export const LOADING_CHANGE = 'LOADING_CHANGE';
export const DATA = "DATA";
export const UPDATE_ARRAY = "UPDATE_ARRAY";
export const DATA_CONCAT = "DATA_CONCAT";
export const DATA_WITH_PAGINATION = "DATA_WITH_PAGINATION";
export const SET_FIELDS = 'SET_FIELDS';
export const SET_ERROR_FORMS = 'SET_ERROR_FORMS';

//Nuevo Catalogo
export const LOADING_CHANGE_CATALOGUE = 'LOADING_CHANGE_CATALOGUE';
export const DATA_CATALOGUE = "DATA_CATALOGUE";
export const SET_FIELDS_CATALOGUE = 'SET_FIELDS_CATALOGUE';
export const DATA_WITH_PAGINATION_CATALOGUE = 'DATA_WITH_PAGINATION_CATALOGUE';

//Nuevo Categories
export const LOADING_CHANGE_CATEGORIES = 'LOADING_CHANGE_CATEGORIES';
export const DATA_CATEGORIES = "DATA_CATEGORIES";
export const SET_FIELDS_CATEGORIES = 'SET_FIELDS_CATEGORIES';

//Nuevo Favorities
export const LOADING_CHANGE_FAVORITIES = 'LOADING_CHANGE_FAVORITIES';
export const DATA_FAVORITIES = "DATA_FAVORITIES";
export const SET_FIELDS_FAVORITIES = 'SET_FIELDS_FAVORITIES';

//Nuevo Cuentas
export const LOADING_CHANGE_ACCOUNTS = 'LOADING_CHANGE_ACCOUNTS';
export const DATA_ACCOUNTS = "DATA_ACCOUNTS";

//Nuevo Carrito
export const LOADING_CHANGE_SHOPPINGCART = 'LOADING_CHANGE_ACCOUNTS';
export const DATA_SHOPPINGCART = "DATA_SHOPPINGCART";