import React from 'react';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonRow,
  IonCol,
  IonImg,
  IonItemDivider,
  IonProgressBar,
  IonToast,
  IonCheckbox,
  IonGrid,
  IonIcon,
  IonModal,
  IonFooter,
  IonButtons,
  IonLoading, IonRefresher, IonRefresherContent
} from '@ionic/react';
import { connect } from 'react-redux'

import { logoWhatsapp, eyeOutline, eyeOffOutline, arrowBack, qrCode, mailOpenOutline, globeOutline, callOutline } from 'ionicons/icons';
import { getdatalogin, getDataJWT, getchek } from '../utils/functions';
import HomeActions from '../actions/HomeActions';
import { QrReader } from 'react-qr-reader';
import { Device } from '@capacitor/device';
import { baseurl } from '../constants/constants';
interface IProps {
  get_: any;
  post_: any;
  changeFields: any;
  setData: any;
  updateArray: any
}

interface IState {
  data: any;
  check: boolean;
  eye: boolean;
  email: any;
  password: any;
  kempresa: any;
  response: any;
  modalOpen: any;
  dircamare: any;
  dataqr: any;
  loading: any;
  simpleToast: any;
  latlong: any;
  DiviseId: any;
  DiviseInfo: any;
  resize: any;
}
const lat: any = "";
const long: any = "";
let resizeinit = "";

class Login extends React.Component<IProps, IState> {
  modal: React.RefObject<HTMLIonModalElement>;
  constructor(props: any) {
    super(props)
    this.modal = React.createRef<HTMLIonModalElement>();
    this.state = { resize: props.resize, DiviseInfo: props.DiviseInfo, DiviseId: props.DiviseId, latlong: props.latlong, simpleToast: props.simpleToast, loading: props.loading, dataqr: props.dataqr, dircamare: props.dircamare, modalOpen: props.modalOpen, response: props.response, data: props.data, check: false, eye: true, email: props.email, password: props.password, kempresa: props.kempresa }
  }

  componentDidMount() {
    resizeinit = window.innerHeight + "," + window.innerWidth;
    this.logDiviseId();
    this.logDiviseInfo();

    navigator.geolocation.watchPosition((position) => {
      let region = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
      this.onRegionChange(region);
    }, (error) => console.log(error));


    let data_login = getdatalogin();
    if (data_login && getchek()) {
      this.setState({
        email: data_login.email,
        password: data_login.passwd,
        kempresa: data_login.kempresa,
        modalOpen: false,
        dircamare: "environment",
        dataqr: "",
        simpleToast: false,
        loading: false
      });
    } else {
      window.localStorage.removeItem("chek");
      this.setState({
        email: "",
        password: "",
        kempresa: "",
        modalOpen: false,
        dircamare: "environment",
        dataqr: "",
        simpleToast: false,
        loading: false
      });
    }

    window.addEventListener('resize', this.handleResize.bind(this))
  }
  handleResize() {
    this.setState({
      resize: window.innerHeight + "," + window.innerWidth
    });
  };
  logDiviseId = async () => {
    const id = await Device.getId();
    this.setState({
      DiviseId: id.uuid
    });
  };
  logDiviseInfo = async () => {
    const info = await Device.getInfo();
    this.setState({
      DiviseInfo: info
    });
  };
  onRegionChange(row: any) {
    this.setState({
      latlong: row.latitude + "," + row.longitude
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (!nextProps.catalogue.loading) {
      this.setState({
        data: nextProps.data,
        response: nextProps.catalogue.response,
        loading: nextProps.catalogue.loading,
        simpleToast: nextProps.catalogue.simpleToast,
        modalOpen: false
      });
    } else {
      this.setState({
        data: nextProps.data,
        response: nextProps.catalogue.response,
        loading: nextProps.catalogue.loading,
        simpleToast: nextProps.catalogue.simpleToast
      });
    }

  }
  send() {
    let dataerrors: any = {};
    let validation_fields = false;

    if (!this.state.email) { dataerrors["email"] = ["*Campo requerido"]; validation_fields = true; };
    if (!this.state.password) { dataerrors["password"] = ["*Campo requerido"]; validation_fields = true; };
    if (!this.state.kempresa) { dataerrors["kempresa"] = ["*Campo requerido"]; validation_fields = true; };

    if (!validation_fields) {

      let data_login = getdatalogin();
      if (data_login) {
        if (this.state.email == data_login.email && this.state.kempresa == data_login.kempresa && this.state.password == data_login.passwd) {
          window.localStorage.setItem('islogued', 'true');
          window.location.href = baseurl() + "/ecommers/home";
        } else {
          this.props.updateArray({ response: { Msg: "Acceso denegado verifique su usuario y contraseña" }, simpleToast: true, loading: false });
        }
      } else {
        this.props.updateArray({ loading: true });
        this.props.post_({ kempresa: this.state.kempresa, latlong: this.state.latlong ? this.state.latlong : "", idmovil: this.state.DiviseId, email: this.state.email, passwd: this.state.password, typeOption: 'login' }, "auth/auth");
      }
    } else {
      this.props.updateArray({ response: { Msg: "llenar campos" }, simpleToast: true, loading: false });

    }
  }

  handleChange(event: any) {
    if (event.target.name == "email") {
      this.setState({ email: event.target.value });
    } else if (event.target.name == "password") {
      this.setState({ password: event.target.value });
    } else if (event.target.name == "kempresa") {
      this.setState({ kempresa: event.target.value });
    }
  }

  showPassword() {
    this.setState({ eye: !this.state.eye });
  }
  onWillDismiss(row: any) {
    this.setState({
      modalOpen: false
    });
  }
  onOpen(row: any) {
    this.setState({
      modalOpen: true
    });
  }
  qrSend(data: any) {
    let splitdata = data.text ? data.text.split("-") : [];
    //window.localStorage.setItem('data_login', JSON.stringify({ kempresa: splitdata[0], idmovil: "123pwa", email: splitdata[1], passwd: splitdata[2] }));
    if (splitdata.length != 3) {
      this.props.updateArray({ loading: false, response: { Msg: "QR de Autenticación Invalido" }, simpleToast: true });
      this.setState({
        dataqr: "",
        //modalOpen: false,
        email: '',
        password: '',
        kempresa: '',
      });
    } else {
      window.localStorage.removeItem("data_login");
      window.localStorage.removeItem("_token_");
      if (this.state.modalOpen) {
        setTimeout(this.send.bind(this), 100);
      }
      this.setState({
        dataqr: data ? data.text : "",
        //modalOpen: false,
        email: splitdata[1],
        password: splitdata[2],
        kempresa: splitdata[0],
      });
      //this.send();
    }
  }
  setSelectedcamara(row: any) {
    this.setState({
      modalOpen: true
    });
  }
  onDidDismissToast(e: any) {
    this.props.updateArray({ response: {}, simpleToast: false });
  }
  handleChangeCheckBox(event: any) {
    if (getchek()) {
      window.localStorage.removeItem("chek");
      window.localStorage.removeItem("data_login");
      window.location.href = baseurl() + "/login";
    } else {
      window.localStorage.setItem('chek', 'true');
    }
  }
  doRefresh(e: any) {
    setTimeout(() => {
      e.detail.complete();
      window.location.href = baseurl() + "/login";
      window.location.reload();
    }, 500);
  }
  render() {
    const { formErrors } = this.state.data;
    const { check, eye, modalOpen, dircamare, dataqr, loading, simpleToast, response, resize } = this.state
    return (
      <IonPage>
        <IonContent fullscreen>
          <IonRefresher slot="fixed" onIonRefresh={e => this.doRefresh(e)}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonProgressBar style={this.state.data.loading ? { display: 'block' } : { display: 'none' }} type="indeterminate"></IonProgressBar>
          <IonToast
            isOpen={simpleToast}
            buttons={[
              {
                text: 'Ok',
                role: 'cancel'
              }
            ]}
            message={`${response && response.Msg}`}
            duration={10000}
            onDidDismiss={e => this.onDidDismissToast(e)}
          />
          <IonLoading
            //color="light"
            cssClass='my-custom-class'
            isOpen={loading}
            message={'Cargando...'}
          />
          <IonRow class="ion-padding-top">
            <IonCol className="ion-align-self-start" size="2"></IonCol>
            <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
              <img style={{ height: "200px", textAlign: 'center', width: "70%" }} src={'https://www.app.codesii.com/public/img/frlogo.png'} />
            </IonCol>
            <IonCol className="ion-align-self-end" size="2"></IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-align-self-center">
              <IonText color="primary" style={{ textAlign: 'center' }} >
                <h1>Catálogo Digital</h1>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-align-self-start" size="1"></IonCol>
            <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
              Para ingresar debe proporcionar la siguiente información.
            </IonCol>
            <IonCol className="ion-align-self-end" size="1"></IonCol>
          </IonRow>
          <form>
            <IonRow>
              <IonCol col-12 size="12">
                <IonItem>
                  <IonLabel position="stacked">Correo electrónico: <IonText color="danger">*</IonText></IonLabel>
                  <IonInput name="email" required type="email" onIonChange={this.handleChange.bind(this)} value={this.state.email || ""}></IonInput>
                </IonItem>
                {formErrors && formErrors.email && (
                  <IonText color="danger" className="ion-padding-start">
                    <small>{formErrors.email}</small>
                  </IonText>
                )}

                <IonItem>
                  <IonLabel position="stacked">Contraseña: <IonText color="danger">*</IonText></IonLabel>
                  <IonInput name="password" required type={eye ? "password" : "text"} onIonChange={this.handleChange.bind(this)} value={this.state.password || ""}></IonInput>
                  <IonIcon icon={eye ? eyeOffOutline : eyeOutline} slot="end" onClick={this.showPassword.bind(this)}></IonIcon>
                </IonItem>
                {formErrors && formErrors.password && (
                  <IonText color="danger" className="ion-padding-start">
                    <small>{formErrors.password}</small>
                  </IonText>
                )}

                {
                  !getdatalogin() || !getchek() ?
                    <>
                      <IonItem>
                        <IonLabel position="stacked">Empresa: <IonText color="danger">*</IonText></IonLabel>
                        <IonInput name="kempresa" required type="text" onIonChange={this.handleChange.bind(this)} value={this.state.kempresa || ""}></IonInput>
                      </IonItem>
                      {formErrors && formErrors.kempresa && (
                        <IonText color="danger" className="ion-padding-start">
                          <small>{formErrors.kempresa}</small>
                        </IonText>
                      )}
                    </>
                    :
                    null
                }
                <div style={{ paddingLeft: '5%', paddingTop: '5%' }}>
                  <IonCheckbox checked={getchek() == "true" ? true : false} onIonChange={this.handleChangeCheckBox.bind(this)}></IonCheckbox>&nbsp;&nbsp;&nbsp;
                  <IonLabel position="stacked">Recordar datos de acceso</IonLabel>
                </div>
              </IonCol>
            </IonRow>
            <div className="ion-padding">
              <IonButton disabled={this.state.email == "" || this.state.kempresa == "" || this.state.password == "" ? true : false} expand="block" className="ion-no-margin" onClick={this.send.bind(this)}>Ingresar</IonButton>
              <IonButton expand="block" slot="primary" color="success" style={{ textAling: "center" }} onClick={e => this.onOpen(e)}>
                <IonIcon icon={qrCode}></IonIcon>&nbsp;&nbsp;Escanear QR
              </IonButton>
            </div>
          </form>
          <IonModal ref={this.modal} isOpen={modalOpen} trigger="open-modal" onWillDismiss={(ev) => this.onWillDismiss(ev)}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={() => this.modal.current?.dismiss()}> <IonIcon slot="end" icon={arrowBack}></IonIcon></IonButton>
                </IonButtons>
                <IonTitle>Scanear QR</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">

              {
                modalOpen ?
                  <div style={!loading ? { display: "block" } : { display: "none" }}>
                    <QrReader
                      //facingMode={selected}
                      onResult={(result, error) => {
                        if (!!result) {
                          if (!loading) {
                            this.qrSend(result);
                          }
                        }
                        if (!!error) {
                          console.info(error);
                        }
                      }}
                      constraints={{ facingMode: 'environment' }}
                    //style={{ width: '100%' }}
                    />
                  </div>
                  :
                  null
              }
              <IonFooter collapse="fade">
                <IonToolbar>
                  <IonButton slot="end" style={{ fontSize: "12px" }} onClick={(ev) => this.onWillDismiss(ev)}>
                    Salir
                  </IonButton>
                </IonToolbar>
              </IonFooter>
            </IonContent>
          </IonModal>
          <IonRow>
            <IonCol col-12 size="12">
              <IonItem>
                <IonIcon icon={mailOpenOutline} size='large' slot='start'></IonIcon>contacto@facturaenruta.com
              </IonItem>
              <IonItem>
                <IonIcon icon={globeOutline} size='large' slot='start'></IonIcon>www.facturaenruta.com
              </IonItem>
            </IonCol>
            <IonCol col-6 size="6">
              <IonItem>
                <IonIcon icon={logoWhatsapp} size='large'></IonIcon>+505 8383-6675
              </IonItem>
            </IonCol>
            <IonCol col-6 size="6">
              <IonItem>
                <IonIcon icon={callOutline} size='large'></IonIcon>+505 5721-3533
              </IonItem>
            </IonCol>

          </IonRow>
        </IonContent>
      </IonPage >
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    _get: PropTypes.func.isRequired,
    _post: PropTypes.func.isRequired,
    data: state.data,
    changeFields: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    catalogue: state.catalogue,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setData: (item: any, name: string) => dispatch(HomeActions.setData(item, name)),
    get_: (fields: any, controller: string) => dispatch(HomeActions.get_(fields, controller)),
    post_: (fields: any, controller: string) => dispatch(HomeActions.post_(fields, controller)),
    changeFields: (fields: any) => dispatch(HomeActions.changeFields(fields)),
    updateArray: (item: string) => dispatch(HomeActions.updateArray(item))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
