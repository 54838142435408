import React from 'react';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonRow,
  IonCol,
  IonImg,
  IonItemDivider,
  IonProgressBar,
  IonToast,
  IonCheckbox,
  IonGrid,
  IonIcon,
  IonThumbnail,
  IonFooter,
  withIonLifeCycle,
  useIonLoading,
  IonButtons,
  IonBackButton,
  IonFab,
  IonFabButton,
  IonAlert,
  IonFabList,
  IonModal,
  IonNote,
  IonTextarea,
  IonToggle,
  IonRadio,
  IonSlides,
  IonSlide
} from '@ionic/react';

import { connect } from 'react-redux'
import { homeOutline, cartOutline, trash, arrowUp, arrowBack, settings, addOutline, ellipsisVerticalOutline, paperPlaneOutline, mailOpenOutline } from 'ionicons/icons';
import { getislogued, getDataJWT, getOrderNumber, getPathImg, getidmoneda, getdatalogin, getpermissions, getshoppingcart_client } from '../utils/functions';
import { API } from '../constants/system';
import ShoppingCartAction from '../actions/ShoppingCartAction'
import HomeActions from '../actions/HomeActions'
import './cart.css';
import moment from "moment";
import PermissionContainer from '../components/PermissionContainer';
interface IProps {
  addquantity: any;
  deleteitem: any;
  updateArray: any;
  deleteitemall: any;
  post_: any;
  addorders: any;
}

interface IState {
  shoppingcart: any;
  catalogue: any;
  data_pagination: any;
  refresh: any;
  subtotal: any;
  itemdelete: any;
  alert: any;
  alertall: any;
  modalOpen: any;
  fields: any;
  formErrors: any;
  alertSend: any;
  cartsend: any;
  totaltax: any;
  totaldiscount: any;
  totalneto: any;
  lat: any;
  lng: any;
  openkey: any;
  resize: any;
  scrollup: any;
  modalOpenImage: any;
  ImageSelect: any;
  NombreSelect: any;
  modalOpenMsg: any;
  fieldMsg: any;
  filter_product: any;

}
let resizeinit = "";
let resizeinit_temp = "";
resizeinit = window.innerHeight + ",";
resizeinit_temp = window.innerHeight + ",";
const options2 = { style: 'currency', currency: 'USD' };
const _numberFormat = new Intl.NumberFormat('en-US', options2);
const slideOpts = {
  initialSlide: 0,
  speed: 400
};

class Cart extends React.Component<IProps, IState> {
  contentRef: React.RefObject<HTMLIonContentElement>;
  modal: React.RefObject<HTMLIonModalElement>;
  constructor(props: any) {
    super(props)
    this.modal = React.createRef<HTMLIonModalElement>();
    this.contentRef = React.createRef<HTMLIonContentElement>();
    this.state = {
      fieldMsg: props.fieldMsg,
      modalOpenMsg: props.modalOpenMsg,
      modalOpenImage: props.modalOpenImage,
      scrollup: props.scrollup,
      resize: props.resize,
      openkey: props.openkey,
      lat: props.lat,
      lng: props.lng,
      alertSend: props.alertSend,
      formErrors: props.formErrors,
      fields: props.fields,
      modalOpen: props.modalOpen,
      alertall: props.alertall,
      alert: props.alert,
      itemdelete: props.itemdelete,
      subtotal: props.subtotal,
      refresh: props.refresh,
      shoppingcart: props.catalogue.shoppingcart,
      catalogue: props.catalogue,
      data_pagination: props.data_pagination,
      cartsend: props.cartsend,
      totaltax: props.totaltax,
      totaldiscount: props.totaldiscount,
      totalneto: props.totalneto,
      ImageSelect: props.ImageSelect,
      NombreSelect: props.NombreSelect,
      filter_product: props.filter_product

    }
  }
  ionViewDidEnter() {
    this.props.updateArray({ refresh_accounts: false, refresh_favorities: false, refresh_cart: true, loading: false });
    window.addEventListener('resize', this.handleResize.bind(this));
  }
  ionViewWillLeave() {
    this.props.updateArray({ refresh_cart: false, loading: true });
  }
  componentDidMount() {
    let fields_clients = getshoppingcart_client();

    navigator.geolocation.watchPosition((position) => {
      let region = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
      this.onRegionChange(region);
    }, (error) => console.log(error));
    var data_user = getDataJWT().data;
    let temp_data_pagination: any = [];
    let totals_subtotals = 0;
    let totaltax = 0;
    let totaldiscount = 0;
    let totalneto = 0;
    let factura_ = Math.floor(Math.random() * 999999);
    let id_ = Math.floor(Math.random() * 100);
    let date = moment(new Date()).format("YYYY-MM-DD");
    this.state.shoppingcart && this.state.shoppingcart.map((val: any, key: any) => {
      let label_modifiers = "-";
      let tem_array: any = [];
      let filter_product: any = [];
      let idtalla_name = "";
      let idcolor_name = "";
      let idtalla = 0;
      let idcolor = 0;
      if (val.idfila) {

        let customer_ = this.state.catalogue.customers[0];
        tem_array['idcliente'] = customer_ && customer_.nid ? customer_.nid : 0;
        tem_array['cprecio'] = customer_ && customer_.precio ? customer_.precio : 0;
        tem_array['cventa'] = customer_ && customer_.tipoventa ? customer_.tipoventa : 0;
        tem_array['cimpuesto'] = customer_ && customer_.impuesto ? customer_.impuesto : 0;
        tem_array['cvendedor'] = customer_ && customer_.idvendedor ? customer_.idvendedor : 0;
        tem_array['cliente'] = customer_ && customer_.nombre ? customer_.nombre : "";

        tem_array['idalmacen'] = data_user && data_user.idalmacen ? data_user.idalmacen : 0;

        tem_array['fecha'] = date;
        tem_array['moneda'] = getidmoneda();
        tem_array['factura'] = factura_;
        tem_array['id'] = id_;
        tem_array['cantidad'] = val.quantity ? val.quantity : 0;
        tem_array['dctporcentaje'] = 0;
        tem_array['idtalla'] = idtalla;
        tem_array['talla'] = idtalla_name;
        tem_array['idcolor'] = idcolor;
        tem_array['color'] = idcolor_name;
        tem_array['label_modifiers'] = label_modifiers == "-" ? "" : label_modifiers;
        tem_array['id_cart'] = val.id;

        tem_array['itemid'] = val && val.nid ? val.nid : 0;
        tem_array['nombreproducto'] = val && val.nombreproducto ? val.nombreproducto : "";
        tem_array['idunidad'] = val && val.idunidad ? val.idunidad : 0;

        let preci_customer: any = filter_product && filter_product.precio1 ? filter_product.precio1 : 0;
        if (tem_array['cprecio'] != 0) {
          if (tem_array['cprecio'] == 1) {
            preci_customer = filter_product.precio1;

          } else if (tem_array['cprecio'] == 2) {
            preci_customer = filter_product.precio2;

          } else if (tem_array['cprecio'] == 3) {
            preci_customer = filter_product.precio3;

          } else {
            preci_customer = filter_product.precio4;

          }
        }

        tem_array['precio'] = preci_customer;
        tem_array['costo'] = val && val.costo ? val.costo : 0;
        tem_array['codigo'] = val && val.codigo ? val.codigo : 0;
        tem_array['imagen'] = val && val.imagen ? val.imagen : "";
        tem_array['unidad'] = val && val.unidad ? val.unidad : 0;

        temp_data_pagination.push(tem_array);

        let sub_total_temp = ((isNaN(val.quantity) ? 0 : val.quantity) * parseFloat(preci_customer));
        let temp_descuento = (0 / 100) * sub_total_temp;
        let temp_tax = ((val.aplicaiva == 1 ? (val && val.impuesto ? val.impuesto : 0) : 0) / 100) * (sub_total_temp - temp_descuento);

        let sub_total = sub_total_temp;
        let sub_total_string: string = _numberFormat.format(sub_total_temp).replace("$", "");

        totals_subtotals = totals_subtotals + sub_total;
        totaltax = totaltax + temp_tax;
        totaldiscount = totaldiscount + temp_descuento;
        totalneto = totalneto + (sub_total - totaldiscount + totaltax);

        let tem_totalneto = (sub_total - temp_descuento + temp_tax);

        tem_array['porcentajetax'] = (val.aplicaiva == 1 ? (val && val.impuesto ? val.impuesto : 0) : 0);
        tem_array['taxvalue'] = (val && val.impuesto ? val.impuesto : 0);

        tem_array['totalbasestring'] = sub_total_string;
        tem_array['totalbase'] = sub_total;
        tem_array['totaliva'] = temp_tax;
        tem_array['totaldescuento'] = temp_descuento;
        tem_array['totalneto'] = tem_totalneto;
        tem_array['idorden'] = val.idorden;
        tem_array['idfila'] = val.idfila;

      } else {

        filter_product = this.state.catalogue.products.filter((cat: any) => cat.nid == val.nid)[0];
        val.modifiers.map((val__: any, key__: any) => {
          let filter_modifiers = this.state.catalogue.modifiers.filter((item: any) => item.nid == val__.nid)[0];
          if (filter_modifiers) {
            if (key__ == 0) {
              label_modifiers = label_modifiers + " " + filter_modifiers.nombre;
              if (filter_modifiers.idgrupo == 7) {
                idtalla = filter_modifiers.nid;
                idtalla_name = filter_modifiers.nombre;
              };
              if (filter_modifiers.idgrupo == 8) {
                idcolor = filter_modifiers.nid;
                idcolor_name = filter_modifiers.nombre;
              };

            } else {
              label_modifiers = label_modifiers + ", " + filter_modifiers.nombre;
              if (filter_modifiers.idgrupo == 7) {
                idtalla = filter_modifiers.nid;
                idtalla_name = filter_modifiers.nombre;
              };
              if (filter_modifiers.idgrupo == 8) {
                idcolor = filter_modifiers.nid;
                idcolor_name = filter_modifiers.nombre;
              };
            };
          }
        });

        let customer_ = this.state.catalogue.customers[0];
        tem_array['idcliente'] = customer_ && customer_.nid ? customer_.nid : 0;
        tem_array['cprecio'] = customer_ && customer_.precio ? customer_.precio : 0;
        tem_array['cventa'] = customer_ && customer_.tipoventa ? customer_.tipoventa : 0;
        tem_array['cimpuesto'] = customer_ && customer_.impuesto ? customer_.impuesto : 0;
        tem_array['cvendedor'] = customer_ && customer_.idvendedor ? customer_.idvendedor : 0;
        tem_array['cliente'] = customer_ && customer_.nombre ? customer_.nombre : "";

        tem_array['idalmacen'] = data_user && data_user.idalmacen ? data_user.idalmacen : 0;

        tem_array['fecha'] = date;
        tem_array['moneda'] = getidmoneda();
        tem_array['factura'] = factura_;
        tem_array['id'] = id_;
        tem_array['cantidad'] = val.quantity ? val.quantity : 0;
        tem_array['dctporcentaje'] = 0;
        tem_array['idtalla'] = idtalla;
        tem_array['talla'] = idtalla_name;
        tem_array['idcolor'] = idcolor;
        tem_array['color'] = idcolor_name;
        tem_array['label_modifiers'] = label_modifiers == "-" ? "" : label_modifiers;
        tem_array['id_cart'] = val.id;

        tem_array['itemid'] = filter_product && filter_product.nid ? filter_product.nid : 0;
        tem_array['nombreproducto'] = filter_product && filter_product.nombre ? filter_product.nombre : "";
        tem_array['idunidad'] = filter_product && filter_product.idunidad ? filter_product.idunidad : 0;

        let preci_customer: any = filter_product && filter_product.precio1 ? filter_product.precio1 : 0;
        if (tem_array['cprecio'] != 0) {
          if (tem_array['cprecio'] == 1) {
            preci_customer = filter_product.precio1;

          } else if (tem_array['cprecio'] == 2) {
            preci_customer = filter_product.precio2;

          } else if (tem_array['cprecio'] == 3) {
            preci_customer = filter_product.precio3;

          } else {
            preci_customer = filter_product.precio4;

          }
        }
        tem_array['precio'] = preci_customer;
        tem_array['costo'] = filter_product && filter_product.costo ? filter_product.costo : 0;
        tem_array['codigo'] = filter_product && filter_product.codigo ? filter_product.codigo : 0;
        tem_array['imagen'] = filter_product && filter_product.imagen ? filter_product.imagen : "";
        tem_array['unidad'] = filter_product && filter_product.unidad ? filter_product.unidad : 0;

        temp_data_pagination.push(tem_array);

        let sub_total_temp = ((isNaN(val.quantity) ? 0 : val.quantity) * parseFloat(preci_customer));
        let temp_descuento = (0 / 100) * sub_total_temp;
        let temp_tax = customer_ ? ((customer_.impuesto == 1 ? (filter_product && filter_product.impuesto ? filter_product.impuesto : 0) : 0) / 100) * (sub_total_temp - temp_descuento) : 0;

        let sub_total = sub_total_temp;
        let sub_total_string: string = _numberFormat.format(sub_total_temp).replace("$", "");

        totals_subtotals = totals_subtotals + sub_total;
        totaltax = totaltax + temp_tax;
        totaldiscount = totaldiscount + temp_descuento;
        totalneto = totalneto + (sub_total - totaldiscount + totaltax);

        let tem_totalneto = (sub_total - temp_descuento + temp_tax);


        tem_array['porcentajetax'] = customer_ ? (customer_.impuesto == 1 ? (filter_product && filter_product.impuesto ? filter_product.impuesto : 0) : 0) : 0;
        tem_array['taxvalue'] = (filter_product && filter_product.impuesto ? filter_product.impuesto : 0);

        tem_array['totalbasestring'] = sub_total_string;
        tem_array['totalbase'] = sub_total;
        tem_array['totaliva'] = temp_tax;
        tem_array['totaldescuento'] = temp_descuento;
        tem_array['totalneto'] = tem_totalneto;
        tem_array['idorden'] = val.idorden;
        tem_array['idfila'] = val.idfila;


      }


      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    });

    this.setState({
      openkey: false,
      data_pagination: temp_data_pagination,
      cartsend: [],
      subtotal: totals_subtotals,
      totaltax: totaltax,
      totaldiscount: totaldiscount,
      totalneto: totalneto,
      alert: false,
      alertall: false,
      modalOpen: false,
      fields: fields_clients,
      formErrors: false,
      alertSend: false,
      resize: resizeinit,
      modalOpenImage: false,
      modalOpenMsg: false
    });


    window.addEventListener('ionKeyboardDidShow', (ev) => {
      /*this.setState({
        openkey: true
      });*/
    });

    window.addEventListener('keyboardWillHide', () => {
      this.setState({
        openkey: false
      });
    });

    window.addEventListener('keyboardDidShow', (event) => {
      this.setState({
        openkey: false
      });
    });

    window.addEventListener('keyboardDidHide', () => {
      this.setState({
        openkey: false
      });
    });
    //this.props.updateArray({ loading: false });
  }
  handleResize() {
    resizeinit_temp = window.innerHeight + ",";
    this.setState({
      resize: window.innerHeight + ","
    });
  };
  onRegionChange(row: any) {
    this.setState({
      lng: row.longitude,
      lat: row.latitude
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.state.scrollup != nextProps.catalogue.scrollup) {
      this.buttomup();
    }
    if ((nextProps.catalogue.refresh_cart)) {
      this.setState({
        shoppingcart: nextProps.catalogue.shoppingcart,
        refresh: nextProps.catalogue.refresh_cart,
        catalogue: nextProps.catalogue,
        scrollup: nextProps.catalogue.scrollup
      });
      setTimeout(this.componentDidMount.bind(this), 100);
      //this.componentDidMount();
    } else {
      this.setState({
        refresh: nextProps.catalogue.refresh_cart,
        scrollup: nextProps.catalogue.scrollup
      });
    }
    navigator.geolocation.watchPosition((position) => {
      let region = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
      this.onRegionChange(region);
    }, (error) => console.log(error));
  }
  errorimg(e: any, row: any) {
    //e.target.src = API + getPathImg() + "nodisponible.jpg";
    e.target.src = 'assets/nodisponible.jpg';
  }
  clearcart(e: any) {
    this.setState({
      alertall: false
    });
    window.localStorage.setItem('edit_order_number', "");
    this.props.deleteitemall();

  }
  addquantity(e: any, row: any) {
    let newquantity = row.type == "removed" ? row.quantity - 1 : row.quantity + 1;
    this.props.addquantity(row.nid, (newquantity <= 0 ? 1 : newquantity));
  }
  onChangequantity(e: any, nid: any) {
    this.props.addquantity(nid, e.target.value == 0 || e.target.value == "" ? 1 : e.target.value);
  }
  deleteitem(e: any) {
    this.props.deleteitem(this.state.itemdelete);
  }
  deleteitemPrev(e: any, nid: any) {
    this.setState({
      itemdelete: nid,
      alert: true
    });
  }
  buttomup() {
    if (this.contentRef.current) {
      this.contentRef.current.scrollToTop();
    }
  }
  deleteitemCancel(e: any) {
    this.setState({
      itemdelete: 0,
      alert: false
    });
  }
  deleteitemCancelAll(e: any) {
    this.setState({
      alertall: false
    });
  }
  deleteitemPrevall(e: any) {
    this.setState({
      alertall: true
    });
  }
  onWillDismiss(row: any) {
    this.setState({
      modalOpen: false
    });
  }
  onWillDismissImg(row: any) {
    this.setState({
      modalOpenImage: false
    });
  }
  onOpen(row: any) {
    if (this.state.shoppingcart.length > 0) {
      this.setState({
        modalOpen: true
      });
    } else {
      this.props.updateArray({ response: { Msg: "Debe agregar productos al carrito" }, simpleToast: true });

    }

  }
  onOpenMsg(row: any) {
    this.setState({
      modalOpenMsg: true
    });
  }
  onWillDismissmodalOpenMsg(row: any) {
    this.setState({
      modalOpenMsg: false
    });
  }
  SendOrderPrev(e: any) {
    let error = false;
    let formErrors: any = {};
    if (this.state.fields && this.state.fields.name == "") { formErrors['name'] = "*Campo Requerido"; error = true };
    if (this.state.fields && this.state.fields.phone == "") { formErrors['phone'] = "*Campo Requerido"; error = true };
    if (this.state.fields && this.state.fields.address == "") { formErrors['address'] = "*Campo Requerido"; error = true };
    if (error) {
      this.setState({
        formErrors: formErrors
      });
    } else {
      this.setState({
        alertSend: true
      });
    }
  }
  SendMsg(e: any) {
    let error = false;
    let formErrors: any = {};
    if (this.state.fieldMsg && this.state.fieldMsg == "") { formErrors['fieldMsg'] = "*Campo Requerido"; error = true };
    if (error) {
      this.setState({
        formErrors: formErrors
      });
    } else {
      let data_ = {
        typeOption: 'notificacionv3',
        sms: this.state.fieldMsg,
        lat: this.state.lat,
        lng: this.state.lng,
        date: moment(new Date()).format("DD-MM-YYYY h:mm A")
      };
      console.log("enviandoooooo", data_);
      this.props.post_(data_, "api/movil/v1/notificacionv3");
      this.setState({
        fieldMsg: "",
        modalOpenMsg: false
      });
    }
  }
  handleChangefieldMsg(event: any) {
    let fieldMsg = event.target.value;
    this.setState({
      fieldMsg: fieldMsg
    });
    //this.props.changeFields(fields)
    // window.localStorage.setItem('shoppingcart_client', JSON.stringify(fields));

  }
  handleChange(event: any) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields: fields
    });
    //this.props.changeFields(fields)
    window.localStorage.setItem('shoppingcart_client', JSON.stringify(fields));

  }
  SendCon(e: any) {
    let fields_clients = getshoppingcart_client();
    this.setState({
      alertSend: false,
      formErrors: {},
      fields: fields_clients,
      modalOpen: false
    });
    this.props.updateArray({ response: { Msg: "Pedido Finalizado" }, simpleToast: true });
  }
  SendFin(e: any) {
    let sendData = {
      tipo: 1,
      version: 2,
      data: "",
      typeOption: "sendCart",
      order: {},
    };
    let order = {
      number: "",
      name: "",
      phone: "",
      total: 0,
      totalbase: 0,
      totaliva: 0,
      totaldiscount: 0,
      detail_order: [],
      address: "",
      date: "",
      note: ""
    };
    let array_tem: any = [];
    let detail_tem: any = [];
    let idorden = 0;
    this.state.data_pagination && this.state.data_pagination.map((val: any, key: any) => {
      if (val.idorden != 0) {
        idorden = val.idorden;
      }
      if (key == 0) {
        order = {
          number: val.factura,
          name: this.state.fields.name,
          phone: this.state.fields.phone,
          total: this.state.subtotal - this.state.totaldiscount + this.state.totaltax,
          totaldiscount: this.state.totaldiscount,
          totalbase: this.state.subtotal - this.state.totaldiscount,
          totaliva: this.state.totaltax,
          address: this.state.fields.address,
          date: moment(new Date()).format("DD-MM-YYYY h:mm A"),
          note: this.state.fields.note,
          detail_order: []
        };
      };
      //console.log(val)
      detail_tem.push(
        {
          cantidad: val.cantidad,
          nombreproducto: val.nombreproducto + " (" + val.codigo + ")",
          precio: val.precio,
          unidad: val.unidad,
          parameters: val.talla + "," + val.color
        });

      array_tem.push(
        {
          idfila: val.idfila,
          idorden: val.idorden,

          usuario: getDataJWT().data.nombre,
          factura: val.factura,
          idcliente: val.idcliente,
          fecha: val.fecha,
          cvendedor: val.cvendedor,
          comentario: this.state.fields.note,
          cliente: this.state.fields.name,
          telefono: this.state.fields.phone,
          direccion: this.state.fields.address,
          moneda: val.moneda,
          cventa: val.cventa,
          cimpuesto: val.cimpuesto,
          totalbase: this.state.subtotal - this.state.totaldiscount,
          totaliva: this.state.totaltax,
          totalneto: this.state.subtotal - this.state.totaldiscount + this.state.totaltax,
          id: val.id,
          cprecio: val.cprecio,
          lng: this.state.lng ? this.state.lng : "",
          lat: this.state.lat ? this.state.lat : "",
          itemid: val.itemid,
          nombreproducto: val.nombreproducto,
          idunidad: val.idunidad,
          idalmacen: val.idalmacen,
          precio: val.precio,
          cantidad: val.cantidad,
          porcentajetax: val.porcentajetax,
          dctporcentaje: val.dctporcentaje,
          costo: val.costo,
          taxvalue: val.taxvalue,
          idtalla: val.idtalla,
          talla: val.talla,
          idcolor: val.idcolor,
          color: val.color
        }
      );

    });
    //console.log(detail_tem);

    let _data = { detail: "" };
    _data.detail = array_tem;
    console.log(array_tem);
    sendData.data = JSON.stringify(_data);
    order.detail_order = detail_tem;
    sendData.order = order;

    let url = "api/movil/v1/pedido";
    if (idorden != 0) {
      url = "api/movil/v1/pedidoactualiza";
    };
    this.props.post_(sendData, url);
    let fields_clients = getshoppingcart_client();
    this.setState({
      alertSend: false,
      formErrors: {},
      fields: fields_clients,
      modalOpen: false
    });
    window.localStorage.setItem('edit_order_number', "");
  }
  handleFocus(event: any) {
    event.target.select();
  }
  aaa(event: any) {
    /*this.setState({
      openkey: false
    });*/
  }
  onOpenModalImage(data: any, row: any, nombreproducto: any) {
    let filter_product = this.state.catalogue.products.filter((cat: any) => cat.nid == data.itemid)[0];

    console.log(data, filter_product);
    this.setState({
      modalOpenImage: true,
      ImageSelect: row,
      NombreSelect: nombreproducto,
      filter_product: filter_product
    });
  }
  onClosedModalImage(row: any) {
    this.setState({
      modalOpenImage: false,
      ImageSelect: '',
      NombreSelect: ""
    });
  }
  render() {
    const { filter_product, fieldMsg, modalOpenMsg, data_pagination, alert, alertall, modalOpen, formErrors, fields, alertSend, openkey, resize, scrollup, modalOpenImage, ImageSelect, NombreSelect } = this.state;
    let cataloguelist: any = [];
    let viewprice = 0;
    let vieworders = 0;
    if (getpermissions() && getpermissions().length > 0) {
      let filter_pri = getpermissions().filter((item: any) => item.nid == 34)[0];
      if (filter_pri) {
        viewprice = filter_pri.valor
      }

      let filter_order = getpermissions().filter((item: any) => item.nid == 32)[0];
      if (filter_order) {
        vieworders = filter_order.valor
      }
    }
    if (data_pagination && data_pagination.length > 0) {
      cataloguelist = [];
      cataloguelist = data_pagination.map((val: any, key: any) => {
        return (
          <IonRow key={key}>
            <IonCol col-12 size='12' style={{ padding: "0px", margin: "0px" }}>
              <h2 style={{ color: "#303e67", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }} >&nbsp;{val.nombreproducto} ({val.codigo})</h2>
            </IonCol >
            <IonCol col-12 size='12' style={{ padding: "0px", margin: "0px" }}>
              <IonItem style={{ background: "white", backgroundColor: "white" }}>
                <IonThumbnail>
                  <img onError={e => this.errorimg(e, val)} src={val.imagen == "nodisponible.jpg" ? 'assets/nodisponible.jpg' : API + getPathImg() + val.imagen}
                    onClick={e => this.onOpenModalImage(val, val.imagen == "nodisponible.jpg" ? 'assets/nodisponible.jpg' : API + getPathImg() + val.imagen, val.nombreproducto + " " + val.codigo)} />
                </IonThumbnail>
                <IonLabel className="ion-text-wrap" style={{ padding: "5px", margin: "0px" }}>
                  {/* des:{val.totaldescuento} - tax:{val.totaliva} */}
                  <span style={{ color: "#7081b9", fontSize: "12px", }}>{viewprice != 0 ? _numberFormat.format(parseFloat(val.precio)).replace("$", "") + " / " : null} {val.unidad} {val.label_modifiers}</span>
                  {/*<span>und</span><p>Lorem ipsum dolor sit amet,  p.</p>*/}
                  <div slot="end" className='inverted-border-radius'>
                    {
                      val.idfila ?
                        <>
                          <input disabled onFocus={this.handleFocus} type="number" className='verticalalign' style={{ width: "60%", background: "white", textAlign: "center", backgroundColor: "white", border: "1px solid white" }} value={val.cantidad} min="0" slot="end" onChange={e => this.onChangequantity(e, val.id_cart)} />
                        </>
                        :
                        <>
                          <button type="button" slot="end" className='verticalalign' style={{ background: "white", backgroundColor: "white", color: "black", fontSize: "20px" }} onClick={e => this.addquantity(e, { nid: val.id_cart, quantity: val.cantidad, type: "removed" })}>-</button>
                          <input onFocus={this.handleFocus} type="number" className='verticalalign' style={{ width: "60%", background: "white", textAlign: "center", backgroundColor: "white", border: "1px solid white" }} value={val.cantidad} min="0" slot="end" onChange={e => this.onChangequantity(e, val.id_cart)} />
                          <button type="button" slot="end" className='verticalalign' style={{ background: "white", backgroundColor: "white", color: "black", fontSize: "20px" }} onClick={e => this.addquantity(e, { nid: val.id_cart, quantity: val.cantidad, type: "add" })}>+</button>
                        </>
                    }
                  </div>
                </IonLabel>
                <div style={{ textAlign: "right" }}>
                  {
                    val.idfila ?
                      <>
                      </>
                      :
                      <>
                        <IonButton slot="end" size="small" color="danger" onClick={e => this.deleteitemPrev(e, val.id_cart)} >
                          <IonIcon icon={trash} ></IonIcon>
                        </IonButton>
                      </>
                  }
                  <br />
                  <IonLabel slot="end">{viewprice != 0 ? <span className='badge_ spancolor_success'>{val.totalbasestring == "NaN" ? parseFloat("0").toFixed(2) : val.totalbasestring}</span> : null}</IonLabel>
                </div >
              </IonItem>
            </IonCol >
          </IonRow >
        );
      });
    };
    return (
      <IonPage>
        <IonAlert
          backdropDismiss={false}
          isOpen={alertSend}
          header={'Confirmar'}
          //subHeader={'Verifique si conexión a internet.'}
          message={'¿Desea finalizar el pedido?'}
          buttons={[
            /*{
              text: 'Conservar',
              handler: e => {
                this.SendCon(e);
              }
            },*/
            {
              text: 'Si',
              handler: e => {
                this.SendFin(e);
              }
            },
            {
              text: 'No',
              handler: e => {
                this.setState({
                  alertSend: false
                });
              }
            }
          ]}
        />
        <IonAlert
          backdropDismiss={false}
          isOpen={alert}
          header={'Alerta'}
          //subHeader={'Verifique si conexión a internet.'}
          message={'Esta seguro de remover este producto!!!'}
          buttons={[
            {
              text: 'Si',
              handler: e => {
                this.deleteitem(e);
              }
            },
            {
              text: 'No',
              handler: e => {
                this.deleteitemCancel(e);
              }
            }
          ]}
        />
        <IonAlert
          backdropDismiss={false}
          isOpen={alertall}
          header={'Alerta'}
          //subHeader={'Verifique si conexión a internet.'}
          message={'Esta seguro de remover todos los productos!!!'}
          buttons={[
            {
              text: 'Si',
              handler: e => {
                this.clearcart(e);
              }
            },
            {
              text: 'No',
              handler: e => {
                this.deleteitemCancelAll(e);
              }
            }
          ]}
        />
        <IonModal ref={this.modal} isOpen={modalOpenMsg} trigger="open-modal" onWillDismiss={(ev) => this.onWillDismissmodalOpenMsg(ev)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButton slot="end" style={{ fontSize: "12px" }} onClick={(ev) => this.onWillDismissmodalOpenMsg(ev)}>
                X
              </IonButton>
              <IonTitle>Enviar Msg</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonLabel>Mensaje:</IonLabel>
            <IonItem>
              <IonTextarea name="fieldMsg" placeholder="Mensaje" value={fieldMsg && fieldMsg ? fieldMsg : ""} rows={5} onIonChange={this.handleChangefieldMsg.bind(this)}></IonTextarea>
            </IonItem>
            <IonRow style={{ height: "56px" }}>

            </IonRow >
            <IonFooter collapse="fade" className='footerfixed'>
              <IonToolbar>
                <IonButton slot="end" style={{ fontSize: "12px" }} onClick={e => this.SendMsg(e)}>
                  <IonIcon icon={paperPlaneOutline}></IonIcon>&nbsp;&nbsp;Enviar
                </IonButton>
              </IonToolbar>
            </IonFooter>
          </IonContent>
        </IonModal>

        <IonModal ref={this.modal} isOpen={modalOpen} trigger="open-modal" onWillDismiss={(ev) => this.onWillDismiss(ev)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButton slot="end" style={{ fontSize: "12px" }} onClick={(ev) => this.onWillDismiss(ev)}>
                X
              </IonButton>
              <IonTitle>Finalizar Pedido</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">

            <IonLabel>Nombre:</IonLabel>
            <IonItem>

              <IonInput name="name" placeholder="Nombre" value={fields && fields.name ? fields.name : ""} onIonChange={this.handleChange.bind(this)}></IonInput>
            </IonItem>
            {formErrors && formErrors.name && (
              <IonText color="danger" className="ion-padding-start">
                <small>{formErrors.name}</small>
              </IonText>
            )}
            <IonLabel>Celular:</IonLabel>

            <IonItem>
              <IonInput name="phone" placeholder="Celular" value={fields && fields.phone ? fields.phone : ""} onIonChange={this.handleChange.bind(this)}></IonInput>
            </IonItem>
            {formErrors && formErrors.phone && (
              <IonText color="danger" className="ion-padding-start">
                <small>{formErrors.phone}</small>
              </IonText>
            )}
            <IonLabel>Dirección:</IonLabel>
            <IonItem>

              <IonTextarea name="address" placeholder="Dirección" value={fields && fields.address ? fields.address : ""} rows={5} onIonChange={this.handleChange.bind(this)}></IonTextarea>
            </IonItem>
            {formErrors && formErrors.address && (
              <IonText color="danger" className="ion-padding-start">
                <small>{formErrors.address}</small>
              </IonText>
            )}
            <IonLabel>Observación:</IonLabel>
            <IonItem>

              <IonTextarea name="note" placeholder="Observación" value={fields && fields.note ? fields.note : ""} rows={5} onIonChange={this.handleChange.bind(this)}></IonTextarea>
            </IonItem>
            <IonRow style={{ height: "56px" }}>

            </IonRow >
            <IonFooter collapse="fade" className='footerfixed'>
              <IonToolbar>
                <IonButton slot="end" style={{ fontSize: "12px" }} onClick={e => this.SendOrderPrev(e)}>
                  <IonIcon icon={paperPlaneOutline}></IonIcon>&nbsp;&nbsp;Finalizar
                </IonButton>
              </IonToolbar>
            </IonFooter>
          </IonContent>
        </IonModal>

        <IonModal ref={this.modal} isOpen={modalOpenImage} trigger="open-modal" onWillDismiss={(ev) => this.onWillDismissImg(ev)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButton slot="end" style={{ fontSize: "12px" }} onClick={(ev) => this.onClosedModalImage(ev)}>
                X
              </IonButton>
              <IonTitle>{NombreSelect}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonSlides pager={true} options={slideOpts}>
              {
                <IonSlide key={"0012"} >
                  <img src={ImageSelect} />
                </IonSlide>
              }
            </IonSlides>
            <IonRow>
              <IonCol col-8 size='8'>
                <IonLabel style={{ fontWeight: 'bold', fontSize: "18px", color: '#3647d3' }}>
                  {filter_product && filter_product.nombre} ({filter_product && filter_product.codigo})
                </IonLabel>
              </IonCol>
              <IonCol col-4 size='4' style={{ textAlign: "right" }}>
                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                  {viewprice != 0 ? filter_product && _numberFormat.format(parseFloat(filter_product.precio1)).replace("$", "") : null}
                </IonLabel>
              </IonCol>
            </IonRow>
            <br />
            <IonRow>
              <IonCol col-6 size='6'>
                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                  MARCA:
                </IonLabel>
                <IonLabel style={{ fontSize: "15px" }}>
                  &nbsp;{filter_product && filter_product.marca}
                </IonLabel>
              </IonCol>
              <IonCol col-6 size='6'>
                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                  U/M:
                </IonLabel>
                <IonLabel style={{ fontSize: "15px" }}>
                  &nbsp;{filter_product && filter_product.unidad}
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol col-6>
                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                  CATEGORIA:
                </IonLabel>
                <IonLabel style={{ fontSize: "15px" }}>
                  &nbsp;{filter_product && filter_product.categoria}
                </IonLabel>
              </IonCol>
            </IonRow>
            {viewprice != 0 ?
              <IonRow>
                <IonCol col-6>
                  <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                    Precio 1:
                  </IonLabel>
                  <IonLabel style={{ fontSize: "15px" }}>
                    &nbsp;{filter_product && _numberFormat.format(parseFloat(filter_product.precio1)).replace("$", "")}&nbsp;
                  </IonLabel> <br />
                  <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                    Precio 2:
                  </IonLabel>
                  <IonLabel style={{ fontSize: "15px" }}>
                    &nbsp;{filter_product && _numberFormat.format(parseFloat(filter_product.precio2)).replace("$", "")}&nbsp;
                  </IonLabel>
                </IonCol>
                <IonCol col-6>
                  <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                    Precio 3:
                  </IonLabel>
                  <IonLabel style={{ fontSize: "15px" }}>
                    &nbsp;{filter_product && _numberFormat.format(parseFloat(filter_product.precio3)).replace("$", "")}&nbsp;
                  </IonLabel> <br />
                  <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                    Precio 4:
                  </IonLabel>
                  <IonLabel style={{ fontSize: "15px" }}>
                    &nbsp;{filter_product && _numberFormat.format(parseFloat(filter_product.precio4)).replace("$", "")}&nbsp;
                  </IonLabel>
                </IonCol>
              </IonRow> : null}
            <br />
            <IonRow>
              <IonCol col-12>
                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                  DESCRIPCION:
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol col-12>
                <IonLabel style={{ fontSize: "15px" }}>
                  <div dangerouslySetInnerHTML={{ __html: filter_product && filter_product.descripcion }} />
                </IonLabel>
              </IonCol>
            </IonRow>
            <br />
          </IonContent>
        </IonModal>

        <div className={resizeinit_temp == resizeinit ? 'header' : 'headerhide'}>
          {resizeinit_temp == resizeinit ?
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonBackButton defaultHref="/ecommers/home" text={""} />
                </IonButtons>
                <IonTitle>Carrito</IonTitle>
                {
                  vieworders != 0 ?
                    <>{
                      /*
                      <IonButton slot="primary" color="secondary" style={{ textAling: "center", marginRight: '10px' }} onClick={e => this.onOpenMsg(e)}>
                        <IonIcon icon={mailOpenOutline}></IonIcon>
                      </IonButton>*/
                    }
                      <IonButton slot="primary" color="secondary" style={{ textAling: "center", marginRight: '10px' }} onClick={e => this.onOpen(e)}>
                        <IonIcon icon={paperPlaneOutline}></IonIcon>
                      </IonButton>
                    </>
                    :
                    null
                }
                <IonButton slot="primary" color="danger" style={{ textAling: "center", marginRight: '10px' }} onClick={e => this.deleteitemPrevall(e)}>
                  <IonIcon icon={trash}></IonIcon>
                </IonButton>
              </IonToolbar>
            </IonHeader>
            : null}
        </div>
        <div className={resizeinit_temp == resizeinit ? 'content' : 'contenthide'}>
          <IonRow>
            <IonCol col-12 size='12' className='centeredcenter'>
              <h1> {" " + getOrderNumber()}</h1>
            </IonCol >
          </IonRow >
          <IonContent ref={this.contentRef} scrollEvents={true} onIonScroll={e => this.aaa(e)}>
            {
              cataloguelist && cataloguelist.length > 0 ?
                <>{cataloguelist}</>
                :
                <PermissionContainer />
            }
          </IonContent>
        </div>
        <div className={resizeinit_temp == resizeinit ? 'footer' : 'footerhide'}>
          {resizeinit_temp == resizeinit ?
            <IonFooter>
              <IonToolbar>
                <IonRow>
                  <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                    <span>Sub-Total</span>
                  </IonCol>
                  <IonCol col-6 size='6' className="centeredfooter">
                    <span >{viewprice != 0 ? _numberFormat.format(parseFloat(this.state.subtotal)).replace("$", "") : _numberFormat.format(parseFloat('0')).replace("$", "")} </span>
                  </IonCol >
                </IonRow >
                <IonRow>
                  <IonCol col-6 size='6'>
                    <IonLabel style={{ fontSize: "13px" }} >
                      Total Descuento (-)
                    </IonLabel>
                  </IonCol >
                  <IonCol col-6 size='6' className="centeredfooter">
                    <IonLabel style={{ textAling: "right" }}>
                      {viewprice != 0 ? _numberFormat.format(parseFloat(this.state.totaldiscount)).replace("$", "") : _numberFormat.format(parseFloat('0')).replace("$", "")}
                    </IonLabel>
                  </IonCol >
                </IonRow >
                <IonRow>
                  <IonCol col-6 size='6'>
                    <IonLabel style={{ fontSize: "13px" }}>
                      Total Impuestos (+)
                    </IonLabel>
                  </IonCol >
                  <IonCol col-6 size='6' className="centeredfooter">
                    <IonLabel style={{ textAling: "right" }}>
                      {viewprice != 0 ? _numberFormat.format(parseFloat(this.state.totaltax)).replace("$", "") : _numberFormat.format(parseFloat('0')).replace("$", "")}
                    </IonLabel>
                  </IonCol >
                </IonRow >
                <IonRow>
                  <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                    <IonLabel >
                      Total Neto
                    </IonLabel>
                  </IonCol >
                  <IonCol col-6 size='6' className="centeredfooter">
                    <IonLabel style={{ textAling: "right" }}>
                      {viewprice != 0 ? _numberFormat.format(parseFloat(this.state.subtotal) - parseFloat(this.state.totaldiscount) + this.state.totaltax).replace("$", "") : _numberFormat.format(parseFloat('0')).replace("$", "")}
                    </IonLabel>
                  </IonCol >
                </IonRow >
              </IonToolbar>
            </IonFooter>
            : null}
        </div>
      </IonPage >
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    catalogue: state.catalogue
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateArray: (item: string) => dispatch(HomeActions.updateArray(item)),
    addquantity: (quantity: any, id: string) => dispatch(ShoppingCartAction.addquantity(quantity, id)),
    deleteitem: (id: string) => dispatch(ShoppingCartAction.deleteitem(id)),
    deleteitemall: () => dispatch(ShoppingCartAction.deleteitemall()),
    post_: (fields: any, controller: string) => dispatch(HomeActions.post_(fields, controller)),
    addorders: (order: any) => dispatch(ShoppingCartAction.addorders(order)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(Cart));
