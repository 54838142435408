import { Redirect, Route, Link, Switch } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
  IonSplitPane
} from '@ionic/react';
import { IonReactRouter, IonReactHashRouter } from '@ionic/react-router';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import CategoriesProducts from './pages/CategoriesProducts';
import DetailsProduct from './pages/DetailsProduct';
import CategoriesProductsVencidos from './pages/CategoriesProductsVencidos';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { createBrowserHistory } from 'history';
/* Theme variables */
import './theme/variables.css';
import { getislogued } from './utils/functions';
import { baseurl } from './constants/constants';

setupIonicReact();
//Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJodHRwczpcL1wvd2ViLmNvZGVzaWkuY29tXC9wdWJsaWMiLCJpYXQiOjE2NTc5MDkzNzMsImV4cCI6MTY1Nzk5NTc3MywiZGF0YSI6eyJlbWFpbCI6ImRpc3RyaWJ1aWRvcjNAZmFjdHVyYWVucnV0YS5jb20iLCJpZHVzdWFyaW8iOiIxMDQiLCJpZGVtcHJlc2EiOiIzMyIsImlkc3VjdXJzYWwiOiIxMiIsImVzdGFkbyI6IjEiLCJmZWNoYWFjdGl2YWNpb24iOiIyMDIyLTA3LTE1VDEyOjIyOjUzLjAwMDAwMCIsImtlbXByZXNhIjoiNTY2MTA0OTIxNiIsImlkcGluIjoiMTg1IiwicGluIjoiMDcwODEwNDYyNSIsIm5vbWJyZSI6IkRpc3RyaWJ1aWRvcjMiLCJpbWFnZW4iOiJub2Rpc3BvbmlibGUuanBnIiwidXN1YXJpbyI6IkRpc3RyaWJ1aWRvcjMiLCJpZHBlcmZpbCI6IjEiLCJpZGFsbWFjZW4iOiIyOCIsImdydXBvIjoiLS0iLCJwdXN1YXJpbyI6IjEiLCJwYXBwIjoiMTIzNDUiLCJkaXJlY2Npb24iOiJTYW4gSm9zXHUwMGU5LCBDb3N0YSBSaWNhIiwidGVsZWZvbm8iOiIwMDAwMDAwMCIsIm1vdmlsIjoiMDAwMDAwMDAiLCJmZWNoYSI6IjIwMjItMDctMTVUMTI6MjI6NTMuMDAwMDAwIn19.G3zO-J2Lsep3MEVEI3jiPZrXR4GU80SoWzLywY0N7WY
const history = createBrowserHistory({ basename: '#' });

const App: React.FC = () => (

  <IonApp>
    <IonReactHashRouter history={history}>
      <Switch>
        <Route path="/login" render={(props: any) =>
          !getislogued() ? (
            <Login />
          ) : (
            <Redirect to="/ecommers/home" />
          )
        } exact={true} />

        <Route path="/ecommers" render={(props: any) =>
          !getislogued() ? (<Redirect to="/login" />)
            :
            <Redirect to="/ecommers/home" />

        } exact={true} />

        <Route path="/ecommers/:child" render={(props: any) =>
          !getislogued() ? (<Redirect to="/login" />)
            :
            <Dashboard {...props} />
        } exact={true} />

        <Route path="/categories_products/:child" render={(props: any) =>
          !getislogued() ? (<Redirect to="/login" />)
            :
            <CategoriesProducts {...props} />
        } exact={true} />

        
        <Route path="/products_vencidos/:child" render={(props: any) =>
          !getislogued() ? (<Redirect to="/login" />)
            :
            <CategoriesProductsVencidos {...props} />
        } exact={true} />

        <Route path="/detail_product/:child" render={(props: any) =>
          !getislogued() ? (<Redirect to="/login" />)
            :
            <DetailsProduct {...props} />
        } exact={true} />

        <Route path="/" render={(props: any) =>
          !getislogued() ? (<Redirect to="/login" />)
            :
            <Redirect to="/ecommers/home" />

        } exact={true} />
      </Switch>
    </IonReactHashRouter>

  </IonApp>
);

export default App;
