import axios from 'axios'
import { API } from '../constants/system'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

//import { tokenCopyPaste } from '../utils/functions'

export function postRequest_(data, view) {
  return axios.post(`${API}${view}`, data, { headers: { Authorization: window.localStorage.getItem('_token_') } });
}

export function getRequest(data, view) {
  return axios.get(`${API}${view}/`, { params: data, headers: { Authorization: window.localStorage.getItem('_token_') } });
}

export function postRequest(data, view) {
  return axios.post(`${API}${view}/`, data, { headers: { Authorization: window.localStorage.getItem('_token_') } });
}

/*export function getlistRequest(data, id, view) {
  return axios.get(`${API}${view}/${id}/`, {params: data, headers: { Authorization: tokenCopyPaste() }});
}

export function postRequest(data, view) {
  return axios.post(`${API}${view}/`, data, { headers: { Authorization: "JWT "+tokenCopyPaste() }});
}

export function putRequest(data,id,view) {
  return axios.put(`${API}${view}/${id}/`, data, { headers: { Authorization: "JWT "+tokenCopyPaste() }});
}

export function deleteRequest(data,id,view) {
  return axios.delete(`${API}${view}/${id}/`, {params: data, headers: { Authorization: tokenCopyPaste() }});
}*/