import * as ActionTypes from '../constants/ActionTypes'
import { getRequest, postRequest_ } from './http'
import { getFavorities, getDataJWT, getShoppingCart, getorders, getdatalogin, getPreEmail } from '../utils/functions';
import { baseurl } from '../constants/constants';

export function loading() {
    return {
        type: ActionTypes.LOADING_CHANGE_CATALOGUE
    }
}

export function setData(items, name) {
    return {
        type: ActionTypes.DATA_CATALOGUE,
        name: name,
        items: items
    }
}

const updateArray = (content) => {
    return {
        type: ActionTypes.UPDATE_ARRAY,
        items: content
    }
}

export function changeFields(fields) {
    return {
        type: ActionTypes.SET_FIELDS_CATALOGUE,
        fields: fields
    }
}

export function data_with_pagination(items, name) {
    return {
        type: ActionTypes.DATA_WITH_PAGINATION_CATALOGUE,
        name: name,
        items: items
    }
}

const get_ = (data, controller) => {
    return (dispatch) => {
        dispatch(loading());
        getRequest(data, controller).then(
            (response) => {
                dispatch(loading());
            },
            (err) => {
                dispatch(loading());
                if (err.response.status === 401) {
                    dispatch(setData(true, 'error401'));
                } else {
                    dispatch(setData(true, 'error'));
                }
            }
        );
    }
};

//Categories
export function setDataCategories(items, name) {
    return {
        type: ActionTypes.DATA_CATEGORIES,
        name: name,
        items: items
    }
}

//Favorities
export function setDataFavorities(items, name) {
    return {
        type: ActionTypes.DATA_FAVORITIES,
        name: name,
        items: items
    }
}

//Accounts
export function setDataAccounts(items, name) {
    return {
        type: ActionTypes.DATA_ACCOUNTS,
        name: name,
        items: items
    }
}

//Login
export function setDataData(items, name) {
    return {
        type: ActionTypes.DATA,
        name: name,
        items: items
    }
}

//shoppingcart
export function setDataShoppingCart(items, name) {
    return {
        type: ActionTypes.DATA_SHOPPINGCART,
        name: name,
        items: items
    }
}
const addorders = (order) => {
    return (dispatch) => {
        let arraygetorders = !getorders() ? [] : getorders();
        let array_orders_tem = [];
        if (arraygetorders && arraygetorders.length > 0) {
            for (var j = 0; j <= arraygetorders.length - 1; j++) {
                arraygetorders[j]['id'] = j;
                array_orders_tem.push(arraygetorders[j]);
            }
        };
        array_orders_tem.push({
            id: array_orders_tem.length,
            number: order.number,
            name: order.name,
            phone: order.phone,
            total: order.total,
            detail_order: order.detail_order,
            totalbase: order.totalbase,
            totaliva: order.totaliva,
            totaldiscount: order.totaldiscount,
            address: order.address,
            date: order.date,
            note: order.note
        });
        window.localStorage.setItem('orders', JSON.stringify(array_orders_tem));
        window.localStorage.removeItem("shoppingcart");
        let arrayshoppingcart = !getShoppingCart() ? [] : getShoppingCart();
        window.localStorage.setItem('shoppingcart', JSON.stringify(arrayshoppingcart));
        dispatch(updateArray({ shoppingcart: arrayshoppingcart, refresh_cart: true }));
        setTimeout(function () {
            dispatch(updateArray({
                refresh_cart: false,
                response: { Msg: "Pedido Enviado" }, simpleToast: true
            }))
        }, 100);
    }
};
const post_ = (data, controller) => {
    return (dispatch) => {
        // dispatch(loading());
        postRequest_(data, controller).then(
            (response) => {
                //dispatch(loading());
                if (data.typeOption == "listasv3") {
                    if (response.data.Code == 401) {
                        dispatch(updateArray({ loading: false, response: response.data, simpleToast: true }));
                        window.localStorage.removeItem("_token_");
                        window.localStorage.removeItem("islogued");
                        window.localStorage.removeItem("data_login");
                        window.location.href = baseurl() + "/login";
                    } else {
                        //CATEGORIAS
                        var categorie_full = [];
                        categorie_full.push({
                            color: "#d0021b",
                            imagen: "",
                            nid: "0",
                            nombre: "CATALOGO",
                            check: false,
                            tam: response.data.productos.length
                        });
                        response.data.categorias.map((val, key) => {
                            val['check'] = false;

                            let totalrows = 0;
                            for (var j = 0; j <= response.data.productos.length - 1; j++) {
                                if (response.data.productos[j]['idcategoria'] == val.nid) {
                                    totalrows = totalrows + 1;
                                }
                            }
                            val['tam'] = totalrows;

                            categorie_full.push(val);
                        });
                        //PRODUCTOS NUEVOS
                        var new_products = [];
                        for (var j = 0; j <= response.data.productos.length - 1; j++) {
                            if (response.data.productos[j]['nuevo'] == 1) {
                                new_products.push(response.data.productos[j]);
                            }
                        }
                        //CATEGORISANDO PRODUCTOS
                        var catalogo_categorie_full_vencidos = [];
                        var catalogo_categorie_full = [];
                        var tem_catalogo_categorie_all = {
                            color: "",
                            imagen: "",
                            nid: 0,
                            nombre: "CATALOGO",
                            catalogo: response.data.productos,
                            catalogo_limit: [],
                            totalrows: response.data.productos.length
                        };
                        catalogo_categorie_full.push(tem_catalogo_categorie_all);

                        let procuct_vencidos=[];

                        response.data.categorias.map((val, key) => {
                            var totalrows = 0;
                            var tem_catalogo_categorie_full = {
                                color: val.color,
                                imagen: val.imagen,
                                nid: val.nid,
                                nombre: val.nombre,
                                catalogo: [],
                                catalogo_limit: [],
                                totalrows: 0
                            };
                            let tem_cat_full = [];
                            let tem_cat = [];
                            let count = 0;
                            for (var j = 0; j <= response.data.productos.length - 1; j++) {
                                


                                if (response.data.productos[j]['idcategoria'] == tem_catalogo_categorie_full.nid) {
                                    if (response.data.productos[j]['caducidades'] != "") {
                                        procuct_vencidos.push(response.data.productos[j]);
                                    }

                                    totalrows = totalrows + 1;
                                    response.data.productos[j]['coloricon'] = "#66666694";
                                    response.data.productos[j]['coloriconshoppingcart'] = "#66666694";
                                    let modifiers_tem = response.data.productos[j]['md'].split('-');
                                    let modifiers = [];
                                    modifiers_tem.map((val) => {
                                        if (val != 0) {
                                            let filter_modifiers = response.data.modificadores.filter((cat) => cat.idgrupo == val);
                                            var tem_catalogo_categorie = {
                                                nid: val,
                                                modifiers: filter_modifiers
                                            };
                                            modifiers.push(tem_catalogo_categorie);
                                        }
                                    });
                                    response.data.productos[j]['modifiersall'] = modifiers;
                                    tem_cat_full.push(response.data.productos[j]);

                                    if (count < 4) {
                                        tem_cat.push(response.data.productos[j]);
                                        count = count + 1;
                                    }

                                }
                            }
                            tem_catalogo_categorie_full.catalogo = tem_cat_full;
                            tem_catalogo_categorie_full.catalogo_limit = tem_cat;
                            tem_catalogo_categorie_full.totalrows = totalrows;
                            catalogo_categorie_full.push(tem_catalogo_categorie_full);
                        });

                        var tem_catalogo_categorie_all_vencidos = {
                            color: "",
                            imagen: "",
                            nid: 0,
                            nombre: "CATALOGO",
                            catalogo: procuct_vencidos,
                            catalogo_limit: [],
                            totalrows: procuct_vencidos.length
                        };

                        catalogo_categorie_full_vencidos.push(tem_catalogo_categorie_all_vencidos);


                        //Favoritos del Storage
                        let arrayfavorities = !getFavorities() ? [] : getFavorities();
                        let array_favorities_tem = [];
                        if (arrayfavorities) {
                            for (var j = 0; j <= arrayfavorities.length - 1; j++) {
                                array_favorities_tem.push(arrayfavorities[j]);
                            }
                        };
                        window.localStorage.setItem('favorities', JSON.stringify(array_favorities_tem));
                        //Shoppingcart del Storage
                        let arrayshoppingcart = !getShoppingCart() ? [] : getShoppingCart();
                        let array_shoppingcart_tem = [];
                        if (arrayshoppingcart) {
                            for (var j = 0; j <= arrayshoppingcart.length - 1; j++) {
                                array_shoppingcart_tem.push(arrayshoppingcart[j]);
                            }
                        };
                        window.localStorage.setItem('shoppingcart', JSON.stringify(array_shoppingcart_tem));
                        let permissions = [];
                        response.data.confmovil.map((val, key) => {
                            //if (val.nid >= 28 && val.nid <= 38) {
                                permissions.push(val);
                            //}
                        });
                        window.localStorage.setItem('permissions', JSON.stringify(permissions));
                        //recibos

                        //DATA ORDERS
                        var new_orders_user = [];
                        var idusuario = getDataJWT().data.idusuario;
                        var new_orders = [];

                        for (var j = 0; j <= response.data.ordenes.length - 1; j++) {
                            let filter_new_orders = new_orders.filter((item) => item.number == response.data.ordenes[j].numero);

                            if (filter_new_orders.length == 0) {
                                let array_tem = {
                                    id: 0
                                };
                                let array_tem_details = [];
                                let filter_details = response.data.ordenes.filter((item) => item.numero == response.data.ordenes[j].numero);
                                let sub_total_temp_all = 0;
                                let temp_descuento_all = 0;
                                let temp_tax = 0;
                                for (var x = 0; x <= filter_details.length - 1; x++) {

                                    let array_tem_detail = {
                                        cantidad: 0
                                    };
                                    let sub_total_temp = 0;
                                    let temp_descuento = 0;
                                    array_tem_detail['idfila'] = filter_details[x].idfila;
                                    array_tem_detail['iditem'] = filter_details[x].iditem;
                                    array_tem_detail['codigo'] = filter_details[x].codigo;
                                    array_tem_detail['idorden'] = filter_details[x].idorden;

                                    array_tem_detail['aplicaiva'] = filter_details[x].aplicaiva;
                                    array_tem_detail['impuesto'] = filter_details[x].impuesto;
                                    array_tem_detail['impuestovalor'] = filter_details[x].impuestovalor;


                                    array_tem_detail['idproducto'] = filter_details[x].idproducto ? filter_details[x].idproducto : 0;
                                    array_tem_detail['cantidad'] = filter_details[x].cantidad;
                                    array_tem_detail['nombreproducto'] = filter_details[x].producto;
                                    array_tem_detail['precio'] = filter_details[x].precio;
                                    array_tem_detail['unidad'] = filter_details[x].unidad;
                                    array_tem_detail['parameters'] = filter_details[x].talla + "-" + filter_details[x].color;
                                    array_tem_details.push(array_tem_detail);

                                    sub_total_temp = sub_total_temp + ((isNaN(filter_details[x].cantidad) ? 0 : filter_details[x].cantidad) * parseFloat(filter_details[x].precio));
                                    temp_descuento = temp_descuento + (0 / 100) * sub_total_temp;

                                    temp_tax = temp_tax + ((filter_details[x].aplicaiva == 1 ? filter_details[x].impuesto : 0) / 100) * (sub_total_temp - temp_descuento);
                                    sub_total_temp_all = parseFloat(sub_total_temp_all) + parseFloat(sub_total_temp);
                                    temp_descuento_all = parseFloat(temp_descuento);
                                }

                                array_tem['id'] = new_orders.length;
                                array_tem['idorden'] = response.data.ordenes[j].idorden;
                                array_tem['number'] = response.data.ordenes[j].numero;
                                array_tem['name'] = response.data.ordenes[j].cliente + " " + response.data.ordenes[j].contacto;
                                array_tem['phone'] = response.data.ordenes[j].telefono;
                                array_tem['id_state'] = response.data.ordenes[j].idestado;
                                array_tem['state'] = response.data.ordenes[j].estado;
                                array_tem['totalbase'] = sub_total_temp_all;
                                array_tem['totaliva'] = temp_tax;
                                array_tem['totaldiscount'] = temp_descuento_all;
                                array_tem['address'] = response.data.ordenes[j].direccion;
                                array_tem['date'] = response.data.ordenes[j].fecha;
                                array_tem['note'] = response.data.ordenes[j].observacion;
                                array_tem['date'] = response.data.ordenes[j].fecha;
                                array_tem['detail_order'] = array_tem_details;
                                array_tem['idusuario'] = response.data.ordenes[j].idusuario;
                                array_tem['total'] = parseFloat(sub_total_temp_all) + parseFloat(temp_tax);

                                if (idusuario == response.data.ordenes[j].idusuario) {
                                    new_orders_user.push(array_tem);
                                }
                                new_orders.push(array_tem);
                            }
                        }

                        dispatch(updateArray({
                            new_products: new_products,
                            products: response.data.productos,
                            categories_products: catalogo_categorie_full,
                            catalogo_categorie_full_vencidos: catalogo_categorie_full_vencidos,
                            modifiers: response.data.modificadores,
                            images: response.data.imagenes,
                            categories: categorie_full,
                            favorities: array_favorities_tem,
                            shoppingcart: array_shoppingcart_tem,
                            accounts: response.data.cxc,
                            customers: response.data.clientes,
                            loading: false,
                            receipts: response.data.recibos ? response.data.recibos : [],
                            orders_data: new_orders,
                            orders_user: new_orders_user,
                            documentos: response.data.documentos ? response.data.documentos : []
                        }));
                    }

                } else if (data.typeOption == "tkn") {
                    window.localStorage.setItem('_token_', response.data.Tkn);
                    setTimeout(function () {
                        dispatch(setDataFavorities(false, 'refresh_'));
                    }, 300);
                    window.location.reload();
                } else if (data.typeOption == "login") {
                    if (response.data.Code == "401") {
                        dispatch(updateArray({ loading: false, response: response.data, simpleToast: true }));
                    } else if (response.data.Code == "200") {
                        response.data.confmovil.map((val, key) => {
                            if (val.nid == 2) {
                                window.localStorage.setItem('path_img', val.valor);
                            }
                        });
                        dispatch(updateArray({
                            loading: false,
                            company: response.data.empresa
                        }));

                        let preEmail = getPreEmail();

                        if (preEmail == "default" || preEmail != data.email) {
                            window.localStorage.removeItem("favorities");
                            window.localStorage.removeItem("shoppingcart");
                            window.localStorage.removeItem("permissions");
                            window.localStorage.removeItem("filterCategorie");
                            window.localStorage.removeItem("orders");
                            window.localStorage.setItem('prev_email', data.email);
                        }

                        window.localStorage.setItem('islogued', 'true');
                        window.localStorage.setItem('company', response.data.empresa.empresa);
                        window.localStorage.setItem('img_default', response.data.empresa.imagen);
                        window.localStorage.setItem('idmoneda', response.data.empresa.idmoneda);
                        window.localStorage.setItem('_token_', response.data.Tkn);

                        var base64Url = response.data.Tkn.split('.')[1];
                        var base64 = base64Url.replace('-', '+').replace('_', '/');
                        var data_token = JSON.parse(window.atob(base64));

                        window.localStorage.setItem('data_login', JSON.stringify({ img_default: response.data.confmovil[0].valor + "recursos/imagenes/empresas/" + response.data.empresa.imagen, kempresa: data.kempresa, latlong: data.latlong, idmovil: data.idmovil, email: data.email, passwd: data.passwd, idpin: data_token.data.idpin, pin: data_token.data.pin }));
                        window.location.href = baseurl() + "/ecommers/home";
                    };

                } else if (data.typeOption == "sendCart") {
                    if (response.data.Code == "200") {
                        dispatch(addorders(data.order));
                        dispatch(post_({
                            latlong: "12.4321792,-86.851584", page: 1, typeOption: "listasv3"
                        }, "api/movil/v1/listasv3"));
                    } else {
                        if (response.data.Code == "401") {
                            dispatch(updateArray({ loading: false, response: response.data, simpleToast: true }));
                        } else {
                            dispatch(updateArray({
                                response: { Msg: "Pedido no Enviado" }, simpleToast: true
                            }));
                        }
                    }

                } else if (data.typeOption == "notificacionv3") {
                    dispatch(updateArray({ response: { Msg: response.data.Msg }, simpleToast: true }));
                } else {
                    window.localStorage.setItem('response', response);
                    window.location.href = baseurl() + "/ecommers/home";
                    window.location.reload();
                }
            },
            (err) => {
                dispatch(loading());
                if (err.response.status === 401) {
                    dispatch(updateArray({ loading: false, response: err.response.data, simpleToast: true }));
                } else if (err.response.status === 422) {

                } else if (err.response.status === 500) {
                    if (err.response.data && err.response.data.Code == "500" && err.response.data.Msg == "Expired token") {
                        if (getdatalogin()) {
                            var data_login = getdatalogin();
                            dispatch(post_({ typeOption: "tkn", kempresa: data_login.kempresa, idmovil: data_login.idmovil, latlong: data_login.latlong, passwd: data_login.passwd, idpin: data_login.idpin, pin: data_login.pin, email: data_login.email }, "auth/tkn"));
                        } else {
                            window.localStorage.removeItem("_token_");
                            window.localStorage.removeItem("islogued");
                            window.localStorage.removeItem("data_login");
                            window.location.href = baseurl() + "/login";
                        }
                    } else {
                        window.localStorage.removeItem("_token_");
                        window.localStorage.removeItem("data_login");
                        window.localStorage.removeItem("islogued");
                        window.location.href = baseurl() + "/login";
                    }
                } else if (err.response.response == "") {
                    if (getdatalogin()) {
                        var data_login = getdatalogin();
                        dispatch(post_({ typeOption: "tkn", kempresa: data_login.kempresa, idmovil: data_login.idmovil, passwd: data_login.passwd, latlong: data_login.latlong, idpin: data_login.idpin, pin: data_login.pin, email: data_login.email }, "auth/tkn"));
                    } else {
                        window.localStorage.removeItem("_token_");
                        window.localStorage.removeItem("islogued");
                        window.localStorage.removeItem("data_login");
                        window.location.href = baseurl() + "/login";
                    }
                } else {
                    dispatch(setData(true, 'error'));
                }
                dispatch(updateArray({
                    loading: false
                }));
            }
        );
    }
};

const concat_data_ = (data, page) => {
    return (dispatch) => {
        dispatch(loading());
        dispatch(data_with_pagination(data, 'data_pagination'));
        dispatch(setData(page, 'page'));
        dispatch(loading());
    }
};

const addfavorities = (id) => {
    return (dispatch) => {
        let arrayfavorities = !getFavorities() ? [] : getFavorities();
        let array_favorities_tem = [];
        let exit = false;
        if (arrayfavorities) {
            for (var j = 0; j <= arrayfavorities.length - 1; j++) {
                if (arrayfavorities[j] == id) {
                    console.log("existe");
                    exit = true;
                } else {
                    array_favorities_tem.push(arrayfavorities[j]);
                }
            }
        };
        if (!exit) {
            array_favorities_tem.push(id);
            window.localStorage.setItem('favorities', JSON.stringify(array_favorities_tem));
            dispatch(updateArray({
                favorities: array_favorities_tem,
                refresh_favorities: true,
                response: { Msg: "Agregado a Favoritos" },
                simpleToast: true
            }));
            /*dispatch(setDataFavorities(array_favorities_tem, 'data'));
            dispatch(setDataFavorities(true, 'refresh_'));*/
        } else {
            window.localStorage.setItem('favorities', JSON.stringify(array_favorities_tem));
            dispatch(updateArray({
                favorities: array_favorities_tem,
                refresh_favorities: true,
                response: { Msg: "Agregado a Favoritos" },
                simpleToast: true
            }));
            /*dispatch(setDataFavorities(array_favorities_tem, 'data'));
            dispatch(setDataFavorities(true, 'refresh_'));*/
        };
        setTimeout(function () {
            dispatch(updateArray({ refresh_favorities: false }))
        }, 100);
    }
};

export default {
    concat_data_,
    get_,
    post_,
    changeFields,
    setData,
    setDataCategories,
    setDataFavorities,
    addfavorities,
    updateArray
}
