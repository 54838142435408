import * as ActionTypes from '../constants/ActionTypes'
import { getRequest, postRequest_ } from './http'
import { getShoppingCart, getorders } from '../utils/functions';

//shoppingcart
export function setDataShoppingCart(items, name) {
    return {
        type: ActionTypes.DATA_SHOPPINGCART,
        name: name,
        items: items
    }
}

export function setData(items, name) {
    return {
        type: ActionTypes.DATA_CATALOGUE,
        name: name,
        items: items
    }
}

const updateArray = (content) => {
    return {
        type: ActionTypes.UPDATE_ARRAY,
        items: content
    }
}

const addshoppingcart = (product) => {
    return (dispatch) => {
        let arrayshoppingcart = !getShoppingCart() ? [] : getShoppingCart();
        let array_shoppingcart_tem = [];
        let exit = false;
        let position = 0;
        if (arrayshoppingcart && arrayshoppingcart.length > 0) {
            for (var j = 0; j <= arrayshoppingcart.length - 1; j++) {
                arrayshoppingcart[j]['modifiers'].sort(SortArray);
                product.modifiers.sort(SortArray);

                if (arrayshoppingcart[j]['nid'] == product.nid && arrayshoppingcart[j]['modifiers'].length == product.modifiers.length && array_equal(arrayshoppingcart[j]['modifiers'], product.modifiers) && !arrayshoppingcart[j]['idfila']) {
                    array_shoppingcart_tem.push(arrayshoppingcart[j]);
                    exit = true;
                    position = j;
                } else {
                    arrayshoppingcart[j]['id'] = j;
                    array_shoppingcart_tem.push(arrayshoppingcart[j]);
                }
            }
        };
        let array_shoppingcart_tem2 = [];
        if (!exit) {
            array_shoppingcart_tem.push({
                id: array_shoppingcart_tem.length,
                nid: product.nid,
                quantity: 1,
                modifiers: product.modifiers,

                idfila: 0,
                parameters_label: null,
                precio: null,
                unidad: null,
                nombreproducto: null,
                codigo: null,
                iditem: product.nid,
                idorden: 0,
                aplicaiva: null,
                impuesto: null,
                impuestovalor: null,

            });
        } else {
            for (var j = 0; j <= arrayshoppingcart.length - 1; j++) {
                arrayshoppingcart[j]['modifiers'].sort(SortArray);
                product.modifiers.sort(SortArray);

                if (position == j) {
                    arrayshoppingcart[j]['id'] = j;
                    arrayshoppingcart[j]['quantity'] = arrayshoppingcart[j]['quantity'] + 1;
                    array_shoppingcart_tem2.push(arrayshoppingcart[j]);
                } else {
                    arrayshoppingcart[j]['id'] = j;
                    array_shoppingcart_tem2.push(arrayshoppingcart[j]);
                }
            }
        }
        //if (!exit) {
        //

        window.localStorage.setItem('shoppingcart', JSON.stringify(array_shoppingcart_tem2.length == 0 ? array_shoppingcart_tem : array_shoppingcart_tem2));
        dispatch(updateArray({
            shoppingcart: array_shoppingcart_tem2.length == 0 ? array_shoppingcart_tem : array_shoppingcart_tem2,
            response: { Msg: "Agregado al Carrito" },
            simpleToast: true,
            refresh_shopping: true
        }));
    }
};
function SortArray(x, y) {
    if (x.nid < y.nid) { return -1; }
    if (x.nid > y.nid) { return 1; }
    return 0;
}
function array_equal(arr1, arr2) {
    if ((Array.isArray(arr1) && Array.isArray(arr2)) === false) return false;
    return JSON.stringify([...new Set(arr1.flat().sort())]) == JSON.stringify([...new Set(arr2.flat().sort())]);
}
export function addquantity(id, quantity) {
    return (dispatch) => {
        let arrayshoppingcart = !getShoppingCart() ? [] : getShoppingCart();
        let array_shoppingcart_tem = [];
        if (arrayshoppingcart) {
            for (var j = 0; j <= arrayshoppingcart.length - 1; j++) {
                if (arrayshoppingcart[j].id == id) {
                    array_shoppingcart_tem.push({
                        id: array_shoppingcart_tem.length,
                        nid: arrayshoppingcart[j].nid,
                        quantity: parseFloat(quantity),
                        modifiers: arrayshoppingcart[j].modifiers,

                        idfila: arrayshoppingcart[j].idfila,
                        parameters_label: arrayshoppingcart[j].parameters_label,
                        precio: arrayshoppingcart[j].precio,
                        unidad: arrayshoppingcart[j].unidad,
                        nombreproducto: arrayshoppingcart[j].nombreproducto,
                        codigo: arrayshoppingcart[j].codigo,
                        iditem: arrayshoppingcart[j].iditem,
                        idorden: arrayshoppingcart[j].idorden,
                        aplicaiva: arrayshoppingcart[j].aplicaiva,
                        impuesto: arrayshoppingcart[j].impuesto,
                        impuestovalor: arrayshoppingcart[j].impuestovalor,

                    });
                } else {
                    array_shoppingcart_tem.push({
                        id: array_shoppingcart_tem.length,
                        nid: arrayshoppingcart[j].nid,
                        quantity: parseFloat(arrayshoppingcart[j].quantity),
                        modifiers: arrayshoppingcart[j].modifiers,

                        idfila: arrayshoppingcart[j].idfila,
                        parameters_label: arrayshoppingcart[j].parameters_label,
                        precio: arrayshoppingcart[j].precio,
                        unidad: arrayshoppingcart[j].unidad,
                        nombreproducto: arrayshoppingcart[j].nombreproducto,
                        codigo: arrayshoppingcart[j].codigo,
                        iditem: arrayshoppingcart[j].iditem,
                        idorden: arrayshoppingcart[j].idorden,
                        aplicaiva: arrayshoppingcart[j].aplicaiva,
                        impuesto: arrayshoppingcart[j].impuesto,
                        impuestovalor: arrayshoppingcart[j].impuestovalor,

                    });
                }
            }
        };

        window.localStorage.setItem('shoppingcart', JSON.stringify(array_shoppingcart_tem));
        dispatch(updateArray({ shoppingcart: array_shoppingcart_tem, refresh_cart: true }));
        setTimeout(function () {
            dispatch(updateArray({ refresh_cart: false }))
        }, 100);
    }
}
export function deleteitem(id, quantity) {
    return (dispatch) => {
        let arrayshoppingcart = !getShoppingCart() ? [] : getShoppingCart();
        let array_shoppingcart_tem = [];
        if (arrayshoppingcart) {
            for (var j = 0; j <= arrayshoppingcart.length - 1; j++) {
                if (arrayshoppingcart[j].id == id) {
                    //array_shoppingcart_tem.push({ nid: id, quantity: parseFloat(quantity) });
                } else {
                    array_shoppingcart_tem.push({
                        id: array_shoppingcart_tem.length,
                        nid: arrayshoppingcart[j].nid,
                        quantity: parseFloat(arrayshoppingcart[j].quantity),
                        modifiers: arrayshoppingcart[j].modifiers,

                        idfila: arrayshoppingcart[j].idfila,
                        parameters_label: arrayshoppingcart[j].parameters_label,
                        precio: arrayshoppingcart[j].precio,
                        unidad: arrayshoppingcart[j].unidad,
                        nombreproducto: arrayshoppingcart[j].nombreproducto,
                        codigo: arrayshoppingcart[j].codigo,
                        iditem: arrayshoppingcart[j].iditem,
                        idorden: arrayshoppingcart[j].idorden,
                        aplicaiva: arrayshoppingcart[j].aplicaiva,
                        impuesto: arrayshoppingcart[j].impuesto,
                        impuestovalor: arrayshoppingcart[j].impuestovalor,

                    });
                }
            }
        };

        window.localStorage.setItem('shoppingcart', JSON.stringify(array_shoppingcart_tem));
        dispatch(updateArray({ shoppingcart: array_shoppingcart_tem, refresh_cart: true }));
        setTimeout(function () {
            dispatch(updateArray({ refresh_cart: false }))
        }, 100);
    }
}
export function deleteitemall() {
    return (dispatch) => {
        window.localStorage.removeItem("shoppingcart");
        let arrayshoppingcart = !getShoppingCart() ? [] : getShoppingCart();
        window.localStorage.setItem('shoppingcart', JSON.stringify(arrayshoppingcart));
        dispatch(updateArray({ shoppingcart: arrayshoppingcart, refresh_cart: true }));
        setTimeout(function () {
            dispatch(updateArray({ refresh_cart: false }))
        }, 100);
    }
}
const addorders = (order) => {
    return (dispatch) => {
        let arraygetorders = !getorders() ? [] : getorders();
        let array_orders_tem = [];
        if (arraygetorders && arraygetorders.length > 0) {
            for (var j = 0; j <= arraygetorders.length - 1; j++) {
                arraygetorders[j]['id'] = j;
                array_orders_tem.push(arraygetorders[j]);
            }
        };
        array_orders_tem.push({ id: array_orders_tem.length, number: order.number, name: order.name, phone: order.phone, total: order.total });
        window.localStorage.setItem('orders', JSON.stringify(array_orders_tem));
        //NUMERO,CLIENTE,TELEFONO,MONTO
        window.localStorage.removeItem("shoppingcart");
        let arrayshoppingcart = !getShoppingCart() ? [] : getShoppingCart();
        window.localStorage.setItem('shoppingcart', JSON.stringify(arrayshoppingcart));
        dispatch(updateArray({ shoppingcart: arrayshoppingcart, refresh_cart: true }));
        setTimeout(function () {
            dispatch(updateArray({ refresh_cart: false }))
        }, 100);
    }
};
export function deleteitempedido(id) {
    return (dispatch) => {
        let arraygetorders = !getorders() ? [] : getorders();
        let arraygetorders_tem = [];
        let codigo = "";
        if (arraygetorders) {
            for (var j = 0; j <= arraygetorders.length - 1; j++) {
                if (arraygetorders[j].id == id) {
                    codigo = arraygetorders[j].number;
                    //arraygetorders_tem.push({ nid: id, quantity: parseFloat(quantity) });
                } else {
                    arraygetorders_tem.push({
                        id: arraygetorders_tem.length,
                        number: arraygetorders[j].number,
                        name: arraygetorders[j].name,
                        phone: arraygetorders[j].phone,
                        total: arraygetorders[j].total,
                        detail_order: arraygetorders[j].detail_order,
                        totalbase: arraygetorders[j].totalbase,
                        totaliva: arraygetorders[j].totaliva,
                        totaldiscount: arraygetorders[j].totaldiscount,
                        address: arraygetorders[j].address,
                        date: arraygetorders[j].date,
                        note: arraygetorders[j].note
                    });
                }
            }
        };

        window.localStorage.setItem('orders', JSON.stringify(arraygetorders_tem));
        dispatch(updateArray({ response: { Msg: "Pedido Eliminado :" + codigo }, simpleToast: true }));
    }
}
export default {
    addshoppingcart,
    setDataShoppingCart,
    addquantity,
    deleteitem,
    deleteitemall,
    addorders,
    deleteitempedido
}

//orders