import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    IonBackButton,
    IonButtons,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonSlides,
    IonSlide,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonBadge,
    IonItemDivider,
    IonList,
    IonItem,
    IonThumbnail,
    IonImg,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,

    IonCardContent,
    IonIcon,
    IonButton,
    IonInfiniteScroll,
    IonAccordion, IonAccordionGroup,
    IonInput,
    IonInfiniteScrollContent, IonFab, IonFabButton, IonAlert, IonSegment, IonSegmentButton, IonModal, IonFooter, IonSpinner
} from '@ionic/react';
import { connect } from 'react-redux'
import HomeActions from '../actions/HomeActions';
import ShoppingCartAction from '../actions/ShoppingCartAction';
import { homeOutline, cartOutline, arrowUp, arrowBack, trash, alarmOutline } from 'ionicons/icons';
import './accounts.css';
import { API } from '../constants/system';
import { getorders, getpermissions } from '../utils/functions';
import PermissionContainer from '../components/PermissionContainer';
interface IProps {
    setDataCategories: any;
    updateArray: any;
    deleteitempedido: any;
}

interface IState {
    accounts: any;
    refresh: any;
    segmentActive: any;
    orders: any;
    modalOpen: any;
    select: any;
    itemdelete: any;
    alert: any;
    alertall: any;
    labelnumber: any;
    receipts_full: any;
    receipts_pagin: any;
    receipt_select: any;
    modalOpenRece: any;
    scrollup: any;
    orders_data: any;
    documentos: any;
}
const options2 = { style: 'currency', currency: 'USD' };
const _numberFormat = new Intl.NumberFormat('en-US', options2);

class Accounts extends React.Component<IProps, IState> {
    contentRef: React.RefObject<HTMLIonContentElement>;
    modal: React.RefObject<HTMLIonModalElement>;
    modalrecei: React.RefObject<HTMLIonModalElement>;
    constructor(props: any) {
        super(props)
        this.modal = React.createRef<HTMLIonModalElement>();
        this.modalrecei = React.createRef<HTMLIonModalElement>();
        this.contentRef = React.createRef<HTMLIonContentElement>();
        this.state = { documentos: props.documentos, orders_data: props.orders_data, scrollup: props.scrollup, modalOpenRece: props.modalOpenRece, receipt_select: props.receipt_select, receipts_pagin: props.receipts_pagin, receipts_full: props.receipts_full, labelnumber: props.labelnumber, alertall: props.alertall, alert: props.alert, itemdelete: props.itemdelete, select: props.select, modalOpen: props.modalOpen, accounts: props.catalogue, refresh: props.refresh, segmentActive: props.segmentActive, orders: props.orders }
    }

    componentDidMount() {
        let arraygetorders = !getorders() ? [] : getorders();
        let sortedData = arraygetorders.slice().sort((a: any, b: any) => b.id - a.id);

        this.setState({
            segmentActive: 4,
            orders: sortedData,
            modalOpen: false,
            alert: false,
            alertall: false,
            modalOpenRece: false,
            labelnumber: ""

        });
    }

    ionViewDidEnter() {
        //this.props.setDataCategories(this.state.refresh ? false : true, 'refresh');
        this.props.updateArray({ refresh_accounts: true, refresh_favorities: false, refresh_cart: false, loading: false });
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.state.scrollup != nextProps.catalogue.scrollup) {
            this.buttomup();
        }
        let _receiptsIds: any = [];
        let _receipts = nextProps.catalogue.receipts && nextProps.catalogue.receipts.filter((element: any) => {
            let isDuplicate = _receiptsIds.includes(element.numero);
            if (!isDuplicate) {
                _receiptsIds.push(element.numero);
                return true;
            }
            return false;
        });

        this.setState({
            accounts: nextProps.catalogue.accounts,
            refresh: nextProps.catalogue.refresh_accounts,
            receipts_full: nextProps.catalogue.receipts,
            receipts_pagin: _receipts,
            scrollup: nextProps.catalogue.scrollup,
            orders_data: nextProps.catalogue.orders_data,
            documentos: nextProps.catalogue.documentos,
            //orders: nextProps.catalogue.orders_user

        });


    }

    buttomup() {
        if (this.contentRef.current) {
            this.contentRef.current.scrollToTop();
        }
    }
    onWillDismiss(row: any) {
        this.setState({
            modalOpen: false,
            select: null
        });
    }
    onopenmodal(e: any, row: any) {
        console.log(row, e);
        this.setState({
            modalOpen: true,
            select: row
        });
    }
    deleteitemCancel(e: any) {
        this.setState({
            itemdelete: 0,
            alert: false
        });
    }
    deleteitemCancelAll(e: any) {
        this.setState({
            alertall: false
        });
    }
    deleteitemPrevall(e: any) {
        this.setState({
            alertall: true
        });
    }
    deleteitem(e: any) {
        this.props.deleteitempedido(this.state.itemdelete);
    }
    clearcart(e: any) {

    }
    deleteitemPrev(e: any, row: any) {
        this.setState({
            itemdelete: row.id,
            alert: true,
            labelnumber: row.number
        });
    }
    editOrder(e: any, row: any) {
        console.log(row);
        //agregando cliente
        window.localStorage.setItem('shoppingcart_client', JSON.stringify({
            address: row.address,
            name: row.name,
            note: row.note,
            phone: row.phone,
        }));
        //agregando carrito
        let car: any = [];
        row.detail_order && row.detail_order.map((val: any, key: any) => {
            car.push(
                {
                    "idfila": val.idfila,
                    "parameters_label": val.parameters,
                    "precio": val.precio,
                    "unidad": val.unidad,
                    "nombreproducto": val.nombreproducto,
                    "codigo": val.codigo,

                    "aplicaiva": val.aplicaiva,
                    "impuesto": val.impuesto,
                    "impuestovalor": val.impuestovalor,

                    "id": val.iditem,
                    "nid": val.iditem,
                    "quantity": val.cantidad,
                    "modifiers": [],
                    "iditem": val.iditem,
                    "idorden": val.idorden,
                }
            );
        });
        console.log(car);

        window.localStorage.setItem('shoppingcart', JSON.stringify(car));
        window.location.href = "#/ecommers/home";
        this.setState({
            modalOpen: false
        });
        window.localStorage.setItem('edit_order_number', " (" + row.number + ")");

        window.location.reload();
        /*[
            {
                "id":0,
                "nid":"4",
                "quantity":1,
                "modifiers":[]
            },
            {
                "id":1,
                "nid":"1",
                "quantity":1,
                "modifiers":[{"nid":"42","idgrupo":"7"},{"nid":"44","idgrupo":"8"}]
            }
        ]*/

    }
    render_receipts(row: any) {
        let receipt = {
            numero: row.numero,
            fecha: row.fecha,
            formapago: row.formapago,
            estado: row.estado,
            emision: row.emision,
            usuario: row.usuario,
            referencia: row.referencia,
            observacion: row.observacion,
            valor: row.valor,
            ir: row.rt1,
            al: row.rt2,
            totalneto: row.montoneto,
            idestado: row.idestado,
            details: []
        }
        let receipts_facts: any = [];
        let filter_facts = this.state.receipts_full.filter((cat: any) => cat.numero == row.numero);
        if (filter_facts.length > 0) {
            filter_facts.map((val_: any, key_: any) => {
                receipts_facts.push({
                    numero: val_.numero,
                    factura: val_.factura,
                    fechafactura: val_.fechafactura,
                    saldo: val_.saldo,
                    abono: val_.abono,
                    nuevosaldo: val_.nuevosaldo
                });
            })
        }
        receipt.details = receipts_facts;
        return receipt;
    }
    onopenmodalrecet(e: any, row: any) {
        this.setState({
            modalOpenRece: true,
            receipt_select: row
        });
    }
    onWillDismissrece(row: any) {
        this.setState({
            modalOpenRece: false,
            receipt_select: null
        });
    }
    render() {
        const { modalOpen, select, alert, alertall, labelnumber, receipts_pagin, receipt_select, modalOpenRece, orders, orders_data, documentos } = this.state;
        let receipts_paginlist: any = [];
        if (receipts_pagin && receipts_pagin.length > 0) {
            receipts_paginlist = [];
            receipts_paginlist = receipts_pagin.map((val: any, key: any) =>
                this.render_receipts(val)
            );
        };
        let totalpedidos = 0;
        let totalcxc = 0;
        let totalnetorece = 0;
        let vieworders = 0;
        let viewsells = 0;
        let viewpayments = 0;
        let viewprice = 0;
        if (getpermissions() && getpermissions().length > 0) {
            let filter_order = getpermissions().filter((item: any) => item.nid == 32)[0];
            if (filter_order) {
                vieworders = filter_order.valor
            }
            let filter_sell = getpermissions().filter((item: any) => item.nid == 31)[0];
            if (filter_sell) {
                viewsells = filter_sell.valor
            }
            let filter_payment = getpermissions().filter((item: any) => item.nid == 33)[0];
            if (filter_payment) {
                viewpayments = filter_payment.valor
            }
            let filter_pri = getpermissions().filter((item: any) => item.nid == 34)[0];
            if (filter_pri) {
                viewprice = filter_pri.valor
            }
        }
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/ecommers/home" text={""} />
                        </IonButtons>
                        <IonTitle>Transacciones</IonTitle>
                    </IonToolbar>
                    {
                        vieworders != 0 || viewsells != 0 || viewpayments != 0 ?
                            <IonToolbar color="primary">
                                <IonSegment value={this.state.segmentActive}>
                                    {
                                        <IonSegmentButton
                                            value="4"
                                            onClick={() => {
                                                this.setState({
                                                    segmentActive: 4
                                                });
                                            }}
                                        >
                                            <IonLabel>Mis Pedidos</IonLabel>
                                        </IonSegmentButton>
                                    }
                                    {
                                        vieworders != 0 ?
                                            <IonSegmentButton
                                                value="1"
                                                onClick={() => {
                                                    this.setState({
                                                        segmentActive: 1
                                                    });
                                                }}
                                            >
                                                <IonLabel>Ordenes</IonLabel>
                                            </IonSegmentButton>
                                            :
                                            null
                                    }
                                    {
                                        viewsells != 0 ?
                                            <IonSegmentButton
                                                value="2"
                                                onClick={() => {
                                                    this.setState({
                                                        segmentActive: 2
                                                    });
                                                }}
                                            >
                                                <IonLabel>Saldos</IonLabel>
                                            </IonSegmentButton>
                                            :
                                            null
                                    }
                                    {
                                        viewpayments != 0 ?
                                            <IonSegmentButton
                                                value="3"
                                                onClick={() => {
                                                    this.setState({
                                                        segmentActive: 3
                                                    });
                                                }}
                                            >
                                                <IonLabel>Pagos</IonLabel>
                                            </IonSegmentButton>
                                            :
                                            null
                                    }
                                </IonSegment>
                            </IonToolbar> : null
                    }

                </IonHeader>
                <IonContent fullscreen ref={this.contentRef} scrollEvents={true}>
                    <IonAlert
                        backdropDismiss={false}
                        isOpen={alert}
                        header={'Alerta'}
                        //subHeader={'Verifique si conexión a internet.'}
                        message={'Esta seguro de remover este pedido: ' + labelnumber}
                        buttons={[
                            {
                                text: 'Si',
                                handler: e => {
                                    this.deleteitem(e);
                                }
                            },
                            {
                                text: 'No',
                                handler: e => {
                                    this.deleteitemCancel(e);
                                }
                            }
                        ]}
                    />
                    <IonAlert
                        backdropDismiss={false}
                        isOpen={alertall}
                        header={'Alerta'}
                        //subHeader={'Verifique si conexión a internet.'}
                        message={'Esta seguro de remover todos los productos!!!'}
                        buttons={[
                            {
                                text: 'Si',
                                handler: e => {
                                    this.clearcart(e);
                                }
                            },
                            {
                                text: 'No',
                                handler: e => {
                                    this.deleteitemCancelAll(e);
                                }
                            }
                        ]}
                    />
                    <div className="table-wrapper">
                        {
                            vieworders != 0 && this.state.segmentActive == 4 ?
                                <>
                                    <table className="sticky-header sticky-column">
                                        <thead>
                                            <tr>
                                                <th >Numero</th>
                                                <th >Fecha</th>
                                                <th >Cliente</th>
                                                <th >Telefono</th>
                                                <th >Monto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders && orders.length > 0 && orders.map((val: any, key: any) => {
                                                totalpedidos += val.total;
                                                return (
                                                    <tr key={key}>
                                                        <td onClick={e => this.onopenmodal(e, val)}> <span style={{ color: "#3880ff", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>{val.number} </span></td>
                                                        <td>{val.date}</td>
                                                        <td>{val.name}</td>
                                                        <td>{val.phone}</td>
                                                        <td style={{ textAlign: "right" }}>{_numberFormat.format(val.total).replace("$", "")}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <IonRow style={{ height: "160px" }}>

                                    </IonRow >
                                    <IonFooter collapse="fade" className='footerfixed'>
                                        <IonToolbar color="primary">
                                            <IonRow>
                                                <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                                    <span style={{ color: "black", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>TOTAL</span>
                                                </IonCol >
                                                <IonCol col-6 size='6' className="centeredfooter">
                                                    <IonLabel style={{ fontWeight: "bold", paddingRight: "7px", textAling: "right" }}>
                                                        {_numberFormat.format(totalpedidos).replace("$", "")}
                                                    </IonLabel>
                                                </IonCol >
                                            </IonRow >
                                        </IonToolbar>
                                    </IonFooter>
                                </>
                                : null
                        }
                    </div>
                    {
                        vieworders != 0 || viewsells != 0 || viewpayments != 0 ?
                            <>
                                <div className="table-wrapper">
                                    {
                                        this.state.segmentActive == 1 && vieworders != 0 ?
                                            (
                                                <>
                                                    <table className="sticky-header sticky-column">
                                                        <thead>
                                                            <tr>

                                                                <th >Numero</th>
                                                                <th >Fecha</th>
                                                                <th >Cliente</th>
                                                                <th >Estado</th>
                                                                <th >Telefono</th>
                                                                <th >Monto</th>

                                                                {/*<th >Eliminar</th>*/}
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {orders_data.length > 0 && orders_data.map((val: any, key: any) => {
                                                                totalpedidos += val.total;
                                                                return (
                                                                    <tr key={key}>


                                                                        <td onClick={e => this.onopenmodal(e, val)}> <span style={{ color: "#3880ff", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>{val.number} </span></td>
                                                                        <td>{val.date}</td>
                                                                        <td>{val.name}</td>
                                                                        <td style={{ textAlign: "center" }}>{
                                                                            val.id_state == 10 ?
                                                                                <IonBadge color='primary' style={{ fontSize: "10px" }}>{val.state}</IonBadge>
                                                                                :
                                                                                val.id_state == 4 ?
                                                                                    <IonBadge color='tertiary' style={{ fontSize: "10px" }}>{val.state}</IonBadge>
                                                                                    :
                                                                                    <IonBadge color='danger' style={{ fontSize: "10px" }}>{val.state}</IonBadge>
                                                                        }</td>
                                                                        <td>{val.phone}</td>
                                                                        <td style={{ textAlign: "right" }}>{_numberFormat.format(val.total).replace("$", "")}</td>
                                                                        {/*<td style={{ textAlign: "center" }}> <IonIcon icon={trash} size="small" style={{ color: "#ef4d56", fontSize: "25px" }} onClick={e => this.deleteitemPrev(e, val)} ></IonIcon></td>*/}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <IonRow style={{ height: "160px" }}>

                                                    </IonRow >
                                                    <IonFooter collapse="fade" className='footerfixed'>
                                                        <IonToolbar color="primary">
                                                            <IonRow>
                                                                <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                                                    <span style={{ color: "black", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>TOTAL</span>
                                                                </IonCol >
                                                                <IonCol col-6 size='6' className="centeredfooter">
                                                                    <IonLabel style={{ fontWeight: "bold", paddingRight: "7px", textAling: "right" }}>
                                                                        {_numberFormat.format(totalpedidos).replace("$", "")}
                                                                    </IonLabel>
                                                                </IonCol >
                                                            </IonRow >
                                                        </IonToolbar>
                                                    </IonFooter>
                                                </>
                                            ) :
                                            this.state.segmentActive == 2 && viewsells != 0 ?
                                                (
                                                    <>
                                                        <table className="sticky-header sticky-column">
                                                            <thead>
                                                                <tr>
                                                                    <th >Factura</th>
                                                                    <th >Fecha</th>
                                                                    <th >Vencimiento</th>
                                                                    <th >Saldo</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.accounts.length > 0 && this.state.accounts.map((val: any, key: any) => {
                                                                    totalcxc += parseFloat(val.saldo);
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td >{val.reference}</td>
                                                                            <td>{val.fecha_registro}</td>
                                                                            <td>{val.vence}</td>
                                                                            <td style={{ textAlign: "right" }}>{_numberFormat.format(val.saldo).replace("$", "")}</td>
                                                                        </tr>
                                                                    );
                                                                })}

                                                            </tbody>
                                                        </table>
                                                        <IonRow style={{ height: "160px" }}>

                                                        </IonRow >
                                                        <IonFooter collapse="fade" className='footerfixed'>
                                                            <IonToolbar color="primary">
                                                                <IonRow>
                                                                    <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                                                        <span style={{ color: "black", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>TOTAL</span>
                                                                    </IonCol >
                                                                    <IonCol col-6 size='6' className="centeredfooter">
                                                                        <IonLabel style={{ fontWeight: "bold", paddingRight: "7px", textAling: "right" }}>
                                                                            {_numberFormat.format(totalcxc).replace("$", "")}
                                                                        </IonLabel>
                                                                    </IonCol >
                                                                </IonRow >
                                                            </IonToolbar>
                                                        </IonFooter>
                                                    </>
                                                ) :
                                                (
                                                    viewpayments != 0 ?
                                                        <>
                                                            <table className="sticky-header sticky-column">
                                                                <thead>
                                                                    <tr>
                                                                        <th >Numero</th>
                                                                        <th >Fecha</th>
                                                                        <th >Forma de Pago</th>
                                                                        <th >Estado</th>
                                                                        <th >Monto Neto</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {receipts_paginlist.length > 0 && receipts_paginlist.map((val: any, key: any) => {

                                                                        if (val.estado != "ANULADO") {
                                                                            totalnetorece += parseFloat(val.totalneto);
                                                                        }
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td onClick={e => this.onopenmodalrecet(e, val)}> <span style={{ color: "#3880ff", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>{val.numero} </span></td>
                                                                                <td>{val.fecha}</td>
                                                                                <td>{val.formapago}</td>
                                                                                <td style={{ textAlign: "center" }}>{
                                                                                    val.idestado == 4 ?
                                                                                        <IonSpinner style={{ color: "#3880ff" }} ></IonSpinner>
                                                                                        :
                                                                                        val.estado
                                                                                }</td>
                                                                                <td style={{ textAlign: "right" }}>{_numberFormat.format(val.totalneto).replace("$", "")}</td>
                                                                            </tr>
                                                                        );
                                                                    })}

                                                                </tbody>
                                                            </table>
                                                            <IonRow style={{ height: "160px" }}>

                                                            </IonRow >
                                                            <IonFooter collapse="fade" className='footerfixed'>
                                                                <IonToolbar color="primary">
                                                                    <IonRow>
                                                                        <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                                                            <span style={{ color: "black", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>TOTAL</span>
                                                                        </IonCol >
                                                                        <IonCol col-6 size='6' className="centeredfooter">
                                                                            <IonLabel style={{ fontWeight: "bold", paddingRight: "7px", textAling: "right" }}>
                                                                                {_numberFormat.format(totalnetorece).replace("$", "")}
                                                                            </IonLabel>
                                                                        </IonCol >
                                                                    </IonRow >
                                                                </IonToolbar>
                                                            </IonFooter>
                                                        </>
                                                        :
                                                        null
                                                )
                                    }
                                </div>
                            </>
                            :
                            <IonContent>
                                <PermissionContainer />
                            </IonContent>
                    }

                    <IonModal ref={this.modal} isOpen={modalOpen} trigger="open-modal" onWillDismiss={(ev) => this.onWillDismiss(ev)}>
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonButtons slot="start">
                                    <IonButton onClick={() => this.modal.current?.dismiss()}> <IonIcon slot="end" icon={arrowBack}></IonIcon></IonButton>
                                </IonButtons>
                                {/*
                                    <IonButton color="secondary" slot="end" onClick={e => this.editOrder(e, this.state.select)}><IonIcon icon={cartOutline} /></IonButton>
                                */}
                                <IonTitle>Detalle del Pedido</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            <IonRow>
                                <IonCol col-12 size='12'>
                                    {/* 
4		PENDIENTE
6		PREPARACION
5		PROCESADO
9		ENVIADO
11		ENTREGADO
                                            */}
                                    {
                                        vieworders != 0 && this.state.segmentActive == 1 ?
                                            <div className="ion-padding">
                                                <ul className="progress-indicator">
                                                    <li className=
                                                        {
                                                            select && (select.id_state == 4 ||
                                                                select.id_state == 6 ||
                                                                select.id_state == 10 ||
                                                                select.id_state == 9 ||
                                                                select.id_state == 11
                                                            ) ? "completed" : ""
                                                        }
                                                    >
                                                        <span className="bubble"></span>
                                                        {documentos && documentos.length && documentos[0].estado}
                                                    </li>
                                                    <li
                                                        className=
                                                        {
                                                            select && (
                                                                select.id_state == 6 ||
                                                                select.id_state == 10 ||
                                                                select.id_state == 9 ||
                                                                select.id_state == 11
                                                            ) ? "completed" : ""
                                                        }
                                                    >
                                                        <span className="bubble"></span>
                                                        {documentos && documentos.length && documentos[2].estado}
                                                    </li>
                                                    <li
                                                        className=
                                                        {
                                                            select && (
                                                                select.id_state == 10 ||
                                                                select.id_state == 9 ||
                                                                select.id_state == 11
                                                            ) ? "completed" : ""
                                                        }
                                                    >
                                                        <span className="bubble"></span>
                                                        {documentos && documentos.length && documentos[4].estado}
                                                    </li>
                                                    <li
                                                        className=
                                                        {
                                                            select && (
                                                                select.id_state == 9 ||
                                                                select.id_state == 11
                                                            ) ? "completed" : ""
                                                        }
                                                    >
                                                        <span className="bubble"></span>
                                                        {documentos && documentos.length && documentos[3].estado}
                                                    </li>
                                                    <li
                                                        className=
                                                        {
                                                            select && (
                                                                select.id_state == 11
                                                            ) ? "completed" : ""
                                                        }
                                                    >
                                                        <span className="bubble"></span>
                                                        {documentos && documentos.length && documentos[5].estado}
                                                    </li>
                                                </ul>
                                            </div>
                                            : null
                                    }


                                </IonCol>
                                <IonCol col-12 size='12'>
                                    <IonAccordionGroup>
                                        <IonAccordion value="first">
                                            <IonItem slot="header" color="light">
                                                <IonLabel>Pedido #{select && select.number}</IonLabel>
                                            </IonItem>
                                            <div className="ion-padding" slot="content">
                                                <IonRow>
                                                    <IonCol col-3 size='3'>
                                                        <IonLabel >
                                                            Numero:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-9 size='9'>
                                                        <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                                            {select && select.number}
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-3 size='3'>
                                                        <IonLabel >
                                                            Fecha:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-9 size='9'>
                                                        <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                                            {select && select.date}
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-3 size='3'>
                                                        <IonLabel >
                                                            Nombre:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-9 size='9' >
                                                        <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                                            {select && select.name}
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-3 size='3'>
                                                        <IonLabel >
                                                            Telefono:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-9 size='9' >
                                                        <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                                            {select && select.phone}
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-3 size='3'>
                                                        <IonLabel >
                                                            Dirección:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-9 size='9' >
                                                        <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                                            {select && select.address}
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-3 size='4'>
                                                        <IonLabel >
                                                            Observación:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol col-9 size='8' >
                                                        <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                                            {select && select.note}
                                                        </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                        </IonAccordion>

                                    </IonAccordionGroup>
                                </IonCol>


                            </IonRow>
                            {
                                select && select.detail_order.map((val: any, key: any) => {
                                    return (
                                        <IonRow key={key}>
                                            <IonCol col-12 size='12' style={{ padding: "0px", margin: "0px" }}>
                                                <h2 style={{ color: "#303e67", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }} >&nbsp;{val.nombreproducto}</h2>
                                            </IonCol >
                                            <IonCol col-12 size='12' style={{ padding: "0px", margin: "0px" }}>
                                                <IonItem style={{ background: "white", backgroundColor: "white" }}>
                                                    <IonLabel className="ion-text-wrap" style={{ padding: "5px", margin: "0px" }}>
                                                        {/* des:{val.totaldescuento} - tax:{val.totaliva} */}
                                                        <span style={{ color: "#7081b9", fontSize: "12px" }}>{viewprice != 0 ? _numberFormat.format(parseFloat(val.precio)).replace("$", "") : "0.00"} X {val.parameters == "," ? "" : val.parameters} &nbsp; {val.cantidad}{val.unidad} </span>
                                                        {/*<span>und</span><p>Lorem ipsum dolor sit amet,  p.</p>*/}
                                                    </IonLabel>
                                                    <div style={{ textAlign: "right" }}>
                                                        <IonLabel slot="end"><span className='badge_ spancolor_success'>{val.totalbasestring == "NaN" ? parseFloat("0").toFixed(2) : (viewprice != 0 ? _numberFormat.format(parseFloat(val.cantidad) * parseFloat(val.precio)).replace("$", "") : "0.00")}</span></IonLabel>
                                                    </div >
                                                </IonItem>
                                            </IonCol >
                                        </IonRow >
                                    );
                                })}
                            <IonRow style={{ height: "160px" }}>

                            </IonRow >
                            <IonFooter collapse="fade" className='footerfixed'>
                                <IonToolbar >
                                    <IonRow>
                                        <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                            <span>Sub-Total</span>
                                        </IonCol>
                                        <IonCol col-6 size='6' className="centeredfooteraccount">
                                            {viewprice != 0 ? _numberFormat.format(parseFloat(select && select.totalbase)).replace("$", "") : "0.00"}
                                        </IonCol >
                                    </IonRow >
                                    <IonRow>
                                        <IonCol col-6 size='6'>
                                            <IonLabel style={{ fontSize: "13px" }} >
                                                Total Descuento (-)
                                            </IonLabel>
                                        </IonCol >
                                        <IonCol col-6 size='6' className="centeredfooteraccount">
                                            <IonLabel style={{ paddingRight: "7px", textAling: "right" }}>
                                                {viewprice != 0 ? _numberFormat.format(parseFloat(select && select.totaldiscount)).replace("$", "") : "0.00"}
                                            </IonLabel>
                                        </IonCol >
                                    </IonRow >
                                    <IonRow>
                                        <IonCol col-6 size='6'>
                                            <IonLabel style={{ fontSize: "13px" }}>
                                                Total Impuestos (+)
                                            </IonLabel>
                                        </IonCol >
                                        <IonCol col-6 size='6' className="centeredfooteraccount">
                                            <IonLabel style={{ paddingRight: "7px", textAling: "right" }}>
                                                {viewprice != 0 ? _numberFormat.format(parseFloat(select && select.totaliva)).replace("$", "") : "0.00"}
                                            </IonLabel>
                                        </IonCol >
                                    </IonRow >
                                    <IonRow>
                                        <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                            <IonLabel >
                                                Total Neto
                                            </IonLabel>
                                        </IonCol >
                                        <IonCol col-6 size='6' className="centeredfooteraccount">
                                            <IonLabel style={{ paddingRight: "7px", textAling: "right" }}>
                                                {viewprice != 0 ? _numberFormat.format(parseFloat(select && select.total)).replace("$", "") : "0.00"}
                                            </IonLabel>
                                        </IonCol >
                                    </IonRow >
                                    <IonRow style={{ textAling: "right" }} className="centerright">
                                        <IonCol col-12 size='12' style={{ fontSize: "13px", textAling: "right" }} className="centerright">
                                            <IonButton style={{ fontSize: "12px", textAling: "right" }} onClick={(ev) => this.onWillDismiss(ev)}>
                                                Salir
                                            </IonButton>
                                        </IonCol >
                                    </IonRow >
                                </IonToolbar>
                            </IonFooter>
                        </IonContent>
                    </IonModal>
                    <IonModal ref={this.modalrecei} isOpen={modalOpenRece} trigger="open-modal" onWillDismiss={(ev) => this.onWillDismissrece(ev)}>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="start">
                                    <IonButton onClick={() => this.modalrecei.current?.dismiss()}> <IonIcon slot="end" icon={arrowBack}></IonIcon></IonButton>
                                </IonButtons>
                                <IonTitle>Recibo</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            <IonRow>
                                <IonCol col-3 size='3'>
                                    <IonLabel >
                                        Numero:
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                        {receipt_select && receipt_select.numero}
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel >
                                        Referencia:
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                        {receipt_select && receipt_select.referencia}
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol col-3 size='3'>
                                    <IonLabel >
                                        Fecha:
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                        {receipt_select && receipt_select.fecha}
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel >
                                        Emision:
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                        {receipt_select && receipt_select.emision}
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol col-3 size='3'>
                                    <IonLabel >
                                        F. Pago:
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                        {receipt_select && receipt_select.formapago}
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel >
                                        Usuario:
                                    </IonLabel>
                                </IonCol>
                                <IonCol col-3 size='3'>
                                    <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                        {receipt_select && receipt_select.usuario}
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol col-4 size='4'>
                                    <IonLabel >
                                        Observación:
                                    </IonLabel>
                                </IonCol>

                            </IonRow>
                            <IonRow>
                                <IonCol col-12 size='12'>
                                    <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                        {receipt_select && receipt_select.observacion}
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                            <div className="table-wrapper">

                                <table className="sticky-header sticky-column">
                                    <thead>
                                        <tr>
                                            <th >Factura</th>
                                            <th >Fecha</th>
                                            <th >Saldo</th>
                                            <th >Abono</th>
                                            <th >Nuevo Saldo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {receipt_select && receipt_select.details.length > 0 && receipt_select.details.map((val: any, key: any) => {
                                            totalnetorece += parseFloat(val.totalneto);
                                            return (
                                                <tr key={key}>
                                                    <td>{val.factura}</td>
                                                    <td>{val.fechafactura}</td>
                                                    <td style={{ textAlign: "right" }}>{_numberFormat.format(val.saldo).replace("$", "")}</td>
                                                    <td style={{ textAlign: "right" }}>{_numberFormat.format(val.abono).replace("$", "")}</td>
                                                    <td style={{ textAlign: "right" }}>{_numberFormat.format(val.nuevosaldo).replace("$", "")}</td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </IonContent>
                        <IonFooter collapse="fade">
                            <IonToolbar>
                                <IonRow>
                                    <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                        <span style={{ color: "black", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>VALOR</span>
                                    </IonCol >
                                    <IonCol col-6 size='6' className="centeredfooteraccount">
                                        <IonLabel style={{ fontWeight: "bold", textAling: "right" }}>
                                            {_numberFormat.format(receipt_select && receipt_select.valor).replace("$", "")}
                                        </IonLabel>
                                    </IonCol >
                                </IonRow >
                                <IonRow>
                                    <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                        <span style={{ color: "black", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>IR</span>
                                    </IonCol >
                                    <IonCol col-6 size='6' className="centeredfooteraccount">
                                        <IonLabel style={{ fontWeight: "bold", textAling: "right" }}>
                                            {_numberFormat.format(receipt_select && receipt_select.ir).replace("$", "")}
                                        </IonLabel>
                                    </IonCol >
                                </IonRow >
                                <IonRow>
                                    <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                        <span style={{ color: "black", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>ALC</span>
                                    </IonCol >
                                    <IonCol col-6 size='6' className="centeredfooteraccount">
                                        <IonLabel style={{ fontWeight: "bold", textAling: "right" }}>
                                            {_numberFormat.format(receipt_select && receipt_select.al).replace("$", "")}
                                        </IonLabel>
                                    </IonCol >
                                </IonRow >
                                <IonRow>
                                    <IonCol col-6 size='6' style={{ fontSize: "13px" }}>
                                        <span style={{ color: "black", fontWeight: "bold", fontSize: "15px", padding: "0px", margin: "0px" }}>TOTAL NETO</span>
                                    </IonCol >
                                    <IonCol col-6 size='6' className="centeredfooteraccount">
                                        <IonLabel style={{ fontWeight: "bold", textAling: "right" }}>
                                            {_numberFormat.format(receipt_select && receipt_select.totalneto).replace("$", "")}
                                        </IonLabel>
                                    </IonCol >
                                </IonRow >
                                <IonRow style={{ textAling: "right" }} className="centerright">
                                    <IonCol col-12 size='12' style={{ fontSize: "13px", textAling: "right" }} className="centerright">
                                        <IonButton style={{ fontSize: "12px", textAling: "right" }} onClick={(ev) => this.onWillDismissrece(ev)}>
                                            Salir
                                        </IonButton>
                                    </IonCol >
                                </IonRow >
                            </IonToolbar>
                        </IonFooter>
                    </IonModal>
                </IonContent>
            </IonPage>
        );
    };
}

const mapStateToProps = (state: any) => {
    return {
        catalogue: state.catalogue
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        //setDataCategories: (data: any, item: string) => dispatch(FavoritiesActions.setDataFavorities(data, item)),
        updateArray: (item: string) => dispatch(HomeActions.updateArray(item)),
        deleteitempedido: (id: any) => dispatch(ShoppingCartAction.deleteitempedido(id)),


    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(Accounts));
