import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import {
    IonBackButton,
    IonButtons,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonSlides,
    IonSlide,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonItemDivider,
    IonList,
    IonItem,
    IonThumbnail,
    IonImg,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonButton,
    IonInfiniteScroll,
    IonInput,
    IonBadge,
    IonInfiniteScrollContent, IonFab, IonFabButton, IonItemGroup,
    IonLoading, IonFabList, IonModal, IonFooter
} from '@ionic/react';
import { connect } from 'react-redux'
import HomeActions from '../actions/HomeActions'
import ShoppingCartAction from '../actions/ShoppingCartAction'
import { RESOURCESREACT } from '../constants/system';
import { cubeOutline, calendarClearOutline, searchSharp, albumsOutline, homeOutline, shareSocialOutline, list, cart, cartOutline, heartOutline, arrowUp, ellipsisVerticalOutline, closeOutline, searchOutline, warningOutline } from 'ionicons/icons';
import { getislogued, getDataJWT, getModeList, getPathImg, getpermissions, getShoppingCart, getsearch_input } from '../utils/functions';
import { API } from '../constants/system';
import './home.css';
import PermissionContainer from '../components/PermissionContainer';
import { Share } from '@capacitor/share';
import moment from "moment";

interface IProps {
    addfavorities: any;
    addshoppingcart: any;
    updateArray: any;
}

interface IState {
    catalogue: any;
    match: any;
    categories_products: any;
    pages: any;
    page: number;
    limit: number;
    totalrows: any;
    data_pagination: any;
    loadmore_data_pagination: any;
    cataloge_categorie: any
    searchText: any;
    favorities: any;
    shoppingcart: any;
    loading: any;
    modalOpen: any;
    product: any;
    scrollup: any;
    mode_list: any;
    modalOpenCaducidad: any;
    dataCaducidad: any;
}

const slideOpts = {
    initialSlide: 0,
    speed: 400
};

const options2 = { style: 'currency', currency: 'USD' };
const _numberFormat = new Intl.NumberFormat('en-US', options2);

class CategoriesProducts extends React.Component<IProps, IState> {
    ionInfiniteScrollRefProducts: React.RefObject<HTMLIonInfiniteScrollElement>;
    contentRef: React.RefObject<HTMLIonContentElement>;
    modal: React.RefObject<HTMLIonModalElement>;
    modalCaducidad: React.RefObject<HTMLIonModalElement>;

    constructor(props: any) {
        super(props)
        this.contentRef = React.createRef<HTMLIonContentElement>();
        this.ionInfiniteScrollRefProducts = React.createRef<HTMLIonInfiniteScrollElement>();
        this.modal = React.createRef<HTMLIonModalElement>();
        this.modalCaducidad = React.createRef<HTMLIonModalElement>();

        this.state = {
            scrollup: props.scrollup,
            loading: props.loading,
            loadmore_data_pagination: props.loadmore_data_pagination,
            data_pagination: props.data_pagination,
            limit: props.limit,
            totalrows: props.totalrows,
            page: props.page,
            pages: props.pages,
            match: props.match,
            catalogue: props.catalogue,
            categories_products: props.catalogue.categories_products,
            cataloge_categorie: props.cataloge_categorie,
            searchText: props.searchText,
            favorities: props.catalogue.favorities,
            shoppingcart: props.catalogue.shoppingcart,
            modalOpen: props.modalOpen,
            product: props.product,
            mode_list: props.mode_list,

            modalOpenCaducidad: props.modalOpenCaducidad,
            dataCaducidad: props.dataCaducidad



        }
    }
    ionViewWillEnter() {
        //console.log('ionViewWillEnter event fired');
        this.props.updateArray({ refresh_shopping: true });
    }

    ionViewWillLeave() {
        //console.log('ionViewWillLeave event fired');
    }

    ionViewDidEnter() {
        //console.log('ionViewDidEnter event fired cargo');
        setTimeout(this.props.updateArray({ refresh_shopping: false }), 100);

    }

    ionViewDidLeave() {
        //console.log('ionViewDidLeave event fired');
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.state.scrollup != nextProps.catalogue.scrollup) {
            this.buttomup();
        }
        let news_favorities = false;
        if (this.state.favorities && nextProps.catalogue.favorities.length != this.state.favorities.length) {
            news_favorities = true;
        };

        let news_shoppingcart = false;
        if (this.state.shoppingcart && nextProps.catalogue.shoppingcart.length != this.state.shoppingcart.length || nextProps.catalogue.refresh_shopping) {
            news_shoppingcart = true;
        };

        this.setState({
            favorities: nextProps.catalogue.favorities,
            shoppingcart: nextProps.catalogue.shoppingcart,
            categories_products: nextProps.catalogue.categories_products,
            loading: nextProps.catalogue.categories_products.length == 0 ? false : nextProps.catalogue.loading,
            scrollup: nextProps.catalogue.scrollup
        });

        if (news_favorities) {
            setTimeout(this.refreshdata.bind(this), 100);
        };
        if (news_shoppingcart) {
            setTimeout(this.refreshdata.bind(this), 100);
        };
    }

    componentDidMount() {
        let search_input = getsearch_input();
        let mode_list = getModeList();

        this.props.updateArray({ refresh_home: false, loading: true });
        let limit = 10;
        let pages = 0;
        let page = 1;
        let totalrows = 0;
        let searchText = "";

        let categorie_id = this.state.match.params.child;
        let filter_cataloge_categorie: any = [];

        /*if (categorie_id == 0) {
            var tem_catalogo_categorie_full = {
                color: null,
                imagen: null,
                nid: categorie_id,
                nombre: "Catalogo de Productos",
                catalogo: this.state.catalogue.products,
                totalrows: this.state.catalogue.products.length,
                catalogo_limit:[]
            };
            filter_cataloge_categorie = tem_catalogo_categorie_full;
            pages = Math.ceil(tem_catalogo_categorie_full.catalogo.length / limit);
            totalrows = tem_catalogo_categorie_full.catalogo.length;
        } else {*/
        filter_cataloge_categorie = this.state.catalogue.categories_products.length > 0 ? this.state.catalogue.categories_products.filter((cat: any) => cat.nid == categorie_id) : [];
        filter_cataloge_categorie = filter_cataloge_categorie.length > 0 ? filter_cataloge_categorie[0] : [];
        pages = filter_cataloge_categorie.length > 0 ? Math.ceil(filter_cataloge_categorie.catalogo.length / limit) : 0;
        totalrows = filter_cataloge_categorie.length > 0 ? filter_cataloge_categorie.catalogo.length : 0;
        //}

        this.setState({
            cataloge_categorie: filter_cataloge_categorie,
            limit: 10,
            pages: pages,
            page: page,
            totalrows: totalrows,
            loadmore_data_pagination: true,
            searchText: search_input,
            modalOpen: false,
            scrollup: false,
            mode_list: mode_list,
            modalOpenCaducidad: false

        });

        setTimeout(this.datapaginrenderinit.bind(this), 100);
    }

    datapaginrenderinit() {
        let cataloguelist: any = [];
        let final_limit = this.state.limit * 1;

        let temp_array_cataloge_categorie = this.state.searchText != "" ? this.state.cataloge_categorie.catalogo.filter((cat: any) => cat.nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) || cat.codigo.toLowerCase().includes(this.state.searchText.toLowerCase())) : this.state.cataloge_categorie.catalogo;
        temp_array_cataloge_categorie.map((val: any, key: any) => {
            if (key >= ((final_limit - 1) - 10) && key <= (final_limit - 1)) {
                cataloguelist.push(val);
            };
        });
        this.setState({
            data_pagination: cataloguelist,
            page: 1,
            pages: Math.ceil(temp_array_cataloge_categorie.length / this.state.limit),
            totalrows: temp_array_cataloge_categorie.length
        });
        this.props.updateArray({ loading: false });
        setTimeout(this.refreshdata.bind(this), 100);

    }

    loadMoreItems(event: any) {

        if (!this.state.loadmore_data_pagination) {
            if (this.ionInfiniteScrollRefProducts.current) {
                this.ionInfiniteScrollRefProducts.current.complete();
            }
        }

        let cataloguelist: any = [];
        let final_limit = this.state.limit * (this.state.page + 1);
        let temp_array_cataloge_categorie = this.state.searchText != "" ? this.state.cataloge_categorie.catalogo.filter((cat: any) => cat.nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) || cat.codigo.toLowerCase().includes(this.state.searchText.toLowerCase())) : this.state.cataloge_categorie.catalogo;
        temp_array_cataloge_categorie.map((val: any, key: any) => {
            if (key > ((final_limit - 1) - this.state.limit) && key <= (final_limit - 1)) {
                cataloguelist.push(val);
            }
        });

        if (this.state.loadmore_data_pagination) {
            setTimeout(this.datapaginrenderconcat.bind(this, cataloguelist), 100);
        }

        if ((this.state.page + 1) == this.state.pages) {
            this.setState({
                loadmore_data_pagination: false,
            });
            if (this.ionInfiniteScrollRefProducts.current) {
                this.ionInfiniteScrollRefProducts.current.complete();
            }
        }
    }

    datapaginrenderconcat(cataloguelist: any, event: any) {
        this.setState({
            data_pagination: this.state.data_pagination.concat(cataloguelist),
            page: this.state.page + 1
        });
        if (this.ionInfiniteScrollRefProducts.current) {
            this.ionInfiniteScrollRefProducts.current.complete();
        }
    }

    setSearchText(e: any) {
        const value = e.target.value;
        this.setState({
            searchText: value.toLowerCase()
        });
        setTimeout(this.datapaginrenderinit.bind(this), 100);

        window.localStorage.setItem('search_input', value.toLowerCase());

    }

    addfavorities(e: any, row: any) {
        this.props.addfavorities(row.nid);
    }

    refreshdata() {
        let categorie_id = this.state.match.params.child;
        let cataloguelist: any = [];

        var tem_catalogo_categorie = {
            color: this.state.cataloge_categorie.color,
            imagen: this.state.cataloge_categorie.imagen,
            nid: this.state.cataloge_categorie.nid,
            nombre: this.state.cataloge_categorie.nombre,
            catalogo: null,
            catalogo_limit: this.state.cataloge_categorie.catalogo_limit,
            totalrows: this.state.cataloge_categorie.totalrows
        };

        /*if (categorie_id == 0) {

        } else {*/
        this.state.cataloge_categorie.catalogo.map((val: any, key: any) => {
            let filter_product = this.state.favorities.filter((cat: any) => cat == val.nid);
            let filter_product_ = this.state.shoppingcart.filter((cat: any) => cat.nid == val.nid);
            if (filter_product.length > 0) {
                let tem = val;
                tem['coloricon'] = "#eb445a";
                if (filter_product_.length > 0) {
                    tem['coloriconshoppingcart'] = "#424242";
                    tem['quantityshoppingcart'] = filter_product_[0].quantity;

                } else {
                    tem['coloriconshoppingcart'] = "#66666694";
                    tem['quantityshoppingcart'] = 0;

                }
                cataloguelist.push(tem);
            } else {
                let tem = val;
                tem['coloricon'] = "#66666694";
                if (filter_product_.length > 0) {
                    tem['coloriconshoppingcart'] = "#424242";
                    tem['quantityshoppingcart'] = filter_product_[0].quantity;

                } else {
                    tem['coloriconshoppingcart'] = "#66666694";
                    tem['quantityshoppingcart'] = 0;

                }
                cataloguelist.push(tem);
            }
        });
        // }
        tem_catalogo_categorie.catalogo = cataloguelist;

        let temp_categorie_cataloge: any = [];
        this.state.categories_products.map((val: any, key: any) => {
            if (val.nid == categorie_id) {
                temp_categorie_cataloge.push(tem_catalogo_categorie);
            } else {
                temp_categorie_cataloge.push(val);
            }
        });
        this.props.updateArray({ categories_products: temp_categorie_cataloge, loading: false });
    }

    errorimg(e: any, row: any) {
        //e.target.src = API + getPathImg() + "nodisponible.jpg";
        e.target.src = 'assets/nodisponible.jpg';

    }
    /*addshoppingcart(e: any, row: any) {
        this.props.addshoppingcart(row.nid);
    }*/
    buttomup() {
        if (this.contentRef.current) {
            this.contentRef.current.scrollToTop();
            this.props.updateArray({ scrollup: false });

        }
    }
    //
    addshoppingcart(e: any) {
        if (this.state.product.modifiers.length != this.state.product.modifiersall.length) {
            this.props.updateArray({
                response: { Msg: "Debe seleccionar un modificador por grupo" },
                simpleToast: true
            });
        } else {
            let product = {
                nid: this.state.product.nid,
                modifiers: this.state.product.modifiers
            }
            this.props.addshoppingcart(product);
            this.setState({
                modalOpen: false,
                product: {}
            });
        }
    }
    addshoppingcartprev(e: any, row: any) {
        if (row.modifiersall.length == 0) {
            this.props.addshoppingcart({ nid: row.nid, modifiers: [] });
        } else {
            this.setState({
                modalOpen: true,
                product: {
                    nid: row.nid,
                    name: row.nombre + " (" + row.codigo + ")",
                    modifiersall: row.modifiersall,
                    modifiers: []
                }
            });
        }
    }
    closemodal(e: any) {
        this.setState({
            modalOpen: false,
            product: {}
        });
    }
    modifiersrender(row: any, key: any) {
        let modifierslist = [];
        let nombre = "";
        if (row && row.modifiers.length > 0) {
            modifierslist = row.modifiers.map((val: any, key_: any) => {
                let filter_modifiers: any = [];
                filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == val.idgrupo && item.nid == val.nid);
                nombre = val.nombregrupo;
                return (
                    <IonButton key={key_} color={filter_modifiers.length > 0 ? "success" : "secondary"} onClick={(ev) => this.addmodifiers(ev, val)}> {val.nombre}</IonButton>
                );
            });
        }

        return (
            <IonRow key={key}>
                <IonCol col-12 size='12'>
                    <IonLabel>{nombre}</IonLabel>
                </IonCol>
                <IonCol col-12 size='12'>
                    {modifierslist}
                </IonCol>
            </IonRow>
        );
    }
    addmodifiers(e: any, row: any) {
        let selectmodifier = {
            nid: row.nid,
            idgrupo: row.idgrupo
        }
        let product = this.state.product;

        let filter_modifiers: any = [];
        filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == row.idgrupo);
        if (filter_modifiers.length == 0) {
            product.modifiers.push(selectmodifier);

        } else {
            let tem_modifiers: any = [];
            filter_modifiers.map((val: any, key_: any) => {
                if (val.idgrupo == row.idgrupo) {
                    val.nid = row.nid;
                    tem_modifiers.push(val);
                } else {
                    tem_modifiers.push(val);
                }
            });
            // product.modifiers = tem_modifiers;
        }
        this.setState({
            product: product
        });
    }
    async sendwha(e: any, row: any) {
        /*// Define the content you want to share
        const shareText = 'Check out this awesome content!';
        const shareUrl = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==" alt="Red dot" />';
        // Create the WhatsApp share URL
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}%20${encodeURIComponent(shareUrl)}`;
        // Open WhatsApp with the share URL
        window.location.href = whatsappUrl;*/

        await Share.share({
            title: row.nombre,
            text: "Producto: " + row.nombre + ", Codigo: " + row.codigo + ", Imagen: ",
            url: API + getPathImg() + row.imagen,
            dialogTitle: 'dialogTitle',
            //files: [API + getPathImg() + row.imagen ],
        });
    }
    changeModeList() {
        let mode_list: any = this.state.mode_list ? false : true;
        window.localStorage.setItem('mode_list_codessi', mode_list);
        this.setState({
            mode_list: mode_list
        });
    }
    renderCaducidades(row: any) {
        let array_caducidades = JSON.parse(row.caducidades);
        let dataCaducidad: any = row;
        dataCaducidad['array_caducidades'] = array_caducidades

        let array_caducidades_render = array_caducidades.map((val: any, key: any) => {
            let color_caducidad = "";
            let array_colors = row.criterios.split(",");
            let array_colors_intervalos: any = [];

            array_colors.map((val_: any, key_: any) => {
                let current_dat = val_.split("-");
                let next_dat = array_colors[key_ + 1] ? array_colors[key_ + 1].split("-") : [];
                array_colors_intervalos.push({ init: parseFloat(current_dat[0]), final: next_dat.length > 0 ? next_dat[0] - 1 : 0, color: current_dat[1] });
            });

            let date_current = moment(new Date(), 'YYYY-MM-DD');
            let date_caducidad = moment(val.Caducidad, 'YYYY-MM-DD');

            let diff = moment.duration(date_current.diff(date_caducidad)).asDays();
            val.Dias = Math.ceil(diff);
            let dias_valor_exact = val.Dias;

            val.Dias = val.Dias < 0 ? val.Dias * -1 : val.Dias;

            array_colors_intervalos.map((val_: any, key_: any) => {

                if (color_caducidad == "") {

                    if (val_.final > 0) {
                        if (val.Dias >= val_.init && val.Dias <= val_.final) {
                            color_caducidad = val_.color;
                        }
                    } else {
                        color_caducidad = val_.color;
                    }
                }

            });

            return (

                <IonCard style={{ marginInline: "2px", textAlign: 'center' }} key={key}>
                    <IonItem>
                        <IonCol col-12 size='12' style={{ textAlign: 'left', color: "#808289" }}>
                            <IonRow>
                                <IonCol col-7 size='7' style={{ textAlign: 'left' }} >
                                    <strong style={{ color: "#000000" }}>Lote {val.Numero}</strong><br />
                                </IonCol>
                                <IonCol col-5 size='5' style={{ textAlign: 'right' }}>
                                    <IonBadge style={{ background: color_caducidad }}>{parseFloat(dias_valor_exact) <= 0 ? "Vence en " + (parseFloat(val.Dias)) + " Días" : "Vencido hace " + parseFloat(val.Dias) + " días"}</IonBadge>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol col-6 size='6' style={{ textAlign: 'left' }}>
                                    <IonIcon style={{ fontSize: "20px" }} icon={calendarClearOutline} /> {val.Caducidad}
                                </IonCol>
                                <IonCol col-6 size='6' style={{ textAlign: 'right' }}>
                                    <IonIcon style={{ fontSize: "20px" }} icon={cubeOutline} /> {_numberFormat.format(parseFloat(val.Cantidad)).replace("$", "")}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonItem>
                </IonCard>

            );
        });
        dataCaducidad['array_caducidades_render'] = array_caducidades_render
        this.setState({
            modalOpenCaducidad: true,
            dataCaducidad: dataCaducidad
        });
    }
    closemodalCaducidades(row: any) {
        this.setState({
            modalOpenCaducidad: false,
            dataCaducidad: {}
        });
    }

    render_products_card(val: any, key: any, viewprice: any, viewstock: any) {
        return (
            <IonCol key={key} col-6 size='6'>
                <IonCard style={{ marginInline: "0px", textAlign: 'center' }}>
                    <IonRow>
                        {
                            val.caducidades && val.caducidades != "" ?
                                <IonCol col-6 style={{ textAlign: "left" }}>
                                    <IonIcon style={{ color: 'crimson', fontSize: "25px" }} icon={warningOutline} onClick={e => this.renderCaducidades(val)} />
                                </IonCol> :
                                null
                        }
                        <IonCol col-6 style={{ textAlign: "center" }}>
                            {
                                val.quantityshoppingcart > 0 ? <IonBadge color="success">{val.quantityshoppingcart}</IonBadge> : null
                            }
                        </IonCol>
                        <IonCol col-6 style={{ textAlign: "right" }}>
                            <IonLabel style={{ fontSize: "10px" }}>
                                {viewstock != 0 ? <> <IonIcon style={{ fontSize: "20px" }} icon={cubeOutline} /> {_numberFormat.format(parseFloat(val.stock)).replace("$", "")}</> : null}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <Link to={"/detail_product/" + val.nid} style={{ textAlign: 'center' }}><img style={{ height: "200px", textAlign: 'center' }} onError={e => this.errorimg(e, val)} src={API + getPathImg() + val.imagen} /></Link>
                    <IonCardContent style={{ padding: "5px" }}>
                        <IonRow>
                            <IonCol col-12 style={{ textAlign: "center" }}>
                                <IonLabel style={{ fontSize: "10px" }}>
                                    {val.codigo}
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol col-12 style={{ textAlign: "left", lineHeight: '10px' }}>
                                <IonLabel style={{ fontSize: "10px", lineHeight: '10px' }}>
                                    {val.nombre.substring(0, 280) + '...'}
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol col-4 size='4' style={{ textAlign: 'left' }} >
                                <IonLabel position="stacked" style={{ fontWeight: 'bold', fontSize: "10px" }}>
                                    {viewprice != 0 ? _numberFormat.format(parseFloat(val.precio1)).replace("$", "") : null}
                                </IonLabel>
                            </IonCol>
                            <IonCol col-8 size='8' style={{ textAlign: 'right' }}>
                                <IonIcon style={{ fontSize: "25px" }} icon={shareSocialOutline} onClick={e => this.sendwha(e, val)} />
                                &nbsp;
                                <IonIcon style={{ color: val.coloricon, fontSize: "25px" }} icon={heartOutline} onClick={e => this.addfavorities(e, val)} />
                                &nbsp;
                                <IonIcon style={{ color: val.coloriconshoppingcart, fontSize: "25px" }} icon={cart} onClick={e => this.addshoppingcartprev(e, val)} />

                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        );
    }

    render_products_list(val: any, key: any, viewprice: any, viewstock: any) {
        return (

            <IonCard key={key} style={{ marginInline: "2px", textAlign: 'center' }}>
                <IonItem>
                    <IonThumbnail slot="start">
                        <Link to={"/detail_product/" + val.nid} style={{ textAlign: 'center' }}><img style={{ textAlign: 'center', width: "100%" }} onError={e => this.errorimg(e, val)} src={API + getPathImg() + val.imagen} /></Link>
                    </IonThumbnail>
                    <IonCol col-12 size='12' style={{ textAlign: 'left', color: "#808289" }}>
                        <IonRow>
                            <IonCol col-6 size='6' style={{ textAlign: 'left' }} >
                                <strong style={{ color: "#000000" }}>{val.codigo}</strong><br />
                            </IonCol>
                            <IonCol col-6 size='6' style={{ textAlign: 'right' }}>
                                {viewstock != 0 ? <> <IonIcon style={{ fontSize: "20px" }} icon={cubeOutline} /> {_numberFormat.format(parseFloat(val.stock)).replace("$", "")}</> : null}
                            </IonCol>
                        </IonRow>
                        {val.nombre}<br />
                        <IonRow>
                            <IonCol col-4 size='4' style={{ textAlign: 'left' }}>
                                {viewprice != 0 ? <>{_numberFormat.format(parseFloat(val.precio1)).replace("$", "")}<br /></> : null}
                            </IonCol>
                            <IonCol col-8 size='8' style={{ textAlign: 'right' }} >
                                {
                                    val.caducidades && val.caducidades != "" ?
                                        <IonIcon style={{ color: 'crimson', fontSize: "25px" }} icon={warningOutline} onClick={e => this.renderCaducidades(val)} />
                                        :
                                        null
                                } &nbsp;
                                <IonIcon style={{ fontSize: "25px" }} icon={shareSocialOutline} onClick={e => this.sendwha(e, val)} /> &nbsp;
                                <IonIcon style={{ color: val.coloricon, fontSize: "25px" }} icon={heartOutline} onClick={e => this.addfavorities(e, val)} /> &nbsp;
                                <IonIcon style={{ color: val.coloriconshoppingcart, fontSize: "25px" }} icon={cart} onClick={e => this.addshoppingcartprev(e, val)} />&nbsp;&nbsp;
                                {
                                    val.quantityshoppingcart > 0 ? <IonBadge color="success">{val.quantityshoppingcart}</IonBadge> : null
                                }
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonItem>
            </IonCard>

        );
    }
    render() {
        const { dataCaducidad, data_pagination, modalOpen, searchText, loading, mode_list, modalOpenCaducidad } = this.state;
        let cataloguelist: any = [];
        let viewprice = 0;
        let viewstock = 0;

        if (getpermissions() && getpermissions().length > 0) {
            let filter_pri = getpermissions().filter((item: any) => item.nid == 34)[0];
            if (filter_pri) {
                viewprice = filter_pri.valor
            }

            let filter_stock = getpermissions().filter((item: any) => item.nid == 38)[0];
            if (filter_stock) {
                viewstock = filter_stock.valor
            }
        }
        if (data_pagination && data_pagination.length > 0) {
            cataloguelist = [];

            cataloguelist = data_pagination.map((val: any, key: any) =>
                mode_list ? this.render_products_list(val, key, viewprice, viewstock) : this.render_products_card(val, key, viewprice, viewstock)
            );
        };
        return (
            <IonPage>
                <div style={{ display: "none" }}>
                    <IonSearchbar onIonChange={e => this.setSearchText(e)} value={searchText}></IonSearchbar>
                </div>
                <IonHeader color="primary">
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/ecommers/home" text={""} />
                        </IonButtons>
                        <IonTitle>{this.state.cataloge_categorie && this.state.cataloge_categorie.nombre ? this.state.cataloge_categorie.nombre : ""}</IonTitle>
                        <IonButton slot="end" color='secondary' onClick={() => this.changeModeList()}>
                            <IonIcon icon={mode_list ? albumsOutline : list}></IonIcon>
                        </IonButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen ref={this.contentRef} scrollEvents={true}>
                    <IonLoading
                        //color="light"
                        cssClass='my-custom-class'
                        isOpen={loading}
                        message={'Cargando...'}
                    />
                    <IonRow >
                        <IonCol size='10' style={{ textAlign: "center", padding: '0px' }}>
                            <DebounceInput
                                className='btn_search'
                                minLength={2}
                                debounceTimeout={300}
                                value={searchText}
                                onChange={event => this.setState({ searchText: event.target.value })} />
                        </IonCol>
                        <IonCol size='2' style={{ textAlign: "left", padding: '0px' }}>
                            <IonButton size="large" color="white" style={{ textAling: "center", marginRight: '10px', height: "39px", color: "#7c7d83" }} onClick={e => this.setState({ searchText: "" })}>
                                {
                                    searchText != '' ? "X" : <IonIcon icon={searchSharp}></IonIcon>
                                }
                            </IonButton>
                        </IonCol>
                        {/*<IonSearchbar onIonChange={e => this.setSearchText(e)} show-cancel-button="never" placeholder="Buscar por Producto"></IonSearchbar>*/}


                    </IonRow>
                    <IonRow >
                        {cataloguelist.length > 0 ?
                            cataloguelist
                            :
                            <IonCol col-12 style={{ textAlign: "center" }}>
                                <IonLabel style={{ fontSize: "10px" }}>
                                    Nos se encontraron productos para esta categoria
                                </IonLabel>
                            </IonCol>
                        }
                    </IonRow>
                    <IonInfiniteScroll threshold="50px" ref={this.ionInfiniteScrollRefProducts} onIonInfinite={this.loadMoreItems.bind(this)}>
                        <IonInfiniteScrollContent
                            loadingSpinner="bubbles"
                            loadingText="Cargando mas información...">
                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                    <IonFab vertical="bottom" horizontal="end" slot="fixed">
                        <IonFabButton>
                            <IonIcon icon={ellipsisVerticalOutline} />
                        </IonFabButton>
                        <IonFabList side="top">
                            <IonFabButton><Link style={{ textDecoration: 'none', color: "black" }} to={"/ecommers/favorites"}><IonIcon style={{ color: "black" }} icon={heartOutline} /></Link></IonFabButton>
                            <IonFabButton><Link style={{ textDecoration: 'none', color: "black" }} to={"/ecommers/cart"}><IonIcon style={{ color: "black" }} icon={cartOutline} /></Link></IonFabButton>
                            <IonFabButton><Link style={{ textDecoration: 'none', color: "black" }} to={"/ecommers/home"}><IonIcon style={{ color: "black" }} icon={homeOutline} /></Link></IonFabButton>
                        </IonFabList>
                    </IonFab>
                    <IonModal onWillDismiss={e => this.closemodal(e)} ref={this.modal} isOpen={modalOpen} trigger="open-modal" initialBreakpoint={0.50} breakpoints={[0, 0.50, 0.5, 0.75]}>
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonTitle>{this.state.product && this.state.product.name}</IonTitle>
                                <IonButton slot="end" style={{ textAling: "center", marginRight: '10px' }} onClick={(ev) => this.closemodal(ev)}>
                                    <IonIcon icon={closeOutline}></IonIcon>
                                </IonButton>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            {
                                this.state.product && this.state.product.modifiersall ?
                                    this.state.product.modifiersall.map((val: any, key: any) =>
                                        this.modifiersrender(val, key)
                                    )
                                    :
                                    null
                            }
                            <IonFooter collapse="fade">
                                <IonToolbar>
                                    <IonButton expand="block" className="ion-no-margin" onClick={e => this.addshoppingcart(e)} > <IonIcon size="small" icon={cartOutline} />&nbsp;&nbsp;Agregar al Pedido</IonButton>
                                </IonToolbar>
                            </IonFooter>
                        </IonContent>
                    </IonModal>


                    <IonModal onWillDismiss={e => this.closemodalCaducidades(e)} ref={this.modalCaducidad} isOpen={modalOpenCaducidad} trigger="open-modal" initialBreakpoint={0.50} breakpoints={[0, 0.50, 0.5, 0.75]}>
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonTitle>{dataCaducidad && dataCaducidad.nombre ?
                                    dataCaducidad.nombre : null}</IonTitle>
                                <IonButton slot="end" style={{ textAling: "center", marginRight: '10px' }} onClick={(ev) => this.closemodalCaducidades(ev)}>
                                    <IonIcon icon={closeOutline}></IonIcon>
                                </IonButton>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">

                            {
                                dataCaducidad && dataCaducidad.array_caducidades_render ?
                                    dataCaducidad.array_caducidades_render : null
                            }
                        </IonContent>
                    </IonModal>
                    <IonFab vertical="bottom" style={{ marginBottom: "20px" }} horizontal="center" slot="fixed">
                        <IonFabButton>
                            <IonFabButton onClick={e => this.buttomup()}><IonIcon icon={arrowUp}></IonIcon></IonFabButton>
                        </IonFabButton>
                    </IonFab>
                </IonContent>
            </IonPage >
        );
    };
}

const mapStateToProps = (state: any) => {
    return {
        data: state.data,
        catalogue: state.catalogue
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateArray: (item: string) => dispatch(HomeActions.updateArray(item)),
        addfavorities: (id: any) => dispatch(HomeActions.addfavorities(id)),
        addshoppingcart: (id: any) => dispatch(ShoppingCartAction.addshoppingcart(id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(CategoriesProducts));