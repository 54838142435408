import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import {
    IonBackButton,
    IonButtons,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonSlides,
    IonSlide,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonItemDivider,
    IonList,
    IonItem,
    IonThumbnail,
    IonImg,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonButton,
    IonInfiniteScroll,
    IonInput,
    IonBadge, IonCheckbox,
    IonInfiniteScrollContent, IonFab, IonFabButton, IonItemGroup,
    IonLoading, IonFabList, IonModal, IonFooter
} from '@ionic/react';
import { connect } from 'react-redux'
import HomeActions from '../actions/HomeActions'
import ShoppingCartAction from '../actions/ShoppingCartAction'
import { RESOURCESREACT } from '../constants/system';
import { filter, arrowBack, cubeOutline, calendarClearOutline, searchSharp, albumsOutline, homeOutline, shareSocialOutline, list, cart, cartOutline, heartOutline, arrowUp, ellipsisVerticalOutline, closeOutline, searchOutline, warningOutline } from 'ionicons/icons';
import { getislogued, getDataJWT, getModeList, getPathImg, getpermissions, getShoppingCart, getsearch_input } from '../utils/functions';
import { API } from '../constants/system';
import './home.css';
import PermissionContainer from '../components/PermissionContainer';
import { Share } from '@capacitor/share';
import moment from "moment";

interface IProps {
    addfavorities: any;
    addshoppingcart: any;
    updateArray: any;
}

interface IState {
    catalogue: any;
    match: any;
    categories_products: any;
    pages: any;
    page: number;
    limit: number;
    totalrows: any;
    data_pagination: any;
    loadmore_data_pagination: any;
    cataloge_categorie: any
    searchText: any;
    favorities: any;
    shoppingcart: any;
    loading: any;
    modalOpen: any;
    product: any;
    scrollup: any;
    mode_list: any;
    modalOpenCaducidad: any;
    dataCaducidad: any;
    openmodalrange: any;
    list_ranges_data: any;
}

const slideOpts = {
    initialSlide: 0,
    speed: 400
};

const options2 = { style: 'currency', currency: 'USD' };
const _numberFormat = new Intl.NumberFormat('en-US', options2);

class CategoriesProductsVencidos extends React.Component<IProps, IState> {
    ionInfiniteScrollRefProducts: React.RefObject<HTMLIonInfiniteScrollElement>;
    contentRef: React.RefObject<HTMLIonContentElement>;
    modal: React.RefObject<HTMLIonModalElement>;
    modalCaducidad: React.RefObject<HTMLIonModalElement>;
    modalrange: React.RefObject<HTMLIonModalElement>;

    constructor(props: any) {
        super(props)
        this.contentRef = React.createRef<HTMLIonContentElement>();
        this.ionInfiniteScrollRefProducts = React.createRef<HTMLIonInfiniteScrollElement>();
        this.modal = React.createRef<HTMLIonModalElement>();
        this.modalCaducidad = React.createRef<HTMLIonModalElement>();
        this.modalrange = React.createRef<HTMLIonModalElement>();

        this.state = {
            scrollup: props.scrollup,
            loading: props.loading,
            loadmore_data_pagination: props.loadmore_data_pagination,
            data_pagination: props.data_pagination,
            limit: props.limit,
            totalrows: props.totalrows,
            page: props.page,
            pages: props.pages,
            match: props.match,
            catalogue: props.catalogue,
            categories_products: props.catalogue.categories_products,
            cataloge_categorie: props.cataloge_categorie,
            searchText: props.searchText,
            favorities: props.catalogue.favorities,
            shoppingcart: props.catalogue.shoppingcart,
            modalOpen: props.modalOpen,
            product: props.product,
            mode_list: props.mode_list,

            modalOpenCaducidad: props.modalOpenCaducidad,
            dataCaducidad: props.dataCaducidad,
            list_ranges_data: [
                { init: 31, check: false, id: 1 },
                { init: 10, check: false, id: 2 }
            ],

            openmodalrange: props.openmodalrange


        }
    }
    ionViewWillEnter() {
        //console.log('ionViewWillEnter event fired');
        this.props.updateArray({ refresh_shopping: true });
    }

    ionViewWillLeave() {
        //console.log('ionViewWillLeave event fired');
    }

    ionViewDidEnter() {
        //console.log('ionViewDidEnter event fired cargo');
        setTimeout(this.props.updateArray({ refresh_shopping: false }), 100);

    }

    ionViewDidLeave() {
        //console.log('ionViewDidLeave event fired');
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.state.scrollup != nextProps.catalogue.scrollup) {
            this.buttomup();
        }
        let news_favorities = false;
        if (this.state.favorities && nextProps.catalogue.favorities.length != this.state.favorities.length) {
            news_favorities = true;
        };

        let news_shoppingcart = false;
        if (this.state.shoppingcart && nextProps.catalogue.shoppingcart.length != this.state.shoppingcart.length || nextProps.catalogue.refresh_shopping) {
            news_shoppingcart = true;
        };

        this.setState({
            favorities: nextProps.catalogue.favorities,
            shoppingcart: nextProps.catalogue.shoppingcart,
            categories_products: nextProps.catalogue.catalogo_categorie_full_vencidos,
            loading: nextProps.catalogue.catalogo_categorie_full_vencidos.length == 0 ? false : nextProps.catalogue.loading,
            scrollup: nextProps.catalogue.scrollup
        });

        if (news_favorities) {
            setTimeout(this.refreshdata.bind(this), 100);
        };
        if (news_shoppingcart) {
            setTimeout(this.refreshdata.bind(this), 100);
        };
    }

    componentDidMount() {
        let search_input = getsearch_input();
        let mode_list = getModeList();

        this.props.updateArray({ refresh_home: false, loading: true });
        let limit = 10;
        let pages = 0;
        let page = 1;
        let totalrows = 0;
        let searchText = "";

        let categorie_id = this.state.match.params.child;
        let filter_cataloge_categorie: any = [];

        /*if (categorie_id == 0) {
            var tem_catalogo_categorie_full = {
                color: null,
                imagen: null,
                nid: categorie_id,
                nombre: "Catalogo de Productos",
                catalogo: this.state.catalogue.products,
                totalrows: this.state.catalogue.products.length,
                catalogo_limit:[]
            };
            filter_cataloge_categorie = tem_catalogo_categorie_full;
            pages = Math.ceil(tem_catalogo_categorie_full.catalogo.length / limit);
            totalrows = tem_catalogo_categorie_full.catalogo.length;
        } else {*/
        filter_cataloge_categorie = this.state.catalogue.catalogo_categorie_full_vencidos.length > 0 ? this.state.catalogue.catalogo_categorie_full_vencidos.filter((cat: any) => cat.nid == categorie_id) : [];
        filter_cataloge_categorie = filter_cataloge_categorie.length > 0 ? filter_cataloge_categorie[0] : [];
        pages = filter_cataloge_categorie.length > 0 ? Math.ceil(filter_cataloge_categorie.catalogo.length / limit) : 0;
        totalrows = filter_cataloge_categorie.length > 0 ? filter_cataloge_categorie.catalogo.length : 0;
        //}

        this.setState({
            cataloge_categorie: filter_cataloge_categorie,
            limit: 10,
            pages: pages,
            page: page,
            totalrows: totalrows,
            loadmore_data_pagination: true,
            searchText: search_input,
            modalOpen: false,
            scrollup: false,
            mode_list: mode_list,
            modalOpenCaducidad: false,
            openmodalrange: false,


        });

        setTimeout(this.datapaginrenderinit.bind(this), 100);
    }

    datapaginrenderinit() {
        console.log('datapaginrenderinit');
        let cataloguelist: any = [];
        let final_limit = this.state.limit * 1;

        let temp_array_cataloge_categorie = this.state.searchText != "" ? this.state.cataloge_categorie.catalogo.filter((cat: any) => cat.nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) || cat.codigo.toLowerCase().includes(this.state.searchText.toLowerCase())) : this.state.cataloge_categorie.catalogo;
        temp_array_cataloge_categorie.map((val: any, key: any) => {
            if (val.caducidades != "") {
                let array_caducidades = JSON.parse(val.caducidades);
                let array_days: any = [];
                array_caducidades.map((row: any, key_: any) => {
                    if (val.criterios) {

                        let date_current = moment(new Date(), 'YYYY-MM-DD');
                        let date_caducidad = moment(row.Caducidad, 'YYYY-MM-DD');

                        let diff = moment.duration(date_current.diff(date_caducidad)).asDays();
                        row.Dias = Math.ceil(diff);

                        row.Dias = row.Dias < 0 ? row.Dias * -1 : row.Dias;
                        array_days.push(row.Dias);
                    }
                });

                let range_view_count = false;
                let range_check = false;
                this.state.list_ranges_data.map((row_list_range: any, key_list_range: any) => {
                    if (row_list_range.check) {
                        range_check = true;
                    };
                    if (row_list_range.init) {
                        let filter_menores = array_days.filter((cat: any) => cat <= row_list_range.init);
                        if (filter_menores.length > 0) {
                            range_view_count = true;
                        }
                    }
                });

                console.log(range_check, range_view_count, array_days, this.state.list_ranges_data);

                if (range_check) {
                    if (key >= ((final_limit - 1) - 10) && key <= (final_limit - 1) && range_view_count) {
                        cataloguelist.push(val);
                    };
                } else {
                    if (key >= ((final_limit - 1) - 10) && key <= (final_limit - 1)) {
                        cataloguelist.push(val);
                    };
                }



            }

        });
        this.setState({
            data_pagination: cataloguelist,
            page: 1,
            pages: Math.ceil(temp_array_cataloge_categorie.length / this.state.limit),
            totalrows: temp_array_cataloge_categorie.length
        });
        this.props.updateArray({ loading: false });
        setTimeout(this.refreshdata.bind(this), 100);

    }

    loadMoreItems(event: any) {

        if (!this.state.loadmore_data_pagination) {
            if (this.ionInfiniteScrollRefProducts.current) {
                this.ionInfiniteScrollRefProducts.current.complete();
            }
        }

        let cataloguelist: any = [];
        let final_limit = this.state.limit * (this.state.page + 1);
        let temp_array_cataloge_categorie = this.state.searchText != "" ? this.state.cataloge_categorie.catalogo.filter((cat: any) => cat.nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) || cat.codigo.toLowerCase().includes(this.state.searchText.toLowerCase())) : this.state.cataloge_categorie.catalogo;
        temp_array_cataloge_categorie.map((val: any, key: any) => {
            if (val.caducidades != "") {
                let array_caducidades = JSON.parse(val.caducidades);
                let array_days: any = [];
                array_caducidades.map((row: any, key_: any) => {
                    if (val.criterios) {

                        let date_current = moment(new Date(), 'YYYY-MM-DD');
                        let date_caducidad = moment(row.Caducidad, 'YYYY-MM-DD');

                        let diff = moment.duration(date_current.diff(date_caducidad)).asDays();
                        row.Dias = Math.ceil(diff);

                        row.Dias = row.Dias < 0 ? row.Dias * -1 : row.Dias;
                        array_days.push(row.Dias);
                    }
                });

                let range_view_count = false;
                let range_check = false;
                this.state.list_ranges_data.map((row_list_range: any, key_list_range: any) => {
                    if (row_list_range.check) {
                        range_check = true;
                    };
                    if (row_list_range.init) {
                        let filter_menores = array_days.filter((cat: any) => cat <= row_list_range.init);
                        if (filter_menores.length > 0) {
                            range_view_count = true;
                        }
                    }
                });

                console.log(range_check, range_view_count, array_days, this.state.list_ranges_data);

                if (range_check) {
                    if (key > ((final_limit - 1) - this.state.limit) && key <= (final_limit - 1) && range_view_count) {
                        cataloguelist.push(val);
                    };
                } else {
                    if (key > ((final_limit - 1) - this.state.limit) && key <= (final_limit - 1)) {
                        cataloguelist.push(val);
                    };
                }
            }
        });

        if (this.state.loadmore_data_pagination) {
            setTimeout(this.datapaginrenderconcat.bind(this, cataloguelist), 100);
        }

        if ((this.state.page + 1) == this.state.pages) {
            this.setState({
                loadmore_data_pagination: false,
            });
            if (this.ionInfiniteScrollRefProducts.current) {
                this.ionInfiniteScrollRefProducts.current.complete();
            }
        }
    }

    datapaginrenderconcat(cataloguelist: any, event: any) {
        this.setState({
            data_pagination: this.state.data_pagination.concat(cataloguelist),
            page: this.state.page + 1
        });
        if (this.ionInfiniteScrollRefProducts.current) {
            this.ionInfiniteScrollRefProducts.current.complete();
        }
    }

    setSearchText(e: any) {
        const value = e.target.value;
        this.setState({
            searchText: value.toLowerCase()
        });
        setTimeout(this.datapaginrenderinit.bind(this), 100);

        window.localStorage.setItem('search_input', value.toLowerCase());

    }

    addfavorities(e: any, row: any) {
        this.props.addfavorities(row.nid);
    }

    refreshdata() {
        let categorie_id = this.state.match.params.child;
        let cataloguelist: any = [];

        var tem_catalogo_categorie = {
            color: this.state.cataloge_categorie.color,
            imagen: this.state.cataloge_categorie.imagen,
            nid: this.state.cataloge_categorie.nid,
            nombre: this.state.cataloge_categorie.nombre,
            catalogo: null,
            catalogo_limit: this.state.cataloge_categorie.catalogo_limit,
            totalrows: this.state.cataloge_categorie.totalrows
        };

        /*if (categorie_id == 0) {

        } else {*/
        this.state.cataloge_categorie.catalogo.map((val: any, key: any) => {
            let filter_product = this.state.favorities.filter((cat: any) => cat == val.nid);
            let filter_product_ = this.state.shoppingcart.filter((cat: any) => cat.nid == val.nid);

            if (filter_product.length > 0) {
                let tem = val;
                tem['coloricon'] = "#eb445a";
                if (filter_product_.length > 0) {
                    tem['coloriconshoppingcart'] = "#424242";
                    tem['quantityshoppingcart'] = filter_product_[0].quantity;

                } else {
                    tem['coloriconshoppingcart'] = "#66666694";
                    tem['quantityshoppingcart'] = 0;

                }
                cataloguelist.push(tem);
            } else {
                let tem = val;
                tem['coloricon'] = "#66666694";
                if (filter_product_.length > 0) {
                    tem['coloriconshoppingcart'] = "#424242";
                    tem['quantityshoppingcart'] = filter_product_[0].quantity;

                } else {
                    tem['coloriconshoppingcart'] = "#66666694";
                    tem['quantityshoppingcart'] = 0;

                }
                cataloguelist.push(tem);
            }
        });
        // }
        tem_catalogo_categorie.catalogo = cataloguelist;

        let temp_categorie_cataloge: any = [];
        this.state.categories_products.map((val: any, key: any) => {
            if (val.nid == categorie_id) {
                temp_categorie_cataloge.push(tem_catalogo_categorie);
            } else {
                temp_categorie_cataloge.push(val);
            }
        });
        this.props.updateArray({ categories_products: temp_categorie_cataloge, loading: false });
    }

    errorimg(e: any, row: any) {
        //e.target.src = API + getPathImg() + "nodisponible.jpg";
        e.target.src = 'assets/nodisponible.jpg';

    }
    /*addshoppingcart(e: any, row: any) {
        this.props.addshoppingcart(row.nid);
    }*/
    buttomup() {
        if (this.contentRef.current) {
            this.contentRef.current.scrollToTop();
            this.props.updateArray({ scrollup: false });

        }
    }
    //
    addshoppingcart(e: any) {
        if (this.state.product.modifiers.length != this.state.product.modifiersall.length) {
            this.props.updateArray({
                response: { Msg: "Debe seleccionar un modificador por grupo" },
                simpleToast: true
            });
        } else {
            let product = {
                nid: this.state.product.nid,
                modifiers: this.state.product.modifiers
            }
            this.props.addshoppingcart(product);
            this.setState({
                modalOpen: false,
                product: {}
            });
        }
    }
    addshoppingcartprev(e: any, row: any) {
        if (row.modifiersall.length == 0) {
            this.props.addshoppingcart({ nid: row.nid, modifiers: [] });
        } else {
            this.setState({
                modalOpen: true,
                product: {
                    nid: row.nid,
                    name: row.nombre + " (" + row.codigo + ")",
                    modifiersall: row.modifiersall,
                    modifiers: []
                }
            });
        }
    }
    closemodal(e: any) {
        this.setState({
            modalOpen: false,
            product: {}
        });
    }
    modifiersrender(row: any, key: any) {
        let modifierslist = [];
        let nombre = "";
        if (row && row.modifiers.length > 0) {
            modifierslist = row.modifiers.map((val: any, key_: any) => {
                let filter_modifiers: any = [];
                filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == val.idgrupo && item.nid == val.nid);
                nombre = val.nombregrupo;
                return (
                    <IonButton key={key_} color={filter_modifiers.length > 0 ? "success" : "secondary"} onClick={(ev) => this.addmodifiers(ev, val)}> {val.nombre}</IonButton>
                );
            });
        }

        return (
            <IonRow key={key}>
                <IonCol col-12 size='12'>
                    <IonLabel>{nombre}</IonLabel>
                </IonCol>
                <IonCol col-12 size='12'>
                    {modifierslist}
                </IonCol>
            </IonRow>
        );
    }
    addmodifiers(e: any, row: any) {
        let selectmodifier = {
            nid: row.nid,
            idgrupo: row.idgrupo
        }
        let product = this.state.product;

        let filter_modifiers: any = [];
        filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == row.idgrupo);
        if (filter_modifiers.length == 0) {
            product.modifiers.push(selectmodifier);

        } else {
            let tem_modifiers: any = [];
            filter_modifiers.map((val: any, key_: any) => {
                if (val.idgrupo == row.idgrupo) {
                    val.nid = row.nid;
                    tem_modifiers.push(val);
                } else {
                    tem_modifiers.push(val);
                }
            });
            // product.modifiers = tem_modifiers;
        }
        this.setState({
            product: product
        });
    }
    async sendwha(e: any, row: any) {
        /*// Define the content you want to share
        const shareText = 'Check out this awesome content!';
        const shareUrl = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==" alt="Red dot" />';
        // Create the WhatsApp share URL
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}%20${encodeURIComponent(shareUrl)}`;
        // Open WhatsApp with the share URL
        window.location.href = whatsappUrl;*/


        await Share.share({
            title: row.nombre,
            text: "Producto: " + row.nombre + ", Codigo: " + row.codigo + ", Imagen: ",
            url: API + getPathImg() + row.imagen,
            dialogTitle: 'dialogTitle',
            //files: [API + getPathImg() + row.imagen ],
        });
    }
    changeModeList() {
        let mode_list: any = this.state.mode_list ? false : true;
        window.localStorage.setItem('mode_list_codessi', mode_list);
        this.setState({
            mode_list: mode_list
        });
    }
    renderCaducidades(row: any) {
        let array_caducidades = JSON.parse(row.caducidades);
        let dataCaducidad: any = row;
        dataCaducidad['array_caducidades'] = array_caducidades

        let array_caducidades_render = array_caducidades.map((val: any, key: any) => {
            let color_caducidad = "";
            let array_colors = row.criterios.split(",");
            let array_colors_intervalos: any = [];

            array_colors.map((val_: any, key_: any) => {
                let current_dat = val_.split("-");
                let next_dat = array_colors[key_ + 1] ? array_colors[key_ + 1].split("-") : [];
                array_colors_intervalos.push({ init: parseFloat(current_dat[0]), final: next_dat.length > 0 ? next_dat[0] - 1 : 0, color: current_dat[1] });
            });

            let date_current = moment(new Date(), 'YYYY-MM-DD');
            let date_caducidad = moment(val.Caducidad, 'YYYY-MM-DD');

            let diff = moment.duration(date_current.diff(date_caducidad)).asDays();
            val.Dias = Math.ceil(diff);
            let dias_valor_exact = val.Dias;
            val.Dias = val.Dias < 0 ? val.Dias * -1 : val.Dias;
            
            console.log(val.Dias, array_colors_intervalos , dias_valor_exact);
            array_colors_intervalos.map((val_: any, key_: any) => {

                if (color_caducidad == "") {

                    if (val_.final > 0) {
                        if (val.Dias >= val_.init && val.Dias <= val_.final) {
                            color_caducidad = val_.color;
                        }
                    } else {
                        color_caducidad = val_.color;
                    }
                }

            });

            return (
                <IonCard style={{ marginInline: "2px", textAlign: 'center' }} key={key}>
                    <IonItem>
                        <IonCol col-12 size='12' style={{ textAlign: 'left', color: "#808289" }}>
                            <IonRow>
                                <IonCol col-7 size='7' style={{ textAlign: 'left' }} >
                                    <strong style={{ color: "#000000" }}>Lote {val.Numero}</strong><br />
                                </IonCol>
                                <IonCol col-5 size='5' style={{ textAlign: 'right' }}>
                                    <IonBadge style={{ background: color_caducidad }}>{parseFloat(dias_valor_exact) <= 0 ? "Vence en " + (parseFloat(val.Dias)) + " Días" : "Vencido hace " + parseFloat(val.Dias) + " días"}</IonBadge>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol col-6 size='6' style={{ textAlign: 'left' }}>
                                    <IonIcon style={{ fontSize: "20px" }} icon={calendarClearOutline} /> {val.Caducidad}
                                </IonCol>
                                <IonCol col-6 size='6' style={{ textAlign: 'right' }}>
                                    <IonIcon style={{ fontSize: "20px" }} icon={cubeOutline} /> {_numberFormat.format(parseFloat(val.Cantidad)).replace("$", "")}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonItem>
                </IonCard>
            );
        });
        dataCaducidad['array_caducidades_render'] = array_caducidades_render
        this.setState({
            modalOpenCaducidad: true,
            dataCaducidad: dataCaducidad
        });
    }
    closemodalCaducidades(row: any) {
        this.setState({
            modalOpenCaducidad: false,
            dataCaducidad: {}
        });
    }

    render_products_card(row: any, key: any, viewprice: any, viewstock: any) {
        console.log(row);
        let array_caducidades = JSON.parse(row.caducidades);
        let array_caducidades_render_colors = array_caducidades.map((val: any, key: any) => {
            let color_caducidad = "";
            console.log(row.criterios);
            if (row.criterios) {
                let array_colors = row.criterios.split(",");
                let array_colors_intervalos: any = [];

                array_colors.map((val_: any, key_: any) => {
                    let current_dat = val_.split("-");
                    let next_dat = array_colors[key_ + 1] ? array_colors[key_ + 1].split("-") : [];
                    array_colors_intervalos.push({ init: parseFloat(current_dat[0]), final: next_dat.length > 0 ? next_dat[0] - 1 : 0, color: current_dat[1] });
                });

                let date_current = moment(new Date(), 'YYYY-MM-DD');
                let date_caducidad = moment(val.Caducidad, 'YYYY-MM-DD');

                let diff = moment.duration(date_current.diff(date_caducidad)).asDays();
                val.Dias = Math.ceil(diff);

                val.Dias = val.Dias < 0 ? val.Dias * -1 : val.Dias;
                array_colors_intervalos.map((val_: any, key_: any) => {
                    if (color_caducidad == "") {
                        if (val_.final > 0) {
                            if (val.Dias >= val_.init && val.Dias <= val_.final) {
                                color_caducidad = val_.color;
                            }
                        } else {
                            color_caducidad = val_.color;
                        }
                    }
                });

                return (
                    <div style={{ width: "20px", background: color_caducidad, height: "20px", border: "1px solid black" }} key={key}>

                    </div>
                );
            } else {
                return (
                    <div style={{ width: "20px", background: "white", height: "20px" }} key={key}>

                    </div>
                );
            }

        });
        return (
            <IonCol key={key} col-6 size='6'>
                <IonCard style={{ marginInline: "0px", textAlign: 'center' }}>
                    <IonRow>
                        {
                            row.caducidades && row.caducidades != "" ?
                                <IonCol col-6 style={{ textAlign: "left" }}>
                                    <IonIcon style={{ color: 'crimson', fontSize: "25px" }} icon={warningOutline} onClick={e => this.renderCaducidades(row)} />
                                </IonCol> :
                                null
                        }
                        <IonCol col-6 style={{ textAlign: "right" }}>
                            <IonLabel style={{ fontSize: "10px" }}>
                                {viewstock != 0 ? <><IonIcon style={{ fontSize: "20px" }} icon={cubeOutline} />  {_numberFormat.format(parseFloat(row.stock)).replace("$", "")}</> : null}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <img style={{ height: "200px", textAlign: 'center' }} onError={e => this.errorimg(e, row)} src={API + getPathImg() + row.imagen} />
                    <IonCardContent style={{ padding: "5px" }}>
                        <IonRow>
                            <IonCol col-12 size='12' style={{ textAlign: "center" }}>
                                <IonLabel style={{ fontSize: "10px" }}>
                                    {row.codigo}
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol col-12 size='12' style={{ textAlign: "left", lineHeight: '10px' }}>
                                <IonLabel style={{ fontSize: "10px", lineHeight: '10px' }}>
                                    {row.nombre.substring(0, 280) + '...'}
                                </IonLabel>
                            </IonCol>
                            <IonCol col-12 size='12' style={{ textAlign: "center", lineHeight: '10px', display: "ruby" }}>
                                {array_caducidades_render_colors}
                            </IonCol>
                        </IonRow>

                    </IonCardContent>
                </IonCard>
            </IonCol>
        );
    }

    render_products_list(row: any, key: any, viewprice: any, viewstock: any) {
        let array_caducidades = JSON.parse(row.caducidades);
        let array_caducidades_render_colors = array_caducidades.map((val: any, key: any) => {
            let color_caducidad = "";
            if (row.criterios) {
                let array_colors = row.criterios.split(",");
                let array_colors_intervalos: any = [];

                array_colors.map((val_: any, key_: any) => {
                    let current_dat = val_.split("-");
                    let next_dat = array_colors[key_ + 1] ? array_colors[key_ + 1].split("-") : [];
                    array_colors_intervalos.push({ init: parseFloat(current_dat[0]), final: next_dat.length > 0 ? next_dat[0] - 1 : 0, color: current_dat[1] });
                });

                let date_current = moment(new Date(), 'YYYY-MM-DD');
                let date_caducidad = moment(val.Caducidad, 'YYYY-MM-DD');

                let diff = moment.duration(date_current.diff(date_caducidad)).asDays();
                val.Dias = Math.ceil(diff);

                val.Dias = val.Dias < 0 ? val.Dias * -1 : val.Dias;
                array_colors_intervalos.map((val_: any, key_: any) => {
                    if (color_caducidad == "") {
                        if (val_.final > 0) {
                            if (val.Dias >= val_.init && val.Dias <= val_.final) {
                                color_caducidad = val_.color;
                            }
                        } else {
                            color_caducidad = val_.color;
                        }
                    }
                });

                return (
                    <div style={{ width: "20px", background: color_caducidad, height: "20px", border: "1px solid black" }} key={key}>

                    </div>
                );
            } else {
                return (
                    <div style={{ width: "20px", background: "white", height: "20px" }} key={key}>

                    </div>
                );
            }

        });
        return (
            <IonCard key={key} style={{ marginInline: "2px", textAlign: 'center', width: "100%" }}>
                <IonRow>
                    <IonCol col-4 size='4' style={{ textAlign: 'left' }} >
                        <strong style={{ color: "#000000" }}>{row.codigo}</strong><br />
                        <IonThumbnail>
                            <img style={{ textAlign: 'center', width: "100%" }} onError={e => this.errorimg(e, row)} src={API + getPathImg() + row.imagen} />
                        </IonThumbnail>
                    </IonCol>
                    <IonCol col-8 size='8'>
                        <IonRow>
                            <IonCol col-12 size='12' style={{ textAlign: 'left' }}>
                                {row.nombre}<br />
                            </IonCol>
                            <IonCol col-6 size='6' style={{ textAlign: 'left' }}>
                                {viewstock != 0 ? <><IonIcon style={{ fontSize: "20px" }} icon={cubeOutline} />  {_numberFormat.format(parseFloat(row.stock)).replace("$", "")}</> : null}
                            </IonCol>
                            <IonCol col-6 size='6' style={{ textAlign: 'right' }} >
                                {
                                    row.caducidades && row.caducidades != "" ?
                                        <IonIcon style={{ color: 'crimson', fontSize: "25px" }} icon={warningOutline} onClick={e => this.renderCaducidades(row)} />
                                        :
                                        null
                                } &nbsp;
                            </IonCol>
                            <IonCol col-12 size='12' style={{ textAlign: "center", lineHeight: '10px', display: "ruby" }}>
                                {array_caducidades_render_colors}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCard>
        );
    }
    closedmodalranges() {
        this.setState({
            openmodalrange: false,
        });
        setTimeout(this.componentDidMount.bind(this), 100);
    }
    opeclosecategoriesmodal(e: any) {
        this.setState({
            openmodalrange: this.state.openmodalrange ? false : true
        });
        setTimeout(this.refreshdata.bind(this), 100);
    }
    renderranges(row: any, key: any) {
        let filter_cat: any = [];
        let chek: any = false;

        return (
            <IonItem key={key}>
                <IonLabel>{"Menores a " + row.init} días</IonLabel>
                <IonCheckbox checked={row.check} name={'check'} onIonChange={e => this.onChangeInputranges(e, row.id)} />
            </IonItem>
        )
    }
    onChangeInputranges(e: any, id: any) {
        const newArr = this.state.list_ranges_data.map((item: any, i: any) => {
            if ((id) == item.id) {
                return { ...item, [e.target.name]: e.target.checked };
            } else {
                return item;
            }
        });
        this.setState({
            list_ranges_data: newArr
        });
    }
    render() {
        const { list_ranges_data, openmodalrange, dataCaducidad, data_pagination, modalOpen, searchText, loading, mode_list, modalOpenCaducidad } = this.state;
        let cataloguelist: any = [];
        let viewprice = 0;
        let viewstock = 0;

        if (getpermissions() && getpermissions().length > 0) {
            let filter_pri = getpermissions().filter((item: any) => item.nid == 34)[0];
            if (filter_pri) {
                viewprice = filter_pri.valor
            }

            let filter_stock = getpermissions().filter((item: any) => item.nid == 38)[0];
            if (filter_stock) {
                viewstock = filter_stock.valor
            }
        }
        if (data_pagination && data_pagination.length > 0) {
            cataloguelist = [];

            cataloguelist = data_pagination.map((val: any, key: any) =>
                mode_list ? this.render_products_list(val, key, viewprice, viewstock) : this.render_products_card(val, key, viewprice, viewstock)
            );
        };
        let list_ranges_render: any = [];
        if (list_ranges_data && list_ranges_data.length > 0) {
            list_ranges_render = list_ranges_data.map((val: any, key: any) =>
                this.renderranges(val, key)
            )
        }
        return (
            <IonPage>
                <div style={{ display: "none" }}>
                    <IonSearchbar onIonChange={e => this.setSearchText(e)} value={searchText}></IonSearchbar>
                </div>
                <IonHeader style={{ '--background': '#fbb72c' }}>
                    <IonToolbar style={{ '--background': '#fbb72c' }}>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/ecommers/home" text={""} />
                        </IonButtons>
                        <IonTitle>CADUCIDADES</IonTitle>
                        <IonButton slot="end" color='secondary' onClick={() => this.changeModeList()}>
                            <IonIcon icon={mode_list ? albumsOutline : list}></IonIcon>
                        </IonButton>
                        <IonButton slot="end" color='secondary' onClick={e => this.opeclosecategoriesmodal(e)}><IonIcon onClick={e => this.opeclosecategoriesmodal(e)} icon={filter}></IonIcon></IonButton>

                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen ref={this.contentRef} scrollEvents={true}>
                    <IonLoading
                        //color="light"
                        cssClass='my-custom-class'
                        isOpen={loading}
                        message={'Cargando...'}
                    />
                    <IonRow >
                        <IonCol size='10' style={{ padding: '0px' }}>
                            <DebounceInput
                                className='btn_search'
                                placeholder=''
                                minLength={2}
                                debounceTimeout={300}
                                value={searchText}
                                onChange={event => this.setState({ searchText: event.target.value })} />
                        </IonCol>
                        <IonCol size='2' style={{ textAlign: "left", padding: '0px' }}>
                            <IonButton size="large" color="white" style={{ textAling: "center", marginRight: '10px', height: "39px", color: "#7c7d83" }} onClick={e => this.setState({ searchText: "" })}>
                                {
                                    searchText != '' ? "X" : <IonIcon icon={searchSharp}></IonIcon>
                                }
                            </IonButton>
                        </IonCol>
                        {/*<IonSearchbar onIonChange={e => this.setSearchText(e)} show-cancel-button="never" placeholder="Buscar por Producto"></IonSearchbar>*/}


                    </IonRow>
                    <IonRow >
                        {cataloguelist.length > 0 ?
                            cataloguelist
                            :
                            <IonCol col-12 style={{ textAlign: "center" }}>
                                <IonLabel style={{ fontSize: "10px" }}>
                                    Nos se encontraron productos para esta categoria
                                </IonLabel>
                            </IonCol>
                        }
                    </IonRow>
                    <IonInfiniteScroll threshold="50px" ref={this.ionInfiniteScrollRefProducts} onIonInfinite={this.loadMoreItems.bind(this)}>
                        <IonInfiniteScrollContent
                            loadingSpinner="bubbles"
                            loadingText="Cargando mas información...">
                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                    <IonFab vertical="bottom" horizontal="end" slot="fixed">
                        <IonFabButton>
                            <IonIcon icon={ellipsisVerticalOutline} />
                        </IonFabButton>
                        <IonFabList side="top">
                            <IonFabButton><Link style={{ textDecoration: 'none', color: "black" }} to={"/ecommers/favorites"}><IonIcon style={{ color: "black" }} icon={heartOutline} /></Link></IonFabButton>
                            <IonFabButton><Link style={{ textDecoration: 'none', color: "black" }} to={"/ecommers/cart"}><IonIcon style={{ color: "black" }} icon={cartOutline} /></Link></IonFabButton>
                            <IonFabButton><Link style={{ textDecoration: 'none', color: "black" }} to={"/ecommers/home"}><IonIcon style={{ color: "black" }} icon={homeOutline} /></Link></IonFabButton>
                        </IonFabList>
                    </IonFab>
                    <IonModal onWillDismiss={e => this.closemodal(e)} ref={this.modal} isOpen={modalOpen} trigger="open-modal" initialBreakpoint={0.50} breakpoints={[0, 0.50, 0.5, 0.75]}>
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonTitle>{this.state.product && this.state.product.name}</IonTitle>
                                <IonButton slot="end" style={{ textAling: "center", marginRight: '10px' }} onClick={(ev) => this.closemodal(ev)}>
                                    <IonIcon icon={closeOutline}></IonIcon>
                                </IonButton>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            {
                                this.state.product && this.state.product.modifiersall ?
                                    this.state.product.modifiersall.map((val: any, key: any) =>
                                        this.modifiersrender(val, key)
                                    )
                                    :
                                    null
                            }
                            <IonFooter collapse="fade">
                                <IonToolbar>
                                    <IonButton expand="block" className="ion-no-margin" onClick={e => this.addshoppingcart(e)} > <IonIcon size="small" icon={cartOutline} />&nbsp;&nbsp;Agregar al Pedido</IonButton>
                                </IonToolbar>
                            </IonFooter>
                        </IonContent>
                    </IonModal>


                    <IonModal onWillDismiss={e => this.closemodalCaducidades(e)} ref={this.modalCaducidad} isOpen={modalOpenCaducidad} trigger="open-modal" initialBreakpoint={0.50} breakpoints={[0, 0.50, 0.5, 0.75]}>
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonTitle>{dataCaducidad && dataCaducidad.nombre ?
                                    dataCaducidad.nombre : null}</IonTitle>
                                <IonButton slot="end" style={{ textAling: "center", marginRight: '10px' }} onClick={(ev) => this.closemodalCaducidades(ev)}>
                                    <IonIcon icon={closeOutline}></IonIcon>
                                </IonButton>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">

                            {

                                dataCaducidad && dataCaducidad.array_caducidades_render ?
                                    dataCaducidad.array_caducidades_render : null
                            }
                        </IonContent>
                    </IonModal>
                    <IonFab vertical="bottom" style={{ marginBottom: "20px" }} horizontal="center" slot="fixed">
                        <IonFabButton>
                            <IonFabButton onClick={e => this.buttomup()}><IonIcon icon={arrowUp}></IonIcon></IonFabButton>
                        </IonFabButton>
                    </IonFab>
                </IonContent>
                <IonModal ref={this.modalrange} isOpen={openmodalrange} trigger="open-modal" onWillDismiss={(ev) => this.closedmodalranges()}>
                    <IonHeader>
                        <IonToolbar color="primary">

                            <IonButtons slot="start">
                                <IonButton onClick={(ev) => this.closedmodalranges()}> <IonIcon slot="end" icon={arrowBack}></IonIcon></IonButton>
                            </IonButtons>
                            <IonTitle>Filtrar caducidades por días</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <IonList>
                            {
                                list_ranges_render
                            }
                        </IonList>
                        <IonRow style={{ height: "60px" }}>

                        </IonRow >
                    </IonContent>
                    <IonFooter collapse="fade" className='footerfixed'>
                        <IonToolbar>
                            <IonButton slot="end" style={{ fontSize: "12px" }} onClick={(ev) => this.closedmodalranges()}>
                                Mostrar Caducidades
                            </IonButton>
                        </IonToolbar>
                    </IonFooter>
                </IonModal>
            </IonPage >
        );
    };
}

const mapStateToProps = (state: any) => {
    return {
        data: state.data,
        catalogue: state.catalogue
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateArray: (item: string) => dispatch(HomeActions.updateArray(item)),
        addfavorities: (id: any) => dispatch(HomeActions.addfavorities(id)),
        addshoppingcart: (id: any) => dispatch(ShoppingCartAction.addshoppingcart(id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(CategoriesProductsVencidos));