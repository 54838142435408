import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    IonBackButton,
    IonButtons,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonSlides,
    IonSlide,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonItemDivider,
    IonList,
    IonItem,
    IonThumbnail,
    IonImg,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonButton,
    IonInfiniteScroll,
    IonInput,
    IonInfiniteScrollContent, IonFab, IonFabButton, IonAlert, IonModal, IonFooter
} from '@ionic/react';
import { connect } from 'react-redux'
import { homeOutline, cartOutline, heart, heartOutline, closeOutline, cart, arrowUp, trash } from 'ionicons/icons';
import { getislogued, getDataJWT, getPathImg, getpermissions } from '../utils/functions';
import HomeActions from '../actions/HomeActions';
import ShoppingCartAction from '../actions/ShoppingCartAction';
import FavoritiesActions from '../actions/FavoritiesActions';
import { API } from '../constants/system';
import PermissionContainer from '../components/PermissionContainer';
interface IProps {
    setDataCategories: any;
    addfavorities: any;
    addshoppingcart: any;
    setDataShoppingCart: any;
    updateArray: any;
    deleteitem: any;
    deleteitemall: any;

}

interface IState {
    favorities: any;
    catalogue: any;
    data_pagination: any;
    refresh: any;
    refresh_: any;
    shoppingcart: any;
    itemdelete: any;
    alert: any;
    alertall: any;
    modalOpen: any;
    product: any;
    scrollup: any;
}
const options2 = { style: 'currency', currency: 'USD' };
const _numberFormat = new Intl.NumberFormat('en-US', options2);
class Favorities extends React.Component<IProps, IState> {
    contentRef: React.RefObject<HTMLIonContentElement>;
    modal: React.RefObject<HTMLIonModalElement>;
    constructor(props: any) {
        super(props)
        this.modal = React.createRef<HTMLIonModalElement>();
        this.contentRef = React.createRef<HTMLIonContentElement>();
        this.state = {
            scrollup: props.scrollup, modalOpen: props.modalOpen, product: props.product, alertall: props.alertall, alert: props.alert, itemdelete: props.itemdelete, shoppingcart: props.shoppingcart, refresh_: props.refresh_, refresh: props.refresh, favorities: props.favorities, catalogue: props.catalogue, data_pagination: props.data_pagination
        }
    }

    ionViewDidEnter() {
        this.props.updateArray({ refresh_accounts: false, refresh_favorities: true, refresh_cart: false, loading: false });
    }

    componentDidMount() {
        let temp_data_pagination: any = [];
        this.state.favorities && this.state.favorities.map((val: any, key: any) => {
            this.state.catalogue && this.state.catalogue.products.map((val_: any, key_: any) => {
                if (val == val_.nid) {
                    val_.coloricon = "#eb445a";

                    let iconcolorshoppingcart = "#66666694";
                    let filter_shoppingcart = this.state.shoppingcart.filter((cat: any) => cat.nid == val);
                    if (filter_shoppingcart && filter_shoppingcart.length > 0) {
                        iconcolorshoppingcart = "#424242";
                    };
                    val_.coloriconshoppingcart = iconcolorshoppingcart;
                    temp_data_pagination.push(val_);
                }
            });
        });
        this.setState({
            data_pagination: temp_data_pagination,
            alert: false,
            alertall: false,
            modalOpen: false
        });

        //this.props.updateArray({ loading: false });
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.state.scrollup != nextProps.catalogue.scrollup) {
            this.buttomup();
        }
        if (nextProps.catalogue.refresh_favorities != this.state.refresh) {
            this.componentDidMount();
        }

        let news_shoppingcart = false;
        if (this.state.shoppingcart && nextProps.catalogue.shoppingcart.length != this.state.shoppingcart.length) {
            news_shoppingcart = true;
        };

        this.setState({
            favorities: nextProps.catalogue.favorities,
            refresh: nextProps.catalogue.refresh_favorities,
            catalogue: nextProps.catalogue,
            shoppingcart: nextProps.catalogue.shoppingcart,
            scrollup: nextProps.catalogue.scrollup
        });

        if (news_shoppingcart) {
            setTimeout(this.refres_product.bind(this), 100);
        };

        if (nextProps.catalogue.products && nextProps.catalogue.products.length > 0) {
            setTimeout(this.refres_product.bind(this), 100);
        };

    }

    refres_product() {
        let temp_data_pagination: any = [];
        this.state.favorities && this.state.favorities.map((val: any, key: any) => {
            this.state.catalogue && this.state.catalogue.products.map((val_: any, key_: any) => {
                if (val == val_.nid) {
                    val_.coloricon = "#eb445a";

                    let iconcolorshoppingcart = "#66666694";
                    let filter_shoppingcart = this.state.shoppingcart.filter((cat: any) => cat.nid == val);
                    if (filter_shoppingcart && filter_shoppingcart.length > 0) {
                        iconcolorshoppingcart = "#424242";
                    };
                    val_.coloriconshoppingcart = iconcolorshoppingcart;
                    temp_data_pagination.push(val_);
                }
            });
        });
        this.setState({
            data_pagination: temp_data_pagination,
        });

    }

    addfavorities(e: any, row: any) {
        this.props.addfavorities(row.nid);
    }
    errorimg(e: any, row: any) {
        e.target.src = API + getPathImg() + "nodisponible.jpg";
    }
    buttomup() {
        if (this.contentRef.current) {
            this.contentRef.current.scrollToTop();
        }
    }
    deleteitemCancel(e: any) {
        this.setState({
            itemdelete: 0,
            alert: false
        });
    }
    deleteitemCancelAll(e: any) {
        this.setState({
            alertall: false
        });
    }
    deleteitemPrevall(e: any) {
        this.setState({
            alertall: true
        });
    }
    deleteitem(e: any) {
        this.props.deleteitem(this.state.itemdelete);
    }
    clearcart(e: any) {
        this.setState({
            alertall: false
        });
        this.props.deleteitemall();
        //window.location.href = "/ecommers/home";
    }
    deleteitemPrev(e: any, nid: any) {
        this.setState({
            itemdelete: nid,
            alert: true
        });
    }
    //
    addshoppingcart(e: any) {
        if (this.state.product.modifiers.length != this.state.product.modifiersall.length) {
            this.props.updateArray({
                response: { Msg: "Debe seleccionar un modificador por grupo" },
                simpleToast: true
            });
        } else {
            let product = {
                nid: this.state.product.nid,
                modifiers: this.state.product.modifiers
            }
            this.props.addshoppingcart(product);
            this.setState({
                modalOpen: false,
                product: {}
            });
        }
    }
    addshoppingcartprev(e: any, row: any) {
        if (row.modifiersall.length == 0) {
            this.props.addshoppingcart({ nid: row.nid, modifiers: [] });
        } else {
            this.setState({
                modalOpen: true,
                product: {
                    nid: row.nid,
                    name: row.nombre + " (" + row.codigo + ")",
                    modifiersall: row.modifiersall,
                    modifiers: []
                }
            });
        }
    }
    closemodal(e: any) {
        this.setState({
            modalOpen: false,
            product: {}
        });
    }
    modifiersrender(row: any, key: any) {
        let modifierslist = [];
        let nombre = "";
        if (row && row.modifiers.length > 0) {
            modifierslist = row.modifiers.map((val: any, key_: any) => {
                let filter_modifiers: any = [];
                filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == val.idgrupo && item.nid == val.nid);
                nombre = val.nombregrupo;
                return (
                    <IonButton key={key_} color={filter_modifiers.length > 0 ? "success" : "secondary"} onClick={(ev) => this.addmodifiers(ev, val)}> {val.nombre}</IonButton>
                );
            });
        }

        return (
            <IonRow key={key}>
                <IonCol col-12 size='12'>
                    <IonLabel>{nombre}</IonLabel>
                </IonCol>
                <IonCol col-12 size='12'>
                    {modifierslist}
                </IonCol>
            </IonRow>
        );
    }
    addmodifiers(e: any, row: any) {
        let selectmodifier = {
            nid: row.nid,
            idgrupo: row.idgrupo
        }
        let product = this.state.product;

        let filter_modifiers: any = [];
        filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == row.idgrupo);
        if (filter_modifiers.length == 0) {
            product.modifiers.push(selectmodifier);

        } else {
            let tem_modifiers: any = [];
            filter_modifiers.map((val: any, key_: any) => {
                if (val.idgrupo == row.idgrupo) {
                    val.nid = row.nid;
                    tem_modifiers.push(val);
                } else {
                    tem_modifiers.push(val);
                }
            });
            // product.modifiers = tem_modifiers;
        }
        this.setState({
            product: product
        });
    }
    render() {
        const { data_pagination, alert, alertall, modalOpen } = this.state;
        let cataloguelist: any = [];
        let viewprice = 0;
        if (getpermissions() && getpermissions().length > 0) {
            let filter_pri = getpermissions().filter((item: any) => item.nid == 34)[0];
            if (filter_pri) {
                viewprice = filter_pri.valor
            }
        }
        if (data_pagination && data_pagination.length > 0) {
            cataloguelist = [];
            cataloguelist = data_pagination.map((val: any, key: any) => {
                return (
                    <IonCol key={key} col-6 size='6'>
                        <IonCard style={{ marginInline: "0px", textAlign: 'center' }}>
                            <Link to={"/detail_product/" + val.nid} style={{ textAlign: 'center' }}><img style={{ height: "200px", textAlign: 'center' }} onError={e => this.errorimg(e, val)} src={API + getPathImg() + val.imagen} /></Link>
                            <IonCardContent style={{ padding: "5px" }}>
                                <IonRow>
                                    <IonCol col-12 style={{ textAlign: "center" }}>
                                        <IonLabel style={{ fontSize: "10px" }}>
                                            {val.codigo}
                                        </IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol col-12 style={{ textAlign: "center" }}>
                                        <IonLabel style={{ fontSize: "10px" }}>
                                            {val.nombre.substring(0, 15) + '...'}
                                        </IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol col-6 style={{ textAlign: 'left' }} >
                                        <IonLabel position="stacked" style={{ fontWeight: 'bold', fontSize: "10px" }}>
                                            {viewprice != 0 ? _numberFormat.format(parseFloat(val.precio1)).replace("$", "") : null}
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol col-6 style={{ textAlign: 'right' }}>
                                        <IonIcon icon={trash} size="small" style={{ color: val.coloricon, fontSize: "25px" }} onClick={e => this.deleteitemPrev(e, val.nid)} ></IonIcon>
                                        &nbsp;&nbsp;
                                        <IonIcon size="small" style={{ color: val.coloricon, fontSize: "25px" }} icon={heartOutline} onClick={e => this.addfavorities(e, val)} />
                                        &nbsp;&nbsp;
                                        <IonIcon size="small" style={{ color: val.coloriconshoppingcart, fontSize: "25px" }} icon={cart} onClick={e => this.addshoppingcartprev(e, val)} />
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                );
            });
        };
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/ecommers/home" text={""} />
                        </IonButtons>
                        <IonTitle>Mis Favoritos</IonTitle>
                        <IonButton slot="primary" color="danger" style={{ textAling: "center", marginRight: '10px' }} onClick={e => this.deleteitemPrevall(e)}>
                            <IonIcon icon={trash}></IonIcon>
                        </IonButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen ref={this.contentRef} scrollEvents={true}>
                    <IonAlert
                        backdropDismiss={false}
                        isOpen={alert}
                        header={'Alerta'}
                        //subHeader={'Verifique si conexión a internet.'}
                        message={'Esta seguro de remover este producto!!!'}
                        buttons={[
                            {
                                text: 'Si',
                                handler: e => {
                                    this.deleteitem(e);
                                }
                            },
                            {
                                text: 'No',
                                handler: e => {
                                    this.deleteitemCancel(e);
                                }
                            }
                        ]}
                    />
                    <IonAlert
                        backdropDismiss={false}
                        isOpen={alertall}
                        header={'Alerta'}
                        //subHeader={'Verifique si conexión a internet.'}
                        message={'Esta seguro de remover todos los productos!!!'}
                        buttons={[
                            {
                                text: 'Si',
                                handler: e => {
                                    this.clearcart(e);
                                }
                            },
                            {
                                text: 'No',
                                handler: e => {
                                    this.deleteitemCancelAll(e);
                                }
                            }
                        ]}
                    />

                    {cataloguelist.length > 0 ?
                        <IonRow >
                            {cataloguelist}
                        </IonRow>
                        :
                        <IonContent>
                            <PermissionContainer />
                        </IonContent>

                    }

                    <IonModal onWillDismiss={e => this.closemodal(e)} ref={this.modal} isOpen={modalOpen} trigger="open-modal" initialBreakpoint={0.50} breakpoints={[0, 0.50, 0.5, 0.75]}>
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonTitle>{this.state.product && this.state.product.name}</IonTitle>
                                <IonButton slot="end" style={{ textAling: "center", marginRight: '10px' }} onClick={(ev) => this.closemodal(ev)}>
                                    <IonIcon icon={closeOutline}></IonIcon>
                                </IonButton>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            {
                                this.state.product && this.state.product.modifiersall ?
                                    this.state.product.modifiersall.map((val: any, key: any) =>
                                        this.modifiersrender(val, key)
                                    )
                                    :
                                    null
                            }
                            <IonFooter collapse="fade">
                                <IonToolbar>
                                    <IonButton expand="block" className="ion-no-margin" onClick={e => this.addshoppingcart(e)} > <IonIcon size="small" icon={cartOutline} />&nbsp;&nbsp;Agregar al Pedido</IonButton>
                                </IonToolbar>
                            </IonFooter>
                        </IonContent>
                    </IonModal>
                </IonContent>
            </IonPage>
        );
    };
}

const mapStateToProps = (state: any) => {
    return {
        catalogue: state.catalogue
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setDataCategories: (data: any, item: string) => dispatch(HomeActions.setDataFavorities(data, item)),
        addfavorities: (id: any) => dispatch(HomeActions.addfavorities(id)),
        addshoppingcart: (id: any) => dispatch(ShoppingCartAction.addshoppingcart(id)),
        setDataShoppingCart: (data: any, item: string) => dispatch(ShoppingCartAction.setDataShoppingCart(data, item)),
        updateArray: (item: string) => dispatch(HomeActions.updateArray(item)),
        deleteitem: (id: string) => dispatch(FavoritiesActions.deleteitem(id)),
        deleteitemall: () => dispatch(FavoritiesActions.deleteitemall())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(Favorities));
