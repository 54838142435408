import * as ActionTypes from '../constants/ActionTypes'
import { getRequest, postRequest_ } from './http'
import { getFavorities } from '../utils/functions';

//Favorities
export function setDataFavorities(items, name) {
    return {
        type: ActionTypes.DATA_FAVORITIES,
        name: name,
        items: items
    }
}

const updateArray = (content) => {
    return {
        type: ActionTypes.UPDATE_ARRAY,
        items: content
    }
}


const addfavorities = (id) => {
    return (dispatch) => {
        let arrayfavorities = !getFavorities() ? [] : getFavorities();
        let array_favorities_tem = [];
        let exit = false;
        if (arrayfavorities) {
            for (var j = 0; j <= arrayfavorities.length - 1; j++) {
                if (arrayfavorities[j] == id) {
                    exit = true;
                } else {
                    array_favorities_tem.push(arrayfavorities[j]);
                }
            }
        };

        if (!exit) {
            array_favorities_tem.push(id);
            window.localStorage.setItem('favorities', JSON.stringify(array_favorities_tem));
            //dispatch(setDataFavorities(array_favorities_tem, 'data'));
            dispatch(setDataFavorities(array_favorities_tem, 'refresh_'));

        } else {
            window.localStorage.setItem('favorities', JSON.stringify(array_favorities_tem));
            //dispatch(setDataFavorities(array_favorities_tem, 'data'));
            dispatch(setDataFavorities(array_favorities_tem, 'refresh_'));
        };
        /*setTimeout(function () {
            dispatch(setDataFavorities(false, 'refresh'));
        }, 1000);*/

    }
};

export function deleteitem(id, quantity) {
    return (dispatch) => {
        let arrayfavorities = !getFavorities() ? [] : getFavorities();
        let array_favorities_tem = [];
        let exit = false;
        if (arrayfavorities) {
            for (var j = 0; j <= arrayfavorities.length - 1; j++) {
                if (arrayfavorities[j] == id) {
                    //exit = true;
                } else {
                    array_favorities_tem.push(arrayfavorities[j]);
                }
            }
        };

        window.localStorage.setItem('favorities', JSON.stringify(array_favorities_tem));
        dispatch(updateArray({ favorities: array_favorities_tem, refresh_favorities: true }));
        setTimeout(function () {
            dispatch(updateArray({ refresh_favorities: false }))
        }, 100);
    }
}
export function deleteitemall() {
    return (dispatch) => {
        window.localStorage.removeItem("favorities");
        let arrayfavorities = !getFavorities() ? [] : getFavorities();
        window.localStorage.setItem('favorities', JSON.stringify(arrayfavorities));
        dispatch(updateArray({ favorities: arrayfavorities, refresh_favorities: true }));
        setTimeout(function () {
            dispatch(updateArray({ refresh_favorities: false }))
        }, 100);
    }
}

export default {
    setDataFavorities,
    addfavorities,
    deleteitem,
    deleteitemall
}