import { combineReducers } from 'redux';

import data from './../reducers/data';
import catalogue from './../reducers/catalogue';
import categories from './../reducers/categories';
import favorities from './../reducers/favorities';
import accounts from './../reducers/accounts';
import shoppingcart from './../reducers/shoppingcart';

export default combineReducers({
  data,
  catalogue,
  categories,
  favorities,
  accounts,
  shoppingcart
});