import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  IonButton,
  IonRouterContext,
  IonRouteProps,
  IonLoading,
  IonToast,
  IonFab,
  IonFabButton

} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { homeOutline, cartOutline, person, heartOutline, logoUsd, arrowUp, warningOutline } from 'ionicons/icons';
import ExploreContainer from '../components/PermissionContainer';
import { createBrowserHistory } from 'history';
import Home from './Home';
import CategoriesProductsVencidos from './CategoriesProductsVencidos';

import Cart from './Cart';
import Favorities from './Favorities';
import Accounts from './Accounts';
import CategoriesProducts from './CategoriesProducts';
import { connect } from 'react-redux'
import HomeActions from '../actions/HomeActions'
import { RESOURCESREACT } from '../constants/system';
import { BASE_URL } from './../BaseUrl';
import { getdatalogin, getDataJWT, getpermissions } from '../utils/functions';
import './dashboard.css';

setupIonicReact();

const history = createBrowserHistory({ basename: '/ecommers' });
interface IProps {
  get_: any;
  post_: any;
  updateArray: any;
}

interface IState {
  match: any;
  catalogue: any;
  refresh_home: any;
  loading: any;
  simpleToast: any;
  response: any;
  latlong: any;
  resize: any;
  scrollup: any;
}
let resizeinit = "";
let resizeinit_temp = "";
resizeinit = window.innerHeight + ",";
resizeinit_temp = window.innerHeight + ",";
class Dashboard extends React.Component<IProps, IState> {
  contentRef: React.RefObject<HTMLIonContentElement>;
  constructor(props: any) {
    super(props)
    this.contentRef = React.createRef<HTMLIonContentElement>();
    this.state = { scrollup: props.scrollup, resize: props.resize, latlong: props.latlong, response: props.response, simpleToast: props.simpleToast, loading: props.loading, refresh_home: props.refresh_home, match: props.match, catalogue: props.catalogue }
  }
  componentDidMount() {
    var te = navigator.geolocation.watchPosition((position) => {
      let region = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
      this.onRegionChange(region);
    }, (error) => console.log(error));

    if (getdatalogin()) {
      setTimeout(this.renderinit.bind(this), 100);
    } else {
      window.localStorage.removeItem("_token_");
      window.localStorage.removeItem("data_login");
      window.localStorage.removeItem("islogued");
      window.location.href = "/login";
    }
    window.addEventListener('resize', this.handleResize.bind(this));
  }
  handleResize() {
    resizeinit_temp = window.innerHeight + ",";
    this.setState({
      resize: window.innerHeight + ","
    });
  };
  onRegionChange(row: any) {
    this.setState({
      latlong: row.latitude + "," + row.longitude,
      resize: resizeinit
    });
  }
  renderinit() {
    let datalogin = getdatalogin();
    datalogin['latlong'] = this.state.latlong ? this.state.latlong : "";
    window.localStorage.setItem('data_login', JSON.stringify(datalogin));
    this.props.post_({ typeOption: "listasv3", page: 1, latlong: this.state.latlong ? this.state.latlong : "" }, "api/movil/v1/listasv3");
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      catalogue: nextProps.catalogue,
      refresh_home: nextProps.catalogue.refresh_home,
      loading: nextProps.catalogue.loading,
      simpleToast: nextProps.catalogue.simpleToast,
      response: nextProps.catalogue.response,
      scrollup: nextProps.catalogue.scrollup
    });
  }
  vi(e: any) {
    this.props.updateArray({ loading: true, refresh_home: false });
  }
  onDidDismissToast(e: any) {
    if (this.state.response && this.state.response.Msg == "Agregado al Carrito") {
      this.props.updateArray({ response: {}, simpleToast: false, refresh_shopping: false });
    } else {
      this.props.updateArray({ response: {}, simpleToast: false });
    }

  }
  buttomup(e: any) {
    this.props.updateArray({ scrollup: this.state.scrollup ? false : true });
  }
  render() {
    const { loading, simpleToast, response, resize } = this.state;
    let vieworders = 0;
    let viewsells = 0;
    let viewpayments = 0;
    let viewprice = 0;
    if (getpermissions() && getpermissions().length > 0) {
      let filter_order = getpermissions().filter((item: any) => item.nid == 32)[0];
      if (filter_order) {
        vieworders = filter_order.valor
      }
      let filter_sell = getpermissions().filter((item: any) => item.nid == 31)[0];
      if (filter_sell) {
        viewsells = filter_sell.valor
      }
      let filter_payment = getpermissions().filter((item: any) => item.nid == 33)[0];
      if (filter_payment) {
        viewpayments = filter_payment.valor
      }
      let filter_pri = getpermissions().filter((item: any) => item.nid == 34)[0];
      if (filter_pri) {
        viewprice = filter_pri.valor
      }
    }
    return (
      <IonContent fullscreen ref={this.contentRef} scrollEvents={true}>
        <IonLoading
          //color="light"
          cssClass='my-custom-class'
          isOpen={loading}
          message={'Cargando...'}
        />
        <IonToast
          isOpen={simpleToast}
          buttons={[
            {
              text: 'Ok',
              role: 'cancel'
            }
          ]}
          message={`${response && response.Msg}`}
          duration={10000}
          onDidDismiss={e => this.onDidDismissToast(e)}
        />
        <IonTabs onIonTabsDidChange={e => this.vi(e)}>
          <IonRouterOutlet >
            <Route path="/ecommers/categoriesProductsVencidos" component={CategoriesProductsVencidos} exact={true} />
            <Route path="/ecommers/home" component={Home} exact={true} />
            <Route path="/ecommers/cart" component={Cart} exact={true} />
            <Route path="/ecommers/favorites" component={Favorities} exact={true} />
            <Route path="/ecommers/accounts" component={Accounts} exact={true} />
            <Route path="/" render={() => <Redirect to="/ecommers/home" />} exact={true} />
            <Route path="/ecommers/" render={() => <Redirect to="/ecommers/home" />} exact={true} />
          </IonRouterOutlet>

          <IonTabBar slot="bottom" className={resizeinit_temp == resizeinit ? '' : 'ocul'}>
            <IonTabButton tab="ecommers/home" href={`/ecommers/home`}>
              <IonIcon icon={homeOutline} />
              <IonLabel>Inicio</IonLabel>
            </IonTabButton>
            <IonTabButton tab="ecommers/cart" href={`/ecommers/cart`}>
              <IonIcon icon={cartOutline} />
              <IonLabel>Carrito</IonLabel>
            </IonTabButton>
            <IonTabButton tab="ecommers/favorites" href={`/ecommers/favorites`}>
              <IonIcon icon={heartOutline} />
              <IonLabel>Favoritos</IonLabel>
            </IonTabButton>

            <IonTabButton tab="ecommers/accounts" href={`/ecommers/accounts`}>
              <IonIcon icon={logoUsd} />
              <IonLabel>Transacciones</IonLabel>
            </IonTabButton>

          </IonTabBar>
        </IonTabs>
        <IonFab vertical="bottom" style={{ marginBottom: "20px" }} horizontal="center" slot="fixed">
            <IonFabButton onClick={e => this.buttomup(e)}><IonIcon icon={arrowUp}></IonIcon></IonFabButton>
        </IonFab>
        <IonFab vertical="bottom" style={{ marginBottom: "20px" }} horizontal="end" slot="fixed">
          <IonFabButton style={{ '--background': '#fbb72c' }} onClick={e => {
            window.location.href = "#/products_vencidos/0";
            //window.location.reload();
          }}><IonIcon style={{ color: 'crimson', fontSize: "25px" }} icon={warningOutline}></IonIcon></IonFabButton>
        </IonFab>
      </IonContent>

    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    _get: PropTypes.func.isRequired,
    _post: PropTypes.func.isRequired,
    data: state.data,
    catalogue: state.catalogue,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    get_: (fields: any, controller: string) => dispatch(HomeActions.get_(fields, controller)),
    post_: (fields: any, controller: string) => dispatch(HomeActions.post_(fields, controller)),
    updateArray: (item: string) => dispatch(HomeActions.updateArray(item)),

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
