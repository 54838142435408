import * as ActionTypes from './../constants/ActionTypes'
//import update from "react-addons-update";
const initialState = {
  loading: true,
  categories: [],
  images: [],
  modifiers: [],
  products: [],
  new_products:[],
  refresh_cart: false,
  refresh_favorities: false,
  refresh_accounts: false,
  refresh_home: true,
  categories_products: [],
  accounts: [],
  favorities: [],
  response: {},
  simpleToast: false,
  company: {},
  receipts:[],
  scrollup: false,
  refresh_shopping: false
};

export default (state = initialState, action = {}) => {

  let pagination = "pag_" + [action.name];
  let more = "loadmore_" + [action.name];
  switch (action.type) {
    case ActionTypes.LOADING_CHANGE_CATALOGUE:
      return {
        ...state,
        loading: !state.loading
      }
    case ActionTypes.DATA_CATALOGUE:
      return {
        ...state,
        [action.name]: action.items
      };
    case ActionTypes.DATA_CONCAT:
      return {
        ...state,
        [action.name]: state[action.name].concat(action.items)
      };
    case ActionTypes.DATA_WITH_PAGINATION_CATALOGUE:
      return {
        ...state,
        [action.name]: state[action.name].length === 0 ? action.items : state[action.name].concat(action.items),
        [pagination]: state[pagination] + 1,
        [more]: action.items.length > 0 ? true : false
      };
    case ActionTypes.UPDATE_ARRAY:
      return Object.assign({}, state, action.items);
    default: return state;
  }
}