import * as ActionTypes from './../constants/ActionTypes'

const initialState = {
    loading: false,
    data:[],
    categorie_cataloge:[],
    view_categorie:false,
    fields: {para:[]},
    formErrors: {errors:{}},
};

export default (state = initialState, action = {}) => {
  switch(action.type) {
  case ActionTypes.LOADING_CHANGE_CATEGORIES:
    return {
      ...state,
      loading: !state.loading
    }
  case ActionTypes.DATA_CATEGORIES:
    return {
      ...state,
      [action.name]: action.items
    };
  default: return state;
  }
}