import * as ActionTypes from './../constants/ActionTypes'

const initialState = {
  loading: false,
  data: [],
  fields: {
    email: '',
    password: '',
    kempresa: ''
  },
  response: {},
  formErrors: { errors: {} },
  simpleToast: false
};

export default (state = initialState, action = {}) => {
  let pagination = "pag" + [action.name];
  let more = "loadmore" + [action.name];

  switch (action.type) {
    case ActionTypes.LOADING_CHANGE:
      return {
        ...state,
        loading: !state.loading
      }
    case ActionTypes.DATA:
      return {
        ...state,
        [action.name]: action.items
      };
    default: return state;
  }
}