export const SERVER = 'http://inventario.devlancer.site/';
export const API = 'https://web.codesii.com/public/';
export const RESOURCES = 'http://inventario.devlancer.site/';
export const RESOURCESREACT = 'public/assets/';
export const SERVER_EDU_CODESII = 'https://www.edu.codesii.com/';
export const IMAGE = 'http://inventario.devlancer.site/';

/*
export const SERVER = 'http://appedu.codesii.com/';
export const API = 'http://localhost/devinventory/';
export const RESOURCES = 'https://www.edu.codesii.com/public/uploaded/';
export const RESOURCESREACT = 'public/assets/';
export const SERVER_EDU_CODESII = 'https://www.edu.codesii.com/';
export const IMAGE = 'https://www.edu.codesii.com/';
*/
