import React from 'react';
import PropTypes from 'prop-types';

import {
    IonBackButton,
    IonButtons,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonSlides,
    IonSlide,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonItemDivider,
    IonList,
    IonItem,
    IonThumbnail,
    IonImg,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonButton,
    IonInfiniteScroll,
    IonInput,
    IonInfiniteScrollContent,
    IonLoading,
    IonModal,
    IonFooter,
    IonToast,


} from '@ionic/react';
import { connect } from 'react-redux'
import HomeActions from '../actions/HomeActions'
import ShoppingCartAction from '../actions/ShoppingCartAction';
import { RESOURCESREACT } from '../constants/system';
import { homeOutline, cartOutline, arrowForward, arrowBack } from 'ionicons/icons';
import { getislogued, getDataJWT, getPathImg, getpermissions } from '../utils/functions';
import { API } from '../constants/system';
import './home.css';

interface IProps {
    get_: any;
    post_: any;
    concat_data_: any;
    setData: any;
    setDataCategories: any;
    addshoppingcart: any;
    updateArray: any;
}

interface IState {
    match: any;
    catalogue: any;
    product: any;
    images: any;
    modifiers: any;
    loading: any;
    modalOpen: any;
    simpleToast: any;
    response: any;
}

const slideOpts = {
    initialSlide: 0,
    speed: 400
};
const options2 = { style: 'currency', currency: 'USD' };
const _numberFormat = new Intl.NumberFormat('en-US', options2);
class DetailsProduct extends React.Component<IProps, IState> {
    modal: React.RefObject<HTMLIonModalElement>;
    ionInfiniteScrollRefProducts: React.RefObject<HTMLIonInfiniteScrollElement>;
    constructor(props: any) {
        super(props)
        this.ionInfiniteScrollRefProducts = React.createRef<HTMLIonInfiniteScrollElement>();
        this.modal = React.createRef<HTMLIonModalElement>();
        this.state = {
            response: props.response,
            simpleToast: props.simpleToast,
            modalOpen: props.modalOpen,
            loading: props.loading,
            match: props.match,
            catalogue: props.catalogue,
            product: props.product,
            images: props.images,
            modifiers: props.modifiers
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            simpleToast: nextProps.catalogue.simpleToast,
            response: nextProps.catalogue.response,

        });
    }
    componentDidMount() {
        let product_id = this.state.match.params.child;
        let filter_product: any = [];
        filter_product = this.state.catalogue.products.filter((cat: any) => cat.nid == product_id);
        filter_product = filter_product.length > 0 ? filter_product[0] : {};
        filter_product['modifiers'] = [];
        this.setState({
            product: filter_product
        });
        let filter_images: any = [];
        filter_images = this.state.catalogue.images.filter((cat: any) => cat.idproducto == product_id);
        let modifiers_tem = filter_product.md.split('-');
        let modifiers: any = [];
        modifiers_tem.map((val: any, key: any) => {
            if (val != 0) {
                let filter_modifiers = this.state.catalogue.modifiers.filter((cat: any) => cat.idgrupo == val);
                var tem_catalogo_categorie = {
                    nid: val,
                    modifiers: filter_modifiers
                };
                modifiers.push(tem_catalogo_categorie);
            }
        });

        this.setState({
            images: filter_images,
            modifiers: modifiers,
            modalOpen: false
        });
    }
    errorimg(e: any) {
        e.target.src = API + getPathImg() + "nodisponible.jpg";
    }
    modifiersrender(row: any, key: any) {
        let modifierslist = [];
        let nombre = "";
        if (row && row.modifiers.length > 0) {
            modifierslist = row.modifiers.map((val: any, key_: any) => {
                let filter_modifiers: any = [];
                filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == val.idgrupo && item.nid == val.nid);
                nombre = val.nombregrupo;
                return (
                    <IonButton key={key_} color={filter_modifiers.length > 0 ? "success" : "secondary"} onClick={(ev) => this.addmodifiers(ev, val)}> {val.nombre}</IonButton>
                );
            });
        }

        return (
            <IonRow key={key}>
                <IonCol col-12 size='12'>
                    <IonLabel>{nombre}</IonLabel>
                </IonCol>
                <IonCol col-12 size='12'>
                    {modifierslist}
                </IonCol>
            </IonRow>
        );
    }
    onWillDismiss(row: any) {
        this.setState({
            modalOpen: false
        });
    }
    onOpen(row: any) {
        this.setState({
            modalOpen: true
        });
    }
    addshoppingcart(e: any, row: any) {
        if (row.modifiers.length != this.state.modifiers.length) {
            this.props.updateArray({
                response: { Msg: "Debe seleccionar un modificador por grupo" },
                simpleToast: true
            });
        } else {
            let product = {
                nid: row.nid,
                modifiers: row.modifiers
            }
            this.props.addshoppingcart(product);
        }
    }
    addmodifiers(e: any, row: any) {
        let selectmodifier = {
            nid: row.nid,
            idgrupo: row.idgrupo
        }
        let product = this.state.product;

        let filter_modifiers: any = [];
        filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == row.idgrupo);
        if (filter_modifiers.length == 0) {
            product.modifiers.push(selectmodifier);

        } else {
            let tem_modifiers: any = [];
            filter_modifiers.map((val: any, key_: any) => {
                if (val.idgrupo == row.idgrupo) {
                    val.nid = row.nid;
                    tem_modifiers.push(val);
                } else {
                    tem_modifiers.push(val);
                }
            });
            // product.modifiers = tem_modifiers;
        }
        this.setState({
            product: product
        });
    }
    onDidDismissToast(e: any) {
        if (this.state.response && this.state.response.Msg == "Agregado al Carrito") {
            this.props.updateArray({ response: {}, simpleToast: false, refresh_shopping: false });
        } else {
            this.props.updateArray({ response: {}, simpleToast: false });
        }

    }
    render() {
        const { modifiers, modalOpen, simpleToast, response } = this.state;
        let listmodifiers = [];
        let viewprice = 0;
        if (getpermissions() && getpermissions().length > 0) {
            let filter_pri = getpermissions().filter((item: any) => item.nid == 34)[0];
            if (filter_pri) {
                viewprice = filter_pri.valor
            }
        }
        if (modifiers && modifiers.length > 0) {
            listmodifiers = modifiers.map((val: any, key: any) =>
                this.modifiersrender(val, key)
            );
        };
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/ecommers/home" text={""} />
                        </IonButtons>
                        <IonTitle>{this.state.product && this.state.product.nombre}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonToast
                        isOpen={simpleToast}
                        buttons={[
                            {
                                text: 'Ok',
                                role: 'cancel'
                            }
                        ]}
                        message={`${response && response.Msg}`}
                        duration={10000}
                        onDidDismiss={e => this.onDidDismissToast(e)}
                    />
                    <IonModal ref={this.modal} isOpen={modalOpen} trigger="open-modal" onWillDismiss={(ev) => this.onWillDismiss(ev)}>
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonButtons slot="start">
                                    <IonButton onClick={() => this.modal.current?.dismiss()}> <IonIcon slot="end" icon={arrowBack}></IonIcon></IonButton>
                                </IonButtons>
                                <IonTitle> {this.state.product && this.state.product.nombre} ({this.state.product && this.state.product.codigo})</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            <IonSlides pager={true} options={slideOpts}>
                                {
                                    this.state.images && this.state.images.length > 0 ?
                                        this.state.images.map((val: any, key: any) => {
                                            return (
                                                <IonSlide key={key}>
                                                    <img src={API + getPathImg() + val.imagen} onClick={e => this.onOpen(e)} onError={e => this.errorimg(e)} />
                                                </IonSlide>
                                            );
                                        })
                                        :
                                        <IonSlide key={"0012"} >
                                            <img src={API + getPathImg() + (this.state.product && this.state.product.imagen)} onError={e => this.errorimg(e)} onClick={e => this.onOpen(e)} />
                                        </IonSlide>
                                }
                            </IonSlides>
                        </IonContent>
                    </IonModal>
                    <IonSlides pager={true} options={slideOpts}>
                        {
                            this.state.images && this.state.images.length > 0 ?
                                this.state.images.map((val: any, key: any) => {
                                    return (
                                        <IonSlide key={key}>
                                            <img style={{ height: "300px" }} src={API + getPathImg() + val.imagen} onClick={e => this.onOpen(e)} onError={e => this.errorimg(e)} />
                                        </IonSlide>
                                    );
                                })
                                :
                                <IonSlide key={"0012"} >
                                    <img style={{ height: "300px" }} src={API + getPathImg() + (this.state.product && this.state.product.imagen)} onError={e => this.errorimg(e)} onClick={e => this.onOpen(e)} />
                                </IonSlide>
                        }
                    </IonSlides>
                    <br />
                    <IonRow>
                        <IonCol col-8 size='8'>
                            <IonLabel style={{ fontWeight: 'bold', fontSize: "18px", color: '#3647d3' }}>
                                {this.state.product && this.state.product.nombre} ({this.state.product && this.state.product.codigo})
                            </IonLabel>
                        </IonCol>
                        <IonCol col-4 size='4' style={{ textAlign: "right" }}>
                            <IonLabel style={{ fontWeight: 'bold', fontSize: "15px", color: "#5a00f0" }}>
                                {viewprice != 0 ? this.state.product && _numberFormat.format(parseFloat(this.state.product.precio1)).replace("$", "") : null}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <br />
                    <IonRow>
                        <IonCol col-6 size='6'>
                            <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                MARCA:
                            </IonLabel>
                            <IonLabel style={{ fontSize: "15px" }}>
                                &nbsp;{this.state.product && this.state.product.marca}
                            </IonLabel>
                        </IonCol>
                        <IonCol col-6 size='6'>
                            <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                U/M:
                            </IonLabel>
                            <IonLabel style={{ fontSize: "15px" }}>
                                &nbsp;{this.state.product && this.state.product.unidad}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol col-6>
                            <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                CATEGORIA:
                            </IonLabel>
                            <IonLabel style={{ fontSize: "15px" }}>
                                &nbsp;{this.state.product && this.state.product.categoria}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    {viewprice != 0 ?
                        <IonRow>
                            <IonCol col-6>
                                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                    Precio 1:
                                </IonLabel>
                                <IonLabel style={{ fontSize: "15px" }}>
                                    &nbsp;{this.state.product && _numberFormat.format(parseFloat(this.state.product.precio1)).replace("$", "")}&nbsp;
                                </IonLabel> <br />
                                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                    Precio 2:
                                </IonLabel>
                                <IonLabel style={{ fontSize: "15px" }}>
                                    &nbsp;{this.state.product && _numberFormat.format(parseFloat(this.state.product.precio2)).replace("$", "")}&nbsp;
                                </IonLabel>
                            </IonCol>
                            <IonCol col-6>
                                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                    Precio 3:
                                </IonLabel>
                                <IonLabel style={{ fontSize: "15px" }}>
                                    &nbsp;{this.state.product && _numberFormat.format(parseFloat(this.state.product.precio3)).replace("$", "")}&nbsp;
                                </IonLabel> <br />
                                <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                    Precio 4:
                                </IonLabel>
                                <IonLabel style={{ fontSize: "15px" }}>
                                    &nbsp;{this.state.product && _numberFormat.format(parseFloat(this.state.product.precio4)).replace("$", "")}&nbsp;
                                </IonLabel>
                            </IonCol>
                        </IonRow> : null}
                    <br />
                    <IonRow>
                        <IonCol col-12>
                            <IonLabel style={{ fontWeight: 'bold', fontSize: "15px" }}>
                                DESCRIPCION:
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol col-12>
                            <IonLabel style={{ fontSize: "15px" }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.product && this.state.product.descripcion }} />
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <br />
                    {listmodifiers}
                    <IonFooter collapse="fade">
                        <IonToolbar>
                            <IonButton expand="block" className="ion-no-margin" onClick={e => this.addshoppingcart(e, this.state.product)} > <IonIcon size="small" icon={cartOutline} />&nbsp;&nbsp;Agregar al Pedido</IonButton>
                        </IonToolbar>
                    </IonFooter>
                </IonContent>
            </IonPage >
        );
    };
}

const mapStateToProps = (state: any) => {
    return {
        catalogue: state.catalogue,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        addshoppingcart: (id: any) => dispatch(ShoppingCartAction.addshoppingcart(id)),
        updateArray: (item: string) => dispatch(HomeActions.updateArray(item)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(DetailsProduct));