export function getDataJWT() {
	if (window.localStorage.getItem('_token_')) {
		var base64Url = window.localStorage.getItem('_token_').split('.')[1];
		var base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	} else {
		return false;
	}
}
export function getislogued() {
	if (window.localStorage.getItem('islogued')) {
		return window.localStorage.getItem('islogued');
	} else {
		return false;
	}
}

export function getFavorities() {
	if (window.localStorage.getItem('favorities')) {
		return JSON.parse(window.localStorage.getItem('favorities'));
	} else {
		return false;
	}
}

export function getShoppingCart() {
	if (window.localStorage.getItem('shoppingcart')) {
		return JSON.parse(window.localStorage.getItem('shoppingcart'));
	} else {
		return false;
	}
}

export function getPathImg() {
	if (window.localStorage.getItem('path_img')) {
		return window.localStorage.getItem('path_img');
	} else {
		return "default";
	}
}

export function getidmoneda() {
	if (window.localStorage.getItem('idmoneda')) {
		return window.localStorage.getItem('idmoneda');
	} else {
		return 1;
	}
}
export function getcompany() {
	if (window.localStorage.getItem('company')) {
		return window.localStorage.getItem('company');
	} else {
		return "Inicio";
	}
}
export function getorders() {
	if (window.localStorage.getItem('orders')) {
		return JSON.parse(window.localStorage.getItem('orders'));
	} else {
		return false;
	}
}
export function getdatalogin() {
	if (window.localStorage.getItem('data_login')) {
		return JSON.parse(window.localStorage.getItem('data_login'));
	} else {
		return false;
	}
}
export function getchek() {
	if (window.localStorage.getItem('chek')) {
		return window.localStorage.getItem('chek');
	} else {
		return false;
	}
}
export function getpermissions() {
	if (window.localStorage.getItem('permissions')) {
		return JSON.parse(window.localStorage.getItem('permissions'));
	} else {
		return false;
	}
}
export function getPreEmail() {
	if (window.localStorage.getItem('prev_email')) {
		return window.localStorage.getItem('prev_email');
	} else {
		return "default";
	}
}
export function getfilterCategorie() {
	if (window.localStorage.getItem('filterCategorie')) {
		return JSON.parse(window.localStorage.getItem('filterCategorie'));
	} else {
		return false;
	}
}


export function getshoppingcart_client() {
	if (window.localStorage.getItem('shoppingcart_client')) {
		return JSON.parse(window.localStorage.getItem('shoppingcart_client'));
	} else {
		return { name: "", phone: "", address: "", note: "" };
	}
}
export function getsearch_input() {
	if (window.localStorage.getItem('search_input')) {
		return window.localStorage.getItem('search_input');
	} else {
		return "";
	}
}
export function getImageDefault() {
	if (window.localStorage.getItem('img_default')) {
		return window.localStorage.getItem('img_default');
	} else {
		return "";
	}
}
export function getOrderNumber() {
	if (window.localStorage.getItem('edit_order_number')) {
		return window.localStorage.getItem('edit_order_number');
	} else {
		return "";
	}
}
export function getModeList() {
	if (window.localStorage.getItem('mode_list_codessi')) {
		return window.localStorage.getItem('mode_list_codessi') == "true";
	} else {
		return false;
	}
}