import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import {
  withIonLifeCycle,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonItemDivider,
  IonList,
  IonItem,
  IonThumbnail,
  IonImg,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonButton,
  IonInfiniteScroll,
  IonInput,
  IonTabButton,
  IonToast, IonFab, IonFabButton, IonInfiniteScrollContent,
  IonLoading, IonRefresher, IonRefresherContent, IonModal, IonFooter, IonPopover, IonButtons, IonCheckbox
} from '@ionic/react';
import { connect } from 'react-redux'
import HomeActions from '../actions/HomeActions'
import ShoppingCartAction from '../actions/ShoppingCartAction'
import { RESOURCESREACT } from '../constants/system';
import { mailOpenOutline, callOutline, globeOutline, homeOutline, arrowForward, heart, logOut, cart, cartOutline, heartOutline, arrowUp, closeOutline, ellipsisVerticalOutline, arrowBack, filter, close, shareSocialOutline } from 'ionicons/icons';
import { getislogued, getDataJWT, getOrderNumber, getPathImg, getcompany, getchek, getpermissions, getfilterCategorie, getdatalogin } from '../utils/functions';
import { API } from '../constants/system';
import PermissionContainer from '../components/PermissionContainer';

import { Share } from '@capacitor/share';

import './home.css';

interface IProps {
  get_: any;
  post_: any;
  setDataCategories: any;
  addfavorities: any;
  addshoppingcart: any;
  updateArray: any;
}

interface IState {
  catalogue: any;
  categories: any;
  categories_products: any;
  page: any;
  favorities: any
  data: any;
  shoppingcart: any;
  refresh_home: any;
  loading: any;
  loadmore_data_pagination: any;
  data_pagination: any;
  limit: any;
  totalrows: any;
  pages: any;
  searchText: any;
  modalOpen: any;
  product: any;
  DiviseId: any;
  showpopu: any;
  openmodalcat: any;
  categorie_pagination: any;
  scrollup: any;
  checkallcat: any;
  categories_primary: any;
  notrendercategories: any;
  new_products: any;

}

const slideOpts = {
  initialSlide: 0,
  speed: 400
};

const options2 = { style: 'currency', currency: 'USD' };
const _numberFormat = new Intl.NumberFormat('en-US', options2);
class Home extends React.Component<IProps, IState> {
  contentRef: React.RefObject<HTMLIonContentElement>;
  ionInfiniteScrollRefProducts: React.RefObject<HTMLIonInfiniteScrollElement>;
  modal: React.RefObject<HTMLIonModalElement>;
  modalcat: React.RefObject<HTMLIonModalElement>;
  popover: React.RefObject<HTMLIonPopoverElement>;
  constructor(props: any) {
    super(props)
    this.contentRef = React.createRef<HTMLIonContentElement>();
    this.ionInfiniteScrollRefProducts = React.createRef<HTMLIonInfiniteScrollElement>();
    this.modal = React.createRef<HTMLIonModalElement>();
    this.modalcat = React.createRef<HTMLIonModalElement>();
    this.popover = React.createRef<HTMLIonPopoverElement>();
    this.state = {
      scrollup: props.scrollup,
      categorie_pagination: props.categorie_pagination,
      showpopu: props.showpopu,
      DiviseId: props.DiviseId,
      loading: props.catalogue.loading,
      refresh_home: props.catalogue.refresh_home,
      shoppingcart: props.catalogue.shoppingcart,
      data: props.data,
      favorities: props.catalogue.favorities,
      catalogue: props.catalogue.products,
      categories: props.catalogue.categories,
      categories_primary: props.catalogue.categories_primary,
      categories_products: props.catalogue.categories_products,
      //paginacion
      loadmore_data_pagination: props.loadmore_data_pagination,
      data_pagination: props.data_pagination,
      limit: props.limit,
      totalrows: props.totalrows,
      page: props.page,
      pages: props.pages,
      searchText: props.searchText,
      modalOpen: props.modalOpen,
      product: props.product,
      openmodalcat: props.openmodalcat,
      checkallcat: props.checkallcat,
      notrendercategories: props.notrendercategories,
      new_products: props.new_products
    }
  }
  componentDidMount() {
    let filtercategorielist: any = [];
    let arrayfiltercategorielist = !getfilterCategorie() ? [] : getfilterCategorie();
    if (arrayfiltercategorielist.length > 0) {
      this.state.categories.map((val: any, key: any) => {
        let filter_cat: any = [];
        filter_cat = arrayfiltercategorielist.filter((item: any) => item.nid == val.nid);
        if (filter_cat.length > 0) {
          val['check'] = true;
          filtercategorielist.push(filter_cat[0]);
        } else {
          val['check'] = false;
          filtercategorielist.push(val);
        }
      });
    } else {
      this.state.categories.map((val: any, key: any) => {
        val['check'] = false;
        filtercategorielist.push(val);
      });
    }
    let filtercategorielist_order = filtercategorielist.sort((a: any, b: any) => (a.nombre > b.nombre) ? 1 : -1);

    if (this.state.data_pagination && this.state.data_pagination.length == 0) {
      this.setState({
        limit: 10,
        pages: 0,
        page: 1,
        totalrows: 0,
        loadmore_data_pagination: true,
        searchText: "",
        modalOpen: false,
        product: {},
        DiviseId: "",
        showpopu: false,
        openmodalcat: this.state.openmodalcat && this.state.openmodalcat ? true : false,
        categories: filtercategorielist_order,
        notrendercategories: true
        //checkallcat: arrayfiltercategorielist.length > 0 ? true : false
      });
      setTimeout(this.datapaginrenderinit.bind(this), 100);

    };
    if (this.state.categories_products.length > 0) {
      setTimeout(this.datapaginrenderinit.bind(this), 100);

    } else {
      //this.props.updateArray({ loading: true });
      this.setState({
        limit: 10,
        pages: 0,
        page: 1,
        totalrows: 0,
        loadmore_data_pagination: true,
        searchText: "",
        modalOpen: false,
        product: {},
        DiviseId: "",
        openmodalcat: false,
        categories: filtercategorielist_order,
        notrendercategories: true
        //checkallcat: arrayfiltercategorielist.length > 0 ? true : false
      });
      setTimeout(this.datapaginrenderinit.bind(this), 100);
    }
  }
  datapaginrenderinit() {
    let arrayfiltercategorielist = !getfilterCategorie() ? [] : getfilterCategorie();
    if (arrayfiltercategorielist.length > 0) {

      let cataloguelist: any = [];
      arrayfiltercategorielist.map((val: any, key: any) => {
        let filter_cat: any = [];
        filter_cat = this.state.categories_products.filter((item: any) => item.nid == val.nid);
        if (filter_cat.length > 0) {
          cataloguelist.push(filter_cat[0]);
        }
      });

      if (this.ionInfiniteScrollRefProducts.current) {
        this.ionInfiniteScrollRefProducts.current.complete();
      }

      this.setState({
        data_pagination: cataloguelist,
        pages: 0,
        totalrows: 0,
        loadmore_data_pagination: false
      });

    } else {
      let cataloguelist: any = [];
      let categorielist: any = [];
      let final_limit = this.state.limit * 1;

      //let temp_array_cataloge_categorie = this.state.searchText != "" ? this.state.cataloge_categorie.catalogo.filter((cat: any) => cat.nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) || cat.codigo.toLowerCase().includes(this.state.searchText.toLowerCase())) : this.state.cataloge_categorie.catalogo;
      this.state.categories_products.map((val: any, key: any) => {
        if (key >= ((final_limit - this.state.limit) - this.state.limit) && key <= (final_limit - 1)) {
          cataloguelist.push(val);
        };
      });

      this.setState({
        data_pagination: cataloguelist,
        pages: Math.ceil(this.state.categories_products.length / this.state.limit),
        totalrows: this.state.categories_products.length,
      });
    }
    this.props.updateArray({ loading: false });


  }
  ionViewWillLeave() {
    this.props.updateArray({ refresh_home: false, loading: true });
  }
  ionViewDidEnter() {
    if (this.state.data_pagination && this.state.data_pagination.length > 0) {
      this.props.updateArray({ refresh_home: true, loading: false, refresh_accounts: false, refresh_favorities: false, refresh_cart: true });
    } else {
      this.props.updateArray({ refresh_home: true, loading: true, refresh_accounts: false, refresh_favorities: false, refresh_cart: true });
    }

  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.state.scrollup != nextProps.catalogue.scrollup) {
      this.buttomup();
    }
    if (nextProps.catalogue.refresh_home) {
      let news_favorities = false;
      if (this.state.favorities && nextProps.catalogue.favorities.length != this.state.favorities.length) {
        news_favorities = true;
      };

      let news_shoppingcart = false;
      if (this.state.shoppingcart && nextProps.catalogue.shoppingcart.length != this.state.shoppingcart.length) {
        news_shoppingcart = true;
      };
      this.setState({
        new_products: nextProps.catalogue.new_products,
        data: nextProps.data,
        catalogue: nextProps.catalogue.products,
        categories: nextProps.catalogue.categories,
        categories_primary: nextProps.catalogue.categories,
        favorities: nextProps.catalogue.favorities,
        shoppingcart: nextProps.catalogue.shoppingcart,
        categories_products: nextProps.catalogue.categories_products,
        refresh_home: nextProps.catalogue.refresh_home,
        loading: nextProps.catalogue.loading,
        scrollup: nextProps.catalogue.scrollup
      });

      if (nextProps.catalogue.categories_products.length != this.state.categories_products.length) {
        setTimeout(this.componentDidMount.bind(this), 100);
      } else if (this.state.data_pagination && this.state.data_pagination.length == 0) {
        setTimeout(this.componentDidMount.bind(this), 100);
      } else if (news_favorities || news_shoppingcart) {
        setTimeout(this.refreshdata.bind(this), 100);
      } else {
        if (this.state.data_pagination && this.state.data_pagination.length > 0) {
          setTimeout(this.refreshdata.bind(this), 100);
        } else {
          setTimeout(this.componentDidMount.bind(this), 100);
        }
      };
    } else {
      //console.log("no render");
    }

  }
  addfavorities(e: any, row: any) {
    this.props.addfavorities(row.nid);
  }

  async sendwha(e: any, row: any) {
    /*// Define the content you want to share
    const shareText = 'Check out this awesome content!';
    const shareUrl = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==" alt="Red dot" />';
    // Create the WhatsApp share URL
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}%20${encodeURIComponent(shareUrl)}`;
    // Open WhatsApp with the share URL
    window.location.href = whatsappUrl;*/

    await Share.share({
      title: 'See cool stuff',
      text: "Producto: " + row.nombre + ", Codigo: " + row.codigo + ", Imagen: ",
      url: API + getPathImg() + row.imagen,
      dialogTitle: 'Share with buddies',
    });

  }
  errorimg(e: any) {
    //console.log(getImageDefault());
    //e.target.src = getdatalogin().img_default;
    e.target.src = 'assets/nodisponible.jpg';
  }
  errorimgCategoria(e: any) {
    //console.log(getImageDefault());
    e.target.src = getdatalogin().img_default;
    //e.target.src = 'assets/nodisponible.jpg';
  }
  addshoppingcart(e: any) {
    if (this.state.product.modifiers.length != this.state.product.modifiersall.length) {
      this.props.updateArray({
        response: { Msg: "Debe seleccionar un modificador por grupo" },
        simpleToast: true
      });
    } else {
      let product = {
        nid: this.state.product.nid,
        modifiers: this.state.product.modifiers
      }
      this.props.addshoppingcart(product);
      this.setState({
        modalOpen: false,
        product: {}
      });
    }
  }
  addshoppingcartprev(e: any, row: any) {
    if (row.modifiersall.length == 0) {
      this.props.addshoppingcart({ nid: row.nid, modifiers: [] });
    } else {
      this.setState({
        modalOpen: true,
        product: {
          nid: row.nid,
          name: row.nombre + " (" + row.codigo + ")",
          modifiersall: row.modifiersall,
          modifiers: []
        }
      });
    }
  }
  closemodal(e: any) {
    this.setState({
      modalOpen: false,
      product: {}
    });
  }
  listproductcategoryrender(row: any, key: any, viewprice: any, viewstock: any) {
    let cataloguelist: any = [];
    /*
    if (row && row.catalogo_limit.length > 0) {
      cataloguelist = row.catalogo_limit.map((val: any, key: any) => {
        let filter_product = this.state.favorities.filter((cat: any) => cat == val.nid);
        let filter_product_ = this.state.shoppingcart.filter((cat: any) => cat.nid == val.nid);

        if (filter_product.length > 0) {
          //let val = val;
          val['coloricon'] = "#eb445a";
          if (filter_product_.length > 0) {
            val['coloriconshoppingcart'] = "#424242";
          } else {
            val['coloriconshoppingcart'] = "#66666694";
          }
          //cataloguelist.push(val);
        } else {
          //let val = val;
          val['coloricon'] = "#66666694";
          if (filter_product_.length > 0) {
            val['coloriconshoppingcart'] = "#424242";
          } else {
            val['coloriconshoppingcart'] = "#66666694";
          }
          //cataloguelist.push(val);
        }
        return (
          <IonCol key={key} col-6 size='6'>
            <IonCard style={{ marginInline: "0px", textAlign: 'center' }}>
              <IonRow>
                <IonCol col-12 style={{ textAlign: "right" }}>
                  <IonLabel style={{ fontSize: "10px" }}>
                    {viewstock != 0 ? "Stock: " + _numberFormat.format(parseFloat(val.stock)).replace("$", "") : null}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <Link to={"/detail_product/" + val.nid} style={{ textAlign: 'center' }}><img style={{ height: "200px", textAlign: 'center' }}
                onError={(e) => this.errorimg(e)}
                src={API + getPathImg() + val.imagen}
              /></Link>
              <IonCardContent style={{ padding: "5px" }}>
                <IonRow>
                  <IonCol col-12 style={{ textAlign: "center" }}>
                    <IonLabel style={{ fontSize: "10px" }}>
                      {val.codigo}
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol col-12 style={{ textAlign: "center" }}>
                    <IonLabel style={{ fontSize: "10px" }}>
                      {val.nombre.substring(0, 15) + '...'}
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol col-2 size='5' style={{ textAlign: 'left' }} >
                    <IonLabel position="stacked" style={{ fontWeight: 'bold', fontSize: "10px" }}>
                      {viewprice != 0 ? _numberFormat.format(parseFloat(val.precio1)).replace("$", "") : null}
                    </IonLabel>
                  </IonCol>
                  <IonCol col-10 size='7' style={{ textAlign: 'right' }}>
                    <IonIcon style={{ fontSize: "25px" }} icon={shareSocialOutline} onClick={e => this.sendwha(e, val)} />
                    &nbsp;
                    <IonIcon style={{ color: val.coloricon, fontSize: "25px" }} icon={heartOutline} onClick={e => this.addfavorities(e, val)} />
                    &nbsp;
                    <IonIcon style={{ color: val.coloriconshoppingcart, fontSize: "25px" }} icon={cart} onClick={e => this.addshoppingcartprev(e, val)} />
                  </IonCol>
                </IonRow>

              </IonCardContent>
            </IonCard>
          </IonCol>
        )
      })
    }
    */
    return (
      <IonRow key={key}>
        <IonCol col-12 size='12'>
          <IonItem >
            <IonThumbnail>
              <img
                onError={(e) => this.errorimgCategoria(e)}
                src={API + getPathImg() + row.imagen} />
            </IonThumbnail>
            &nbsp;&nbsp;
            <IonLabel style={{ textDecoration: 'none' }}><Link style={{ textDecoration: 'none', color: "black" }} to={"/categories_products/" + row.nid}>{row.nombre.toUpperCase()} ({row.totalrows}) </Link></IonLabel>
            <Link to={"/categories_products/" + row.nid}><IonIcon size="small" slot="end" icon={arrowForward} /></Link>
          </IonItem>
        </IonCol>
        {cataloguelist}
        <br />
      </IonRow>
    );
  }
  logout(e: any) {
    if (getchek()) {

    } else {
      window.localStorage.removeItem('data_login');
    }
    window.localStorage.removeItem('islogued');
    window.location.reload();
  }
  buttomup() {
    if (this.contentRef.current) {
      this.contentRef.current.scrollToTop();
    }
  }
  loadMoreItems(event: any) {

    if (!this.state.loadmore_data_pagination) {
      if (this.ionInfiniteScrollRefProducts.current) {
        this.ionInfiniteScrollRefProducts.current.complete();
      }
    }

    let cataloguelist: any = [];
    let final_limit = this.state.limit * (this.state.page + 1);
    //let temp_array_cataloge_categorie = this.state.searchText != "" ? this.state.cataloge_categorie.catalogo.filter((cat: any) => cat.nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) || cat.codigo.toLowerCase().includes(this.state.searchText.toLowerCase())) : this.state.cataloge_categorie.catalogo;
    this.state.categories_products.map((val: any, key: any) => {
      if (key > (this.state.limit * (this.state.page)) - 1 && key <= (final_limit - 1)) {
        cataloguelist.push(val);
      }
    });

    if (this.state.loadmore_data_pagination) {
      setTimeout(this.datapaginrenderconcat.bind(this, cataloguelist), 1000);
    }

    if ((this.state.page + 1) == this.state.pages) {
      this.setState({
        loadmore_data_pagination: false,
      });
      if (this.ionInfiniteScrollRefProducts.current) {
        this.ionInfiniteScrollRefProducts.current.complete();
      }
    }
  }
  datapaginrenderconcat(cataloguelist: any, event: any) {
    this.setState({
      data_pagination: this.state.data_pagination.concat(cataloguelist),
      page: this.state.page + 1
    });
    if (this.ionInfiniteScrollRefProducts.current) {
      this.ionInfiniteScrollRefProducts.current.complete();
    }
  }
  refreshdata() {
    let arrayfiltercategorielist = !getfilterCategorie() ? [] : getfilterCategorie();
    if (arrayfiltercategorielist.length > 0) {

      let filtercategorielist: any = [];
      this.state.categories.map((val: any, key: any) => {
        let filter_cat: any = [];
        filter_cat = arrayfiltercategorielist.filter((item: any) => item.nid == val.nid);
        if (filter_cat.length > 0) {
          filtercategorielist.push(filter_cat[0]);
        } else {
          filtercategorielist.push(val);
        }
      });

      let cataloguelist: any = [];
      arrayfiltercategorielist.map((val: any, key: any) => {
        let filter_cat: any = [];
        filter_cat = this.state.categories_products.filter((item: any) => item.nid == val.nid);
        if (filter_cat.length > 0) {
          cataloguelist.push(filter_cat[0]);
        }
      });

      if (this.ionInfiniteScrollRefProducts.current) {
        this.ionInfiniteScrollRefProducts.current.complete();
      }

      this.setState({
        data_pagination: cataloguelist,
        pages: 0,
        totalrows: 0,
        loadmore_data_pagination: false,
        categories: filtercategorielist
      });

    } else {
      let cataloguelistfull: any = [];

      this.state.data_pagination.map((val: any, key: any) => {
        let cataloguelist: any = [];
        val.catalogo_limit.map((val_: any, key_: any) => {
          let filter_product = this.state.favorities.filter((cat: any) => cat == val_.nid);
          let filter_product_ = this.state.shoppingcart.filter((cat: any) => cat.nid == val_.nid);

          if (filter_product.length > 0) {
            let tem = val_;
            tem['coloricon'] = "#eb445a";
            if (filter_product_.length > 0) {
              tem['coloriconshoppingcart'] = "#424242";
            } else {
              tem['coloriconshoppingcart'] = "#66666694";
            }
            cataloguelist.push(tem);
          } else {
            let tem = val_;
            tem['coloricon'] = "#66666694";
            if (filter_product_.length > 0) {
              tem['coloriconshoppingcart'] = "#424242";
            } else {
              tem['coloriconshoppingcart'] = "#66666694";
            }
            cataloguelist.push(tem);
          }

        });

        let tem__ = val;
        tem__['catalogo_limit'] = cataloguelist;
        cataloguelistfull.push(tem__);
      });
      this.setState({
        data_pagination: cataloguelistfull,
      });
    }
  }
  doRefresh(e: any) {
    setTimeout(() => {
      e.detail.complete();
      window.location.href = "/ecommers/home";
      window.location.reload();
    }, 500);
  }
  modifiersrender(row: any, key: any) {
    let modifierslist = [];
    let nombre = "";
    if (row && row.modifiers.length > 0) {
      modifierslist = row.modifiers.map((val: any, key_: any) => {
        let filter_modifiers: any = [];
        filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == val.idgrupo && item.nid == val.nid);
        nombre = val.nombregrupo;
        return (
          <IonButton key={key_} color={filter_modifiers.length > 0 ? "success" : "secondary"} onClick={(ev) => this.addmodifiers(ev, val)}> {val.nombre}</IonButton>
        );
      });
    }

    return (
      <IonRow key={key}>
        <IonCol col-12 size='12'>
          <IonLabel>{nombre}</IonLabel>
        </IonCol>
        <IonCol col-12 size='12'>
          {modifierslist}
        </IonCol>
      </IonRow>
    );
  }
  addmodifiers(e: any, row: any) {
    let selectmodifier = {
      nid: row.nid,
      idgrupo: row.idgrupo
    }
    let product = this.state.product;

    let filter_modifiers: any = [];
    filter_modifiers = this.state.product.modifiers.filter((item: any) => item.idgrupo == row.idgrupo);
    if (filter_modifiers.length == 0) {
      product.modifiers.push(selectmodifier);

    } else {
      let tem_modifiers: any = [];
      filter_modifiers.map((val: any, key_: any) => {
        if (val.idgrupo == row.idgrupo) {
          val.nid = row.nid;
          tem_modifiers.push(val);
        } else {
          tem_modifiers.push(val);
        }
      });
      // product.modifiers = tem_modifiers;
    }
    this.setState({
      product: product
    });
  }
  openpopup(e: any) {
    this.popover.current!.event = e;
    this.setState({
      showpopu: true
    });
  }
  closepopup(e: any) {
    this.setState({
      showpopu: true
    });
  }
  opeclosecategoriesmodal(e: any) {
    this.setState({
      openmodalcat: this.state.openmodalcat ? false : true
    });
    setTimeout(this.refreshdata.bind(this), 100);
  }
  onChangeInput(e: any, id: any) {
    const newArr = this.state.categories.map((item: any, i: any) => {
      if ((id) == item.nid) {
        return { ...item, [e.target.name]: e.target.checked };
      } else {
        return item;
      }
    });
    let new_categories: any = [];
    newArr.map((val: any, key: any) => {
      if (val.check) {
        new_categories.push(val);
      }

    });
    window.localStorage.setItem('filterCategorie', JSON.stringify(new_categories));
    this.setState({
      categories: newArr
    });
  }
  categorieonchangue(e: any, row: any) {
    let categorielist: any = [];
    let filtercategorielist: any = [];
    this.state.categories.map((val: any, key: any) => {
      let tem: any = {
        check: val.check,
        color: val.color,
        imagen: val.imagen,
        nid: val.nid,
        nombre: val.nombre,
        tam: val.tam
      }
      if (val.nid == row.nid) {
        if (val.check) {
          tem['check'] = false
          categorielist.push(tem);
        } else {
          tem['check'] = true
          categorielist.push(tem);
          filtercategorielist.push(tem);
        }
      } else {
        if (val.check) {
          categorielist.push(tem);
          filtercategorielist.push(tem);
        } else {
          categorielist.push(tem);
        }
      };
    });
    this.setState({
      categories: categorielist
    });
  }
  categorieDesSelect(e: any) {
    let categorielist: any = [];
    this.state.categories.map((val: any, key: any) => {
      val['check'] = false;
      categorielist.push(val);
    });
    this.setState({
      categories: categorielist,
      data_pagination: []
    });
    window.localStorage.setItem('filterCategorie', JSON.stringify([]));
  }
  rendercategories(row: any, key: any) {
    let arrayfiltercategorielist = !getfilterCategorie() ? [] : getfilterCategorie();
    let filter_cat: any = [];
    let chek: any = false;

    filter_cat = arrayfiltercategorielist.filter((item: any) => item.nid == row.nid);
    if (filter_cat.length > 0) {
      chek = true;
    }
    return (
      <IonItem key={key}>
        <IonThumbnail>
          <img
            onError={(e) => this.errorimgCategoria(e)}
            src={API + getPathImg() + row.imagen} />
        </IonThumbnail>
        <IonLabel>{row.nombre} ({row.tam})</IonLabel>
        <IonCheckbox checked={chek} name={'check'} onIonChange={e => this.onChangeInput(e, row.nid)} />
      </IonItem>
    )
  }
  setSearchText(e: any) {
    const value = e.target.value;
    this.setState({
      searchText: value.toLowerCase()
    });
    setTimeout(this.dataSearchCategories.bind(this), 100);
  }
  dataSearchCategories() {
    let temp_array_cataloge_categorie = this.state.searchText != "" ?
      this.state.categories.filter((cat: any) => cat.nombre.toLowerCase().includes(this.state.searchText.toLowerCase()))
      :
      this.state.categories;

    this.setState({
      categories_primary: temp_array_cataloge_categorie
    });
  }
  closedmodalcategories() {
    let arrayfiltercategorielist = !getfilterCategorie() ? [] : getfilterCategorie();
    this.setState({
      openmodalcat: false,
      data_pagination: arrayfiltercategorielist.length == 0 ? [] : this.state.data_pagination
    });
    setTimeout(this.componentDidMount.bind(this), 100);
  }
  listproductnewrender(val: any, key: any, viewprice: any) {
    /*
      <Link to={"/detail_product/" + val.nid} style={{ textAlign: 'center' }}>
        <span className='badge__' style={{ top: '0px', marginTop: "0px" }}>Nuevo</span>

        <img style={{ textAlign: 'center' }}
          className='ion-slide_s'
          onError={(e) => this.errorimg(e)}
          src={API + getPathImg() + val.imagen}
        />


      </Link>
    */
    return (
      <div className="card 1 ol-xs-4">
        <div className="card_image">
          <Link to={"/detail_product/" + val.nid} style={{ textAlign: 'center' }}>
            <img style={{ textAlign: 'center' }}
              className='card_img_'
              onError={(e) => this.errorimg(e)}
              src={API + getPathImg() + val.imagen}
            />
          </Link>

        </div>
        <div className="card_title title-white ">
          <p>Nuevo</p>
        </div>
      </div>
    )
  }
  render() {
    if (!this.state.refresh_home) {
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>{getcompany()}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen ref={this.contentRef} scrollEvents={true}>
          </IonContent>
        </IonPage >
      );
    } else {
      const { data_pagination, modalOpen, showpopu, openmodalcat, categories, searchText, categories_primary, new_products } = this.state;
      let viewcataloge = 0;
      let viewprice = 0;
      let viewstock = 0;
      if (getpermissions() && getpermissions().length > 0) {
        let filter_per = getpermissions().filter((item: any) => item.nid == 30)[0];
        if (filter_per) {
          viewcataloge = filter_per.valor
        }
        let filter_pri = getpermissions().filter((item: any) => item.nid == 34)[0];
        if (filter_pri) {
          viewprice = filter_pri.valor
        }
        let filter_stock = getpermissions().filter((item: any) => item.nid == 38)[0];
        if (filter_stock) {
          viewstock = filter_stock.valor
        }
      }
      let listproductcategoryrender: any = [];
      if (data_pagination && data_pagination.length > 0) {
        listproductcategoryrender = data_pagination.map((val: any, key: any) =>
          this.listproductcategoryrender(val, key, viewprice, viewstock)
        );
      };
      let listcategories: any = [];
      if (categories && categories.length > 0) {
        listcategories = categories.map((val: any, key: any) =>
          this.rendercategories(val, key)
        )
      }
      if (searchText && searchText != "" && categories && categories.length > 0) {
        listcategories = categories.map((val: any, key: any) =>
          val.nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) ?
            this.rendercategories(val, key) : null
        )
      }
      let listnew_products: any = [];
      if (new_products && new_products.length > 0) {
        listnew_products = new_products.map((val: any, key: any) =>
          this.listproductnewrender(val, key, viewprice)
        );
      };
      return (
        <IonPage>
          <IonModal ref={this.modalcat} isOpen={openmodalcat} trigger="open-modal" onWillDismiss={(ev) => this.closedmodalcategories()}>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonButton onClick={(ev) => this.closedmodalcategories()}> <IonIcon slot="end" icon={arrowBack}></IonIcon></IonButton>
                </IonButtons>
                <IonTitle>Categorias</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              {<IonSearchbar onIonChange={e => this.setSearchText(e)} show-cancel-button="never" placeholder="Buscar por Nombre"></IonSearchbar>}
              <IonList>
                {
                  getfilterCategorie() && getfilterCategorie().length > 0 ?
                    <IonItem>
                      <IonButton size="small" slot="end" color='primay' onClick={e => this.categorieDesSelect(e)}><IonIcon size="small" style={{ color: "black" }} onClick={e => this.categorieDesSelect(e)} icon={close}></IonIcon></IonButton>
                    </IonItem>
                    : null
                }
                {
                  listcategories
                }
              </IonList>
              <IonRow style={{ height: "60px" }}>

              </IonRow >
            </IonContent>
            <IonFooter collapse="fade" className='footerfixed'>
              <IonToolbar>
                <IonButton slot="end" style={{ fontSize: "12px" }} onClick={(ev) => this.closedmodalcategories()}>
                  Mostrar Catalogo
                </IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle style={{ fontSize: "15px" }}>{getcompany() + " " + getOrderNumber()}</IonTitle>
              <IonButton slot="end" color='secondary' onClick={e => this.opeclosecategoriesmodal(e)}><IonIcon onClick={e => this.opeclosecategoriesmodal(e)} icon={filter}></IonIcon></IonButton>
              <IonButton slot="end" color='secondary' onClick={e => this.openpopup(e)}><IonIcon icon={ellipsisVerticalOutline}></IonIcon></IonButton>
              <IonPopover ref={this.popover} isOpen={showpopu} onDidDismiss={e => {
                this.setState({
                  showpopu: false
                })
              }}>
                {
                  getDataJWT() && getDataJWT().data ?
                    (
                      <>
                        <IonContent class="ion-padding">
                          <IonRow >
                            <IonCol col-12 size="12" className="cardcenter">
                              <img onError={e => this.errorimg(e)} src={API + 'recursos/imagenes/usuarios/128/' + getDataJWT().data.imagen} style={{ borderRadius: "50%", width: "50%" }} />
                              <p>{getDataJWT().data.nombre}</p>
                            </IonCol>
                            <IonCol col-12 size="12">
                              <IonLabel style={{ fontWeight: 'bold' }}>
                                Correo:
                              </IonLabel>
                              {getDataJWT().data.email}
                              <br />
                              <IonLabel style={{ fontWeight: 'bold' }}>
                                PIN:
                              </IonLabel>
                              {getDataJWT().data.pin}
                              <br />
                              <IonLabel style={{ fontWeight: 'bold' }}>
                                Contraseña:
                              </IonLabel>
                              {getDataJWT().data.papp}
                              <br />
                              <IonLabel style={{ fontWeight: 'bold' }}>
                                Empresa:
                              </IonLabel>
                              {getDataJWT().data.kempresa}
                            </IonCol>
                            <IonCol col-12 size="12" className="cardcenter">
                              <p>Contactenos</p>
                            </IonCol>
                            <IonCol col-12 size="12">
                              <IonLabel style={{ fontWeight: 'bold' }}></IonLabel>contacto@facturaenruta.com<br />
                              <IonLabel style={{ fontWeight: 'bold' }}></IonLabel>www.facturaenruta.com<br />
                              <IonLabel style={{ fontWeight: 'bold' }}></IonLabel> +505 5721-3533<br />
                              <IonLabel style={{ fontWeight: 'bold' }}></IonLabel> +505 8383-6675<br />
                            </IonCol>
                          </IonRow>
                        </IonContent>
                      </>
                    )
                    :
                    null
                }
                <IonContent class="ion-padding">
                  <IonRow >
                    <IonCol col-12 size="12" className="cardcenter">
                      <IonButton style={{ textAling: "center", marginRight: '10px' }} onClick={e => this.logout(e)}>
                        Salir
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonContent>
              </IonPopover>
            </IonToolbar>
          </IonHeader>
          {
            viewcataloge != 0 ?
              <IonContent fullscreen ref={this.contentRef} scrollEvents={true}>
                <IonRefresher slot="fixed" onIonRefresh={e => this.doRefresh(e)}>
                  <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {
                  new_products && new_products.length > 0 ?
                    <IonRow>
                      <IonCol size="12">
                        <div className=" horizontal-scrollable">
                          <div className="row_">
                            {listnew_products}
                          </div>
                        </div>
                      </IonCol>
                    </IonRow> : null
                }
                <IonGrid>
                  {listproductcategoryrender}
                </IonGrid>
                <IonInfiniteScroll threshold="100px" ref={this.ionInfiniteScrollRefProducts} onIonInfinite={this.loadMoreItems.bind(this)}>
                  <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText="Cargando mas información...">
                  </IonInfiniteScrollContent>
                </IonInfiniteScroll>
                <IonModal onWillDismiss={e => this.closemodal(e)} ref={this.modal} isOpen={modalOpen} trigger="open-modal" initialBreakpoint={0.50} breakpoints={[0, 0.50, 0.5, 0.75]}>
                  <IonHeader>
                    <IonToolbar>
                      <IonTitle>{this.state.product && this.state.product.name}</IonTitle>
                      <IonButton slot="end" style={{ textAling: "center", marginRight: '10px' }} onClick={(ev) => this.closemodal(ev)}>
                        <IonIcon icon={closeOutline}></IonIcon>
                      </IonButton>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent className="ion-padding">
                    {
                      this.state.product && this.state.product.modifiersall ?
                        this.state.product.modifiersall.map((val: any, key: any) =>
                          this.modifiersrender(val, key)
                        )
                        :
                        null
                    }
                    <IonFooter collapse="fade">
                      <IonToolbar>
                        <IonButton expand="block" className="ion-no-margin" onClick={e => this.addshoppingcart(e)} > <IonIcon size="small" icon={cartOutline} />&nbsp;&nbsp;Agregar al Pedido</IonButton>
                      </IonToolbar>
                    </IonFooter>
                  </IonContent>
                </IonModal>
              </IonContent>
              :
              <IonContent fullscreen ref={this.contentRef} scrollEvents={true}>
                <IonRefresher slot="fixed" onIonRefresh={e => this.doRefresh(e)}>
                  <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <PermissionContainer />
              </IonContent>
          }

        </IonPage >
      );
    };
  };
}

const mapStateToProps = (state: any) => {
  return {
    catalogue: state.catalogue
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateArray: (item: string) => dispatch(HomeActions.updateArray(item)),
    addfavorities: (id: any) => dispatch(HomeActions.addfavorities(id)),
    addshoppingcart: (id: any) => dispatch(ShoppingCartAction.addshoppingcart(id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(Home));