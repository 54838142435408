import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
  withIonLifeCycle,
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonRow
} from '@ionic/react';
import { connect } from 'react-redux'
import { getislogued, getDataJWT, getOrderNumber, getPathImg, getcompany, getchek, getpermissions, getfilterCategorie, getdatalogin } from '../utils/functions';

interface IProps {

}

interface IState {

}

const slideOpts = {

};

class PermissionContainer extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    return (
      <IonContent>
        <div className="container">
          <img style={{ height: "200px", textAlign: 'center', width: "200px" }} src={'https://www.app.codesii.com/public/img/frlogo.png'} />
          <IonRow >
            <IonCol col-12 size="12">
              <IonLabel style={{ fontWeight: 'bold' }}></IonLabel>contacto@facturaenruta.com<br />
              <IonLabel style={{ fontWeight: 'bold' }}></IonLabel>www.facturaenruta.com<br />
              <IonLabel style={{ fontWeight: 'bold' }}></IonLabel> +505 8383-6675<br />

              <IonLabel style={{ fontWeight: 'bold' }}></IonLabel> +505 5721-3533<br />
            </IonCol>
          </IonRow >
        </div>
      </IonContent >
    );
  };
}

const mapStateToProps = (state: any) => {

}

const mapDispatchToProps = (dispatch: any) => {
  return {

  }
};

export default PermissionContainer;