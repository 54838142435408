import * as ActionTypes from './../constants/ActionTypes'

const initialState = {
  data: [],
  refresh: false,
  refresh_: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.LOADING_CHANGE_FAVORITIES:
      return {
        ...state,
        loading: !state.loading
      }
    case ActionTypes.DATA_FAVORITIES:
      return {
        ...state,
        [action.name]: action.items
      };
    default: return state;
  }
}